export const allCFTypeOptions = [
    { value: 1, label: "Inflows" },
    { value: 2, label: "Coupon" },
    { value: 3, label: "Dividend" },
    { value: 10, label: "Cost" },
    { value: 11, label: "Management Fee" },
    { value: 12, label: "Performance Fee (Carried Interest Paid)" },
    { value: 13, label: "Deal Fee" },
    { value: 14, label: "Fund Operations Fee" },
    { value: 15, label: "Start-Up Fund Fee" },
    { value: 16, label: "Hurdle Catch-Up Fee" },
    { value: 17, label: "Catch Up Paid" },
    { value: 50, label: "Payouts" },
    { value: 51, label: "Commitment" }
]

export const cashFlowTypes = {
    "1": "Inflows",
    "2": "Coupon",
    "3": "Dividend",
    "10": "Cost",
    "11": "Management Fee",
    "12": "Performance Fee (Carried Interest Paid)",
    "13": "Deal Fee",
    "14": "Fund Operations Fee",
    "15": "Start-Up Fund Fee",
    "16": "Hurdle Catch-Up Fee",
    "17": "Catch Up Paid",
    "50": "Payouts",
    "51": "Commitment"
}

import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, CircularProgress, Stack  } from '@mui/material';
import NewOrganisationForm from 'Forms/NewOrganisation';
import supabase from 'utils/supabase';
import PageFormContainer from 'Layout/PageFormContainer';

const InitializeNewOnboardingOfPrimaryCustomer = () => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token_hash = urlParams.get('token_hash')
    const type = urlParams.get('type')

    const [loading, setLoading] = useState(true)
    const [session, setSession] = useState(null);

    useEffect(() => {
        supabase.auth.verifyOtp({ token_hash, type }).then(({ data: { session } }) => {
            setSession(session);
            setLoading(false);
          });
          const {
            data: { subscription },
          } = supabase.auth.onAuthStateChange((_event, session) => {
              setSession(session);
          });
          return () => subscription.unsubscribe();
    }, [token_hash, type]);

    
    if(loading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <CircularProgress />
            </Box>
        )

    }

    if(!session) {
        return <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Typography variant='h3'>Unable to confirm invite. Please reach out to pg@rebus-finance.com, so we can resolve the issue.</Typography>
        </Box>
    }

    return (
        <PageFormContainer fluid={'true'} sx={{ top: 0 }}>
            <div>
                <Typography variant="h1" gutterBottom>Rebus Finance</Typography>
            </div>
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={6} md={4}>
                    <Stack spacing={2}>
                        <Typography variant="h6" >Create user with email: <b>{session.user.email}</b></Typography>
                        <NewOrganisationForm />
                    </Stack>
                </Grid>
            </Grid>
        </PageFormContainer>
    )

}

        {/* {activeStep === steps.length ? (
            <div>
                <h1>All steps completed</h1>
                <Button onClick={() => setActiveStep(0)}>Reset</Button>
            </div>
        ) : (
            <div>
                <h1>{steps[activeStep]}</h1>
                <form onSubmit={handleSubmit}>
                    <FormFields activeStep={activeStep} />
                    <Button disabled={activeStep === 0} onClick={handleBack}>Back</Button>
                    <Button type="submit">Next</Button>
                    {error && <p>{error}</p>}
                </form>
            </div>
        )} */}
    

// const InitializeNewOnboardingOfPrimaryCustomer = () => {
//     const [password, setPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const [error, setError] = useState('');

//     const handlePasswordChange = (e) => {
//         setPassword(e.target.value);
//     };

//     const handleConfirmPasswordChange = (e) => {
//         setConfirmPassword(e.target.value);
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();

//         if (password.length < 6 || password !== confirmPassword) {
//             setError('Passwords must be at least 6 characters long and match');
//         } else {
//             // Password validation passed, proceed to the next step
//             // Add your logic here
//         }
//     };

//     return (
//         <div>
//             <h1>Step Guide</h1>
//             <form onSubmit={handleSubmit}>
//                 <TextField
//                     label="Password"
//                     type="password"
//                     value={password}
//                     onChange={handlePasswordChange}
//                 />
//                 <br />
//                 <TextField
//                     label="Confirm Password"
//                     type="password"
//                     value={confirmPassword}
//                     onChange={handleConfirmPasswordChange}
//                 />
//                 <br />
//                 <Button type="submit">Next</Button>
//                 {error && <p>{error}</p>}
//             </form>
//         </div>
//     );
// };

export default InitializeNewOnboardingOfPrimaryCustomer;

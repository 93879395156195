import { APIS } from 'api/constants';
import { action, makeObservable, observable } from 'mobx';
import { mobXStoreHelper } from "utils/mobXStoreHelper"
import { getAssetInfo } from "utils/urlHelpers";

class ETFLoopthroughAssetStore {
    etfLoopthroughAssetTableData = {};
    statusText = '';

    constructor() {
        makeObservable(this, {
            etfLoopthroughAssetTableData: observable,
            getETFLoopthroughAssetData: action.bound,
        });
    }

    async getETFLoopthroughAssetData(assetUrl) {
        this.etfLoopthroughAssetTableData = {};
        this.statusText = 'Loading...';
        const { AssetID, CurrencyCode } = getAssetInfo(assetUrl);
        const params = { AssetID, CurrencyCode };

        mobXStoreHelper(APIS.GET_ETF_LOOPTHROUGH_DATA, params, (response) => {
            if(response.message.UnpackedETFData && response.message.UnpackedETFData.length) {
                this.statusText = '';
                this.etfLoopthroughAssetTableData = response.message.UnpackedETFData[0];
            } else {
                this.statusText = 'No data available';
            }
        });
    }
}

const etfLoopthroughAssetStore = (window.etfLoopthroughAssetStore = new ETFLoopthroughAssetStore());

export default etfLoopthroughAssetStore;

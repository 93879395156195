/* eslint-disable no-useless-escape */
import * as XLSX from "xlsx";
import { isNullDefined, isNumber, isString, isValidDate } from "utils/typeChecker"
import * as FileSaver from "file-saver";

/*
    TABLE LAYOUT FROM EXCEL LOOKS LIKE THIS - straight forward
    +-------+-------+-------+--------+-------+
    +-------+-------+-------+--------+-------+
    +-------+-------+-------+--------+-------+
    +-------+-------+-------+--------+-------+
    +-------+-------+-------+--------+-------+
*/
export const convertSimpleTableLayout = (excelToJsonData) => {
    const layoutHeadersIndex = 0
    const layoutHeaders = excelToJsonData[layoutHeadersIndex]
    const layoutData =  [...excelToJsonData.slice(0, layoutHeadersIndex), ...excelToJsonData.slice(layoutHeadersIndex + 1)];

    // map through layout data
    const formattedArrayData = []
    
    layoutData.forEach((row, rowIndex) => {
        formattedArrayData[rowIndex] = {}
        layoutHeaders.forEach((headerKey, headerKeyIndex) => {
            if(isNullDefined(layoutData[rowIndex][headerKeyIndex]) && headerKey === "") { // Ignore empty cells
                return
            } else if(isNullDefined(layoutData[rowIndex][headerKeyIndex])) { // Backend cannot read null or undefined, so instead we need to pass 0
                formattedArrayData[rowIndex][headerKey] = ""
            } else {
                formattedArrayData[rowIndex][headerKey] = layoutData[rowIndex][headerKeyIndex];
            }
        })
    })

    return formattedArrayData;
}

export const prepareForExcel = (excelToJsonData) => {
    const content = {}
    // let maxRowIndex = 0
    // let maxColIndex = 0
    excelToJsonData.forEach((row, rowIndex) => {
        // if(rowIndex > maxRowIndex) {
        //     maxRowIndex++
        // }
        row.forEach((col, colIndex) => {
            // if(colIndex > maxColIndex) {
            //     maxColIndex++
            // }
            content[`${rowIndex},${colIndex}`] = col 
            // {
            //     value: col,
            //     validator: new SheetsColValidator(col),
            //     type: isNumber(col) ? 'number' : isString(col) ? 'string' : isValidDate(new Date(col)) ? 'date' : 'any'
            // };
        })
    })

    return content;
}

/*
    TABLE LAYOUT FROM EXCEL LOOKS LIKE THIS - has turned rows of values
    +-------+-------+
    +-------+-------+
    +-------+-------+
    +-------+-------+
    +-------+-------+-------+--------+-------+
    +-------+-------+-------+--------+-------+
    +-------+-------+-------+--------+-------+
    +-------+-------+-------+--------+-------+
    +-------+-------+-------+--------+-------+
*/
export const converDoubleDeckerTableLayout = (excelToJson) => {
    const infoData = excelToJson.splice(0, 4)

    const formattedTableData = convertSimpleTableLayout(excelToJson)
    // Formatting the two upper rows manually
    const result = {
        // Portfolio name
        [infoData[0][0]]: infoData[0][1],

        // Portfolio type
        [infoData[1][0]]: infoData[1][1],

        // RealPortfolio
        [infoData[2][0]]: infoData[2][1],

        // Portfolio currency
        [infoData[3][0]]: infoData[3][1],
        Assets: formattedTableData

    }
    return result;
}

export const bulkUploadFromExcel = (e, conversionMethod, cb) => {
    var file = e.target.files[0];
    e.target.value = null

    const reader = new FileReader();
    reader.onload = (onFileSelectEvent) => {

        const bstr = onFileSelectEvent.target.result;
        const wb = XLSX.read(bstr, {type: 'binary', cellDates: true, dateNF: 'yyyy/mm/dd;@'});

        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { raw: true, header: 1 });

        const removedEmptyDataRows = data.filter((el) => el.length)

        const convertedDataArray = conversionMethod(removedEmptyDataRows);

        
        cb(convertedDataArray)
    };
    
    reader.readAsBinaryString(file);
}


const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};

export const exportPortfolioTemplateAsExcel = (filename) => {
    const worksheet = XLSX.utils.json_to_sheet([]);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    XLSX.utils.sheet_add_aoa(worksheet, [["PortfolioName", "", ]], { origin: "A1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["PortfolioType", "", ]], { origin: "A2" });
    XLSX.utils.sheet_add_aoa(worksheet, [["RealPortfolio", "", ]], { origin: "A3" });
    XLSX.utils.sheet_add_aoa(worksheet, [["PortfolioCurrency", "", ]], { origin: "A4" });
    XLSX.utils.sheet_add_aoa(worksheet, [["AssetID", "AssetName", "Holding", "AssetCurrency", "GroupName"]], { origin: "A5" });
    
    XLSX.writeFile(workbook, `${filename}.xlsx`);
    // const wb = { Sheets: { data: ws }, SheetNames: [fileName] };
    // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    // const data = new Blob([excelBuffer], { type: fileType });
    // FileSaver.saveAs(data, fileName + fileExtension);
};

export const exportCashFlowTemplateAsExcel = (filename) => {
    const worksheet = XLSX.utils.json_to_sheet([]);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    XLSX.utils.sheet_add_aoa(worksheet, [["CFAmount", "", ]], { origin: "A1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["CFName", "", ]], { origin: "B1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["CFType", "", ]], { origin: "C1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["CurrencyCode", "", ]], { origin: "D1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["Description", "", ]], { origin: "E1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["FirstDate", "", ]], { origin: "F1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["Frequency", "", ]], { origin: "G1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["IsCommitment", "", ]], { origin: "H1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["LastDate", "", ]], { origin: "I1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["RateOfIncrease", "", ]], { origin: "J1" });
    
    XLSX.writeFile(workbook, `${filename}.xlsx`);
};

export const exportRealTimeAssetPricesTemplateAsExcel = (filename) => {
    const worksheet = XLSX.utils.json_to_sheet([]);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    XLSX.utils.sheet_add_aoa(worksheet, [["AssetID", "", ]], { origin: "A1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["CurrencyCode", "", ]], { origin: "B1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["CleanPrice", "", ]], { origin: "C1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["FXRate", "", ]], { origin: "D1" });
    
   
    
    XLSX.writeFile(workbook, `${filename}.xlsx`);
};

export const exportBulkEditAssetsTemplateFileToExcel = (filename) => {
    const worksheet = XLSX.utils.json_to_sheet([]);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };

    XLSX.utils.sheet_add_aoa(worksheet, [["AssetID", "", ]], { origin: "A1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["CurrencyCode", "", ]], { origin: "B1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["RiskClassification", "", ]], { origin: "C1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["Sector", "", ]], { origin: "D1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["ParentIssuer", "", ]], { origin: "E1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["FreeField", "", ]], { origin: "F1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["UserAC", "", ]], { origin: "G1" });

    XLSX.writeFile(workbook, `${filename}.xlsx`);
};
export const exportBulkEditAssetPricesTemplateFileToExcel = (filename) => {
    const worksheet = XLSX.utils.json_to_sheet([]);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };

    XLSX.utils.sheet_add_aoa(worksheet, [["Date", "", ]], { origin: "A1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["Price", "", ]], { origin: "B1" });

    XLSX.writeFile(workbook, `${filename}.xlsx`);
};
export const exportPreTradeComplianceTemplateFileToExcel = (filename) => {
    const worksheet = XLSX.utils.json_to_sheet([]);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };

    XLSX.utils.sheet_add_aoa(worksheet, [["AssetID", "", ]], { origin: "A1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["AssetCurrencyCode", "", ]], { origin: "B1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["TradedAmount", "", ]], { origin: "C1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["TradedPrice", "", ]], { origin: "D1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["FXRate", "", ]], { origin: "E1" });

    XLSX.writeFile(workbook, `${filename}.xlsx`);
};


import { action, makeObservable, observable } from "mobx";
import { dateToInt } from "utils/dateFormatters";
import portfoliosStore from "stores/portfoliosStore";

class PDFEditorSettingsStore {
    loading = false;
    settings = null;
    uiSettings = {
        pageZoom: 0.8,
    }

    constructor() {
        makeObservable(this, {
            loading: observable,
            settings: observable,
            updateSettingsValue: action,
            uiSettings: observable,
            updateUISettingsValue: action,
            changeCalcDate: action,
        });
    }

    initialize = (settings) => {
        this.settings = settings;
    }

    updateSettingsValue = (key, value) => {
        this.settings[key] = value;
    }

    updateUISettingsValue = (key, value) => {
        this.uiSettings[key] = value;
    }

    async changeCalcDate(customerId, portfolioId, newDate, key) {
        this.settings[key] = dateToInt(newDate)
        
        await portfoliosStore.fetchCustomerPortfolioDetails(customerId, portfolioId, this.startDate, this.endDate);
      }
}

const store = (window.pdfEditorSettingsStore = new PDFEditorSettingsStore());

export default store;
import call, { internalapiCall } from 'api/call';
import { runInAction } from 'mobx';
import { externalApiCallErrorHandler } from "utils/apiDataHelpers";
import snackbar from "stores/snackbarStore";
import navigationStore from "stores/navigationStore";

export const mobXStoreHelper = async (url, params = null, cb = null, errorCb = null) => {
    navigationStore.setLoader(true)

    try {
        const response = await call(url, params);
        if (response.success) {
            runInAction(() => {
                cb(response)
            })
        } else {
            if(errorCb) {
                snackbar.handleOpen(response.meta.data.ErrorText, "error")
                errorCb(response.meta.data.ErrorText, response.meta.data.ErrorCode)
            } else {
                externalApiCallErrorHandler(response.meta.data.ErrorCode, () => {
                    snackbar.handleOpen(response.meta.data.ErrorText, "error")
                })
            }
        }
    } catch(error) {
        console.error(error)
        snackbar.handleOpen(error.toString(), "error")
    } finally {
        navigationStore.setLoader(false)
    }
}

export const mobXStoreInternalApiHelper = async (method, url, params = null, cb, errorCb) => {
    try {
        const response = await internalapiCall(method, url, params);

        runInAction(() => {
            cb(response)
        })
    } catch(error) {
        console.error(error)
        snackbar.handleOpen(error.toString(), "error")
        if(errorCb) {
            errorCb()
        }
    }
}
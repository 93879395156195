/* eslint-disable no-unused-vars */
import call from 'api/call';
import { APIS } from 'api/constants';
import { action, makeObservable, observable, runInAction, toJS } from 'mobx';
import { formattedDateToString, getTodayByNumber, goYearsBack } from "utils/dateFormatters";
import { benchmarkPeriods } from "../Pages/Benchmarks/constants";
import snackbar from "./snackbarStore";
import data from "./dataStore";
import navigation from "./navigationStore";
import { v4 as uuidv4 } from 'uuid';

class BenchmarksStore {
    currentAssetClasses = [];
    searchedCurrentAssetClasses = [];
    defaultAssetClasses = [];
    benchmarkChartData = {};
    benchmarkKiidData = null;
    benchmarkDescription = null;

    benchmarkStatistics = [];
    benchmarkStatisticsKeys = [];
    benchmarkChartPeriodFilter = benchmarkPeriods[3].value;
    priceReturnTypeFilter = "Index";
    benchmarkStatisticsFilter = null
    benchmarkName = "";
    loadBeDetails = false;
    benchmarksList = [];
    selectedBenchmarksKeys = [];
    benchmarkChartDataInterval = benchmarkPeriods[3].interval;
    benchmarkPriceRange = {
        min: 0,
        max: 100
    }


    constructor() {
        makeObservable(this, {
            defaultAssetClasses: observable,
            benchmarkStatistics: observable,
            benchmarkStatisticsFilter: observable,
            setPriceReturnTypeFiler: action.bound,
            benchmarkName: observable,
            fetchCurrentAssetClasses: action.bound,
            loadBeDetails: observable,
            currentAssetClasses: observable,
            searchedCurrentAssetClasses: observable,
            search: action.bound,
            fetchBenchmarkChartData: action.bound,
            fetchBenchMarkDescription: action.bound,
            benchmarkChartData: observable,
            initializeBenchmarkDetails: action.bound,
            benchmarkKiidData: observable,
            benchmarksList: observable,
            selectedBenchmarksKeys: observable,
            setBenchmarkChartPeriodFilter: action.bound,
            benchmarkChartPeriodFilter: observable,
            benchmarkChartDataInterval: observable,
            benchmarkPriceRange: observable,
            benchmarkStatisticsKeys: observable,
            benchmarkDescription: observable,
        });
    }
    
    search(searchString) {
        let newList = this.currentAssetClasses;

        newList = newList.filter(({AssetClass_LongName, BenchMark_Name}) => {
            return AssetClass_LongName.toLowerCase().includes(searchString.toLowerCase()) || 
                BenchMark_Name.toLowerCase().includes(searchString.toLowerCase());
        });

        this.searchedCurrentAssetClasses = newList;
    }

    async initializeBenchmarkDetails(assetClass) {
        const params = [{"AssetClass_ShortName": assetClass, "Weight":100}]
        await this.fetchCurrentAssetClasses();
        await this.fetchBenchmarkChartData(params)
        await this.fetchBenchmarkKiidData(assetClass)
        await this.fetchBenchMarkDescription(assetClass)
        if(this.currentAssetClasses && this.currentAssetClasses.length > 0) {
            this.benchmarkName = this.currentAssetClasses.find((el) => el.AssetClass_ShortName === assetClass).AssetClass_LongName || ""
        } 
    }

    setBenchmarkChartPeriodFilter(filterOption, interval) {
        this.benchmarkChartPeriodFilter = filterOption
        this.benchmarkChartDataInterval = interval;

        const data = toJS(this.selectedBenchmarksKeys).map((assetClassShortName) => ({ "AssetClass_ShortName": assetClassShortName, "Weight":100 }))
        this.fetchBenchmarkChartData(data)
    }

    setPriceReturnTypeFiler(filterOption) {
        this.priceReturnTypeFilter = filterOption

        const data = toJS(this.selectedBenchmarksKeys).map((assetClassShortName) => ({ "AssetClass_ShortName": assetClassShortName, "Weight":100 }))
        this.fetchBenchmarkChartData(data)
    }

    // This gives data for the table
    async calculateBenchMarkStats(assetClasses) {
        navigation.setLoader(true)
        try {
            const response = await call(APIS.CALC_BENCHMARK_STATS, { "AssetClasses": assetClasses, "ToDate": getTodayByNumber(), "PriceReturnType": this.priceReturnTypeFilter, "FromDate": this.benchmarkChartPeriodFilter  });

            if(response.success) {
                runInAction(() => {
                    const data = [{ key: "Periods" }, { key: "Mean" }, { key: "Variance" }, { key: "Standard Deviation" }, { key: "Standard error" }, { key: "Mean Deviation" }, { key: "Median" }, { key: "Maximum" },  { key: "Minimum" }, { key: "Range" }, { key: "Skewness" }, { key: "Kurtosis" }]
                    const keys = []
    
                    response.message.AssetClasses.forEach((el, i) => {
                        const assetClass = el.AssetClass[0].AssetClass_ShortName

                        keys.push(assetClass)



                        data[0] = { ...data[0], [assetClass]: el.Number_Of_Periods }
                        data[1] = { ...data[1], [assetClass]: el.Mean.toFixed(2) }
                        data[2] = { ...data[2], [assetClass]: el.Variance.toFixed(2) }
                        data[3] = { ...data[3], [assetClass]: el.Standard_Deviation.toFixed(2) }
                        data[4] = { ...data[4], [assetClass]: el.Standard_Error.toFixed(2) }
                        data[5] = { ...data[5], [assetClass]: el.Mean_Deviation.toFixed(2) }
                        data[6] = { ...data[6], [assetClass]: el.Median.toFixed(2) }
                        data[7] = { ...data[7], [assetClass]: el.Maximum.toFixed(2) }
                        data[8] = { ...data[8], [assetClass]: el.Minimum.toFixed(2) }
                        data[9] = { ...data[9], [assetClass]: el.Range.toFixed(2) }
                        data[10] = { ...data[10], [assetClass]: el.Skewness.toFixed(2) }
                        data[11] = { ...data[11], [assetClass]: el.Kurtosis.toFixed(2) }
                    })

                    this.benchmarkStatistics = data.map((el) => ({id: uuidv4(), ...el}))
                    this.benchmarkStatisticsKeys = keys
                })
            } else {
                snackbar.handleOpen("Unable to calculate statistics", "error")
            }
        } catch(e) {
            snackbar.handleOpen(e, "error")
        }
        navigation.setLoader(false)
    }

    async fetchBenchmarkChartData(assetClassesArray) {
        navigation.setLoader(true);

        try {
            const response = await call(APIS.GET_BENCHMARK_DATA, { "AssetClasses": assetClassesArray, "FromDate": this.benchmarkChartPeriodFilter, "ToDate": getTodayByNumber(), "PriceReturnType": this.priceReturnTypeFilter });

            if(response.success) {
                runInAction(() => {
                    let charts = {}

                    response.message.AssetClasses.forEach((element, i) => {
                        // const key = `benchmark-${i}`
                        const id = element.AssetClass[0].AssetClass_ShortName.split("_").join(" ").toLowerCase()
                        charts[id] = element.Data.map((item) => ({ [id]: item.Price, date: formattedDateToString(item.Dates), return: item.Return }))
                    })

                    this.benchmarkChartData = charts;

                    this.selectedBenchmarksKeys = response.message.AssetClasses.map((el) => (el.AssetClass[0].AssetClass_ShortName))
                    this.benchmarkPriceRange = {
                        min: response.message.MinValue,
                        max: response.message.MaxValue
                    }
                })

                

                await this.calculateBenchMarkStats(assetClassesArray)
            } else {
                snackbar.handleOpen("Something went wrong, contact support...", "error")
            }
        } catch(e) {
            snackbar.handleOpen("Something went wrong, contact support...", "error")
        }

        navigation.setLoader(false)
    }

    //Fetching Description of BenchMarks.
    async fetchBenchMarkDescription(params = []) {
        navigation.setLoader(true)
        let reqParam = {};
        if (params.length) {reqParam = { AssetClass_ShortName: params }}
        
        try {
            const response = await call(APIS.GET_BENCHMARK_DESCRIPTION, reqParam);
            if (response.success) {
                runInAction(() => {
                    if(response.message.AssetClasses.length) {
                        this.benchmarkDescription = response.message.AssetClasses[0].BenchMark_Description
                    } else {
                        this.benchmarkDescription = "";
                    }
                })
            } else {
                snackbar.handleOpen(response.message.ErrorText, "error")      
            }
        } catch(e) {
            snackbar.handleOpen("Unable to fetch benchmark description", "error")  
        }
        navigation.setLoader(false)
    }

    async fetchBenchmarkKiidData(assetClass) {
        navigation.setLoader(true)
        try {
            const prevYear = goYearsBack(data.calcDate)
            const response = await call(APIS.GET_KID_RISK_CLASS, { "ToDate": data.calcDate, "FromDate": prevYear });

            if (response.success) {
                runInAction(() => {
                    let data = response.message.AssetClasses.find((el) => el.AssetClass_ShortName === assetClass);

                    if(data && data.iRiskKiiD) {
                        data = {...data, iRiskKiiD: data.iRiskKiiD.map((el) => ({ ...el, id: uuidv4() }))}
                    }

                    this.benchmarkKiidData = data;
                })
            } else {
                snackbar.handleOpen(response.message.ErrorText, "error")
            }
        } catch(e) {
            snackbar.handleOpen(e, "error")  
        }
        navigation.setLoader(false)
    }

    async fetchCurrentAssetClasses() {
        navigation.setLoader(true)
        try {
            const response = await call(APIS.GET_CURRENT_ASSET_CLASS, { "IncludeDateRange": 1, "IncludeProcessPar": 1 });
            
            if(response.success) {
                runInAction(() => {
                    this.currentAssetClasses = response.message.AssetClasses.map((el) => ({ ...el, id: el.AssetClass_ShortName, StartDate: formattedDateToString(el.StartDate), EndDate: formattedDateToString(el.EndDate), ParentAC: el.ParentAC }))
                    this.searchedCurrentAssetClasses = this.currentAssetClasses;
                    this.benchmarksList = response.message.AssetClasses.map((el) => ({ value: el.AssetClass_ShortName, label: el.BenchMark_Name }))
                })
            } else {
                snackbar.handleOpen("Unable to get benchmarks", "error")
            }
        } catch(e) {
            snackbar.handleOpen("Unable to get benchmarks", "error")
        }
        navigation.setLoader(false)
    }
}

// this makes it possible to access it via console
const benchmarksStore = (window.benchmarksStore = new BenchmarksStore());

export default benchmarksStore;

export const TOKEN = 'TOKEN';
export const EDITOR = 'slate-editor';
// export const EXP_SECONDS = "EXP_SECONDS"

export const getToken = () => window.localStorage.getItem(TOKEN);
export const setToken = (value) => window.localStorage.setItem(TOKEN, value);
export const removeToken = () => window.localStorage.removeItem(TOKEN);

export const getSlateEditor = () => window.localStorage.getItem(EDITOR);
export const setSlateEditor = (value) => window.localStorage.setItem(EDITOR, JSON.stringify(value));
export const removeSlateEditor = () => window.localStorage.removeItem(EDITOR);


// export const setTokenExpirationSeconds = (value) => window.localStorage.setItem(EXP_SECONDS, value);
// export const getTokenExpirationSeconds = () => window.localStorage.getItem(EXP_SECONDS);

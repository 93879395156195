export const defaultDashboardReport = {
    id: 'mkclds233-e321312-32132112dddg',
    name: "Report 1",
    AccountId: 123456789,
    widgets: [
        { i: 'AUM', x: 0, y: 0, w: 6, h: 2, static: false },
        { i: 'groups-distribution', x: 0, y: 2, w: 2, h: 2, static: false },
        { i: 'risk-classification', x: 2, y: 2, w: 2, h: 2, static: false },
        { i: 'liquidity-distribution', x: 4, y: 2, w: 2, h: 2, static: false },
        { i: 'currency-exposure', x: 0, y: 4, w: 2, h: 2, static: false },
        { i: 'sector-distribution', x: 2, y: 4, static: false, h: 3, w: 4 },
        { i: 'target-weight', x: 0, y: 7, static: false, h: 3, w: 6 },
    ],
    settings: {
        unit: "mio",
        startDate: '2024-01-03',
        endDate: '2024-03-01',
    }
}
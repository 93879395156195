import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import ReactDOM from "react-dom/client";
import Snackbar from "Snackbar/Snackbar";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {theme} from './theme';
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
// import markerSDK from '@marker.io/browser';
import GlobalLoader from 'components/Loaders/GlobalLoader';
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey("bc6309f5e2c7e3a8ff6330b56e38c518Tz04Njg4MixFPTE3NDMwMDkyOTMwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");
// Sentry.init({
//   dsn: "https://269f0c8363174af08684031cd1304884@o1301158.ingest.sentry.io/6536789",
//   // This sets the sample rate to be 10%. You may want this to be 100% while
//   // in development and sample at a lower rate in production
//   replaysSessionSampleRate: 0.1,
//   // If the entire session is not sampled, use the below sample rate to sample
//   // sessions when an error occurs.
//   replaysOnErrorSampleRate: 1.0,
//   integrations: [
//       new BrowserTracing({
//       tracingOrigins: ["app.rebus-finance.com", /^\//],
//     }),
//     new Sentry.Replay(),
//     ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 1.0 : 0.3,
// });

// const initializeWidget = async () => {
//     const widget = await markerSDK.loadWidget({
//         destination: '62bb46d2bfea10500c65600a',
//     });

//     widget.show()
// }

// initializeWidget()

const app = document.getElementById('root');
const root = ReactDOM.createRoot(app);

root.render(
  <ThemeProvider theme={theme}>
      {/* <div className='bg-image' /> */}
      <CssBaseline />
      <GlobalLoader />
      <Snackbar />
      <App />
  </ThemeProvider>
    
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/* eslint-disable no-unused-vars */
import { APIS } from "api/constants";
import { action, makeObservable, observable, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import {mobXStoreHelper} from "utils/mobXStoreHelper";
import snackbarStore from "stores/snackbarStore";

class SwapMatrixStore {
    loading = true;
    volList = [];
    selectedVols = [];

    constructor() {
        makeObservable(this, {
            loading: observable,
            volList: observable,
            selectedVols: observable,

            getList: action.bound,
            getVol: action.bound,
            toggleVolSelection: action.bound,
            getVolRawData: action.bound,
            getVolHist: action.bound,
            deleteVol: action.bound

        });
    }

    setLoading(loading) {
        this.loading = loading;
    }

    async getList() {
        this.setLoading(true);

        mobXStoreHelper(APIS.LIST_SWAPTION_MATRICES, {}, (response) => {
            console.log("VolList", response.message.VolList)
            this.volList = response.message.VolList;
            this.loading = false;
            // this.yieldCurvesList = response.message.YCList
        });
    }

    coatTimeStamp (message) {
        const timeStampNameLength = "TimeStamp".length + 2;
        const timeStampLength = 19;
        const offset = 6;

        const endTag = message.slice(message.indexOf("TimeStamp") + timeStampNameLength+timeStampLength, message.indexOf("TimeStamp") + timeStampNameLength+timeStampLength+offset);

        const coated = message.slice(0, message.indexOf("TimeStamp") + timeStampNameLength) + '"' + message.slice(message.indexOf("TimeStamp")+timeStampNameLength, message.indexOf(endTag)) + '"' + message.slice(message.indexOf(endTag));

        return coated;
    }

    async getVol(volID, cb) {
        const params = {
            VOLID: volID
        };

        mobXStoreHelper(APIS.GET_SWAPTION_MATRIX, params, (response) => {
            const coated = this.coatTimeStamp(response.message);
            cb(JSON.parse(coated));
        });
    }

    toggleVolSelection(vol, unlockList) {
        if (this.selectedVols.some((sVol) => sVol.ID === vol.ID)) {
            // cannot remove if there is only 1
            // if (this.selectedYieldCurves.length <= 1) {
            //     return;
            // }

            // remove
            this.selectedVols = this.selectedVols.filter((sVol) => {
                return sVol.ID !== vol.ID;
            })

            if (unlockList) {
                unlockList();
            }
        } else {
            if (this.selectedVols.length >= 5) {
                snackbarStore.handleOpen("Maximum 5 Swaption Matrices", "info");
                return unlockList();;
            }
            
            this.getVol(vol.ID, (volData) => {
                const newVol = {
                    ...vol,
                    MinDate: volData.MinDate,
                    MaxDate: volData.MaxDate,
                    StandardDeviation: volData.StandardDeviation,
                    Volatility: volData.Volatility
                };

                this.selectedVols = [...this.selectedVols, newVol];
            
                console.log("selectedVols", this.selectedVols);

                if (unlockList) {
                    unlockList();
                }
            });
        }
    }
    
    async getVolRawData(volID, cb) {
        const params = {
            VOLID: volID
        };

        mobXStoreHelper(APIS.GET_SWAPTION_MATRIX_RAW_DATA, params, (response) => {
            const coated = this.coatTimeStamp(response.message);

            function fixCorruptedJSON(jsonString) {
                // Regular expression to find all occurrences of "Option_Expiry" followed by a value
                const regex = /("Option_Expiry"\s*:\s*)([^,"{[\]}]+)/g;
            
                // Replace the values of "Option_Expiry" with double quotes
                const fixedJSON = jsonString.replace(regex, (match, p1, p2) => {
                    return `${p1}"${p2}"`;
                });
            
                return fixedJSON;
            }

            const fixedJSON = fixCorruptedJSON(coated);

            const volRawData = JSON.parse(fixedJSON);
            console.log("getVolRawData", volRawData)

            cb(volRawData);
        });
    }

    async getVolHist() {
        const params = {
            CurrencyCode: "DKK",
            StartDate: 20230701,
            EndDate: 20231001,
            VolType: "S"
        };

        mobXStoreHelper(APIS.GET_SWAPTION_MATRIX_HIST, params, (response) => {
            console.log("getVolHist", response)
        });
    }

    async deleteVol(volID) {
        const params = {
            VOLID: volID
        };

        mobXStoreHelper(APIS.DELETE_SWAPTION_MATRIX, params, (response) => {
            console.log("deleteVol", response)
        });
    }   
}

// this makes it possible to access it via console
const store = (window.swapMatrixStore = new SwapMatrixStore());

export default store;

import { isNumber, isString } from "utils/typeChecker"

export function numberWithCommas(x, fix = 2) {
  if(x && !isString(x) && isNumber(x)) {
    
    const stringifiedNum = x.toFixed(fix).replaceAll(".", ",");
    return stringifiedNum.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  return x
}
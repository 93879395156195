/* eslint-disable no-unused-vars */
import { APIS } from "api/constants";
import { action, makeObservable, observable } from 'mobx';
import snackbarStore from "./snackbarStore";
import { mobXStoreHelper } from 'utils/mobXStoreHelper';
import columns from "Pages/Benchmarks/columns";


class PortfolioHistoricalCalculationsStore {
    FirstDate = null;
    LastDate = null;
    calculations = {
        WEIGHT: {
            "BenchMark Allocation": [],
            "Currency": [],
            "Group": [],
            "Risk_Classification": [],
            "Sector": []
        },
        HIST: {
            "NVaR_Standard_Deviation": [],
            "NVaR_Expected_Return": [],
            "Normal CVaR in Kroner": [],
            "Normal CVaR": [],
            "Normal VaR in Kroner": [],
            "Normal VaR": [],
            "Hist. CVaR in Kroner": [],
            "Hist. CVaR": [],
            "Hist. VaR in Kroner": [],
            "Hist. VaR": [],
            "Risk-Adjusted Return": [],
            "Information Ratio": [],
            "Tracking Error": [],
            "Beta": [],
            "Alpha": [],
            "Omega": [],
            "Sortino": [],
            "Drawdown Duration": [],
            "Max DD": [],
            "Adjusted Sharpe Ratio": [],
            "Sharpe Ratio": [],
            "Standard Deviation": [],
            "Mean": [],
        },
        AUM_NAV: {
            "AUM": [],
        },
        historicReturn: {
            returnTable: {
                columns: [],
                rows: [],
            },
            aumChart: [],
        }
    };
    loading = false;

    constructor() {
        makeObservable(this, {
            calculations: observable,
            fetchHistoricalPortfolioCalculations: action.bound,
            setLoader: action.bound,
        });
    }

    setInitialStore() {

    }

    setLoader(value) {
        this.loading = value;
    }

    //Fetch
    async fetchHistoricalPortfolioCalculations(customerId, portfolioId) {
        const basePArams = { CustomerID: Number(customerId), PortfolioName: portfolioId }
        this.setLoader(true);
        this.setInitialStore();
        mobXStoreHelper(APIS.GET_LIST_OF_CALCULATIONS, basePArams, (response) => {
            if(response.message.HistCalculations && response.message.HistCalculations.length > 0) {
                const newestCalc = response.message.HistCalculations[response.message.HistCalculations.length - 1]
                this.FirstDate = newestCalc.FirstDate
                this.LastDate = newestCalc.LastDate

                const AUMParams = {
                    ...basePArams,
                        "CalcName":"AUM",
                        "FromDate":this.FirstDate,
                        "ToDate":this.LastDate,
                    
                }
                mobXStoreHelper(APIS.GET_CALCULATION_RESULT_HISTORICAL, AUMParams, (AUMResponse) => {
                    const data = AUMResponse.message.TableReturnAsset;
                    
                    const columns = Object.keys(data).map((key) => ({
                        field: key,
                        headerName: key,
                        width: 80,
                      }));
                      
                      const rows = data["What_Year"].map((_, index) => {
                        const row = { id: index };
                        for (const key in data) {
                          row[key] = Array.isArray(data[key]) ? data[key][index] : data[key];
                        }
                        return row;
                      });

                      const returnTable = {
                        columns,
                        rows,
                      }

                      this.calculations.historicReturn.returnTable = returnTable
                      this.calculations.historicReturn.aumChart = AUMResponse.message.HistData

                    // this.calculations.AUM_NAV.AUM = AUMResponse.message.HistData


                })
                // set start and end date
            } else {
                this.setInitialStore();
                this.setLoader(false);
                snackbarStore.handleOpen("No calculations available", "error")
                
            }
        })
        
        // this.loading = true;

        // const response = await call(APIS.GET_LIST_OF_CALCULATIONS, { CustomerID: Number(customerId), PortfolioName: portfolioId });

        // if(response.success) {
        //     // const formattedData = handleFormatCalculations(response.message)

        //     this.calculations = formattedData

        // } else {
        //     externalApiCallErrorHandler(response.meta.data.ErrorCode, () => {
        //         snackbar.handleOpen("An error occured, try again later", "error")
        //     })
        // }

        // this.loading = false;
    }
}

// this makes it possible to access it via console
const store = (window.portfolioHistoricalCalculationsStore = new PortfolioHistoricalCalculationsStore());

export default store;

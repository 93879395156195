/* eslint-disable no-unused-vars */
import { APIS } from "api/constants";
import { action, makeObservable, observable, toJS } from 'mobx';
import { mobXStoreHelper } from "utils/mobXStoreHelper"
import snackbarStore from "stores/snackbarStore";
import { goMonthsBack } from "utils/dateFormatters";

class SwapCurvesStore {
    loading = true;
    yieldCurvesList = [];
    yieldCurvesListFiltered = [];

    yieldCurvesCurrencies = [];
    yieldCurvesCurrencyFilter = [];

    yieldCurvesTenors = [];
    yieldCurvesTenorFilter = [];


    yieldCurvesDates = [];
    yieldCurvesDatesFilter = [];

    yieldCurvesEarliestDate = 0;
    yieldCurvesLatestDate = 0;

    yieldCurvesIsEODFilter = [];

    yieldCurvesIsThereVolLink = [];

    selectedYieldCurves = [];

    yieldCurveSpreadBasisCurveIndex = 0;

    selectedPeriods = [];

    yieldCurveRawData = [];

    yieldCurveHistory = {
        YCHist: []
    };

    yieldCurveHistories = {};

    timeseriesTransformation = {
        options: [
            {
                label: "Abs.",
                field: "ABS"
            },
            {
                label: "Spread",
                field: "SPREAD",
                disabled: (yieldCurvesStore) => {
                    if (yieldCurvesStore.selectedYieldCurves.length !== 2) {
                        return true;
                    }

                    const yc1 = yieldCurvesStore.selectedYieldCurves[0];
                    const yc2 = yieldCurvesStore.selectedYieldCurves[1];

                    if (yc1.Tenor === yc2.Tenor && yc1.Currency === yc2.Currency) {
                        return true;
                    }

                    return false;
                }
            },
            {
                label: "Slope",
                field: "SLOPE",
                disabled: (yieldCurvesStore) => {
                    return yieldCurvesStore.selectedPeriods.length !== 2;
                },
                basePeriod: null
            }
        ],
        active: "ABS",
        previous: "ABS"
    };

    timeseriesDates = {
        range: {
            start: 0,
            end: 0
        },
        selected: {
            start: 0,
            end: 0
        }
    };

    yieldCurveSource = 1 // 1 is default 0 is NOT default

    timeseriesXDomain = [];
    timeseriesXDomainFiltered = [];

    constructor() {
        makeObservable(this, {
            loading: observable,
            yieldCurvesList: observable,
            yieldCurvesListFiltered: observable,
            yieldCurvesCurrencies: observable,
            yieldCurvesIsEODFilter: observable,
            yieldCurvesIsThereVolLink: observable,
            yieldCurvesCurrencyFilter: observable,
            yieldCurvesTenors: observable,
            yieldCurvesTenorFilter: observable,
            yieldCurvesDates: observable,
            yieldCurvesDatesFilter: observable,
            yieldCurvesEarliestDate: observable,
            yieldCurvesLatestDate: observable,
            selectedYieldCurves: observable,
            yieldCurveSpreadBasisCurveIndex: observable,
            selectedPeriods: observable,
            yieldCurveRawData: observable,
            yieldCurveHistory: observable,
            yieldCurveHistories: observable,
            timeseriesTransformation: observable,
            timeseriesDates: observable,
            timeseriesXDomain: observable,
            timeseriesXDomainFiltered: observable,
            getList: action.bound,
            getYieldCurve: action.bound,
            getYieldCurveRawData: action.bound,
            getYieldCurveVolRawData: action.bound,
            getYieldCurveHistory: action.bound,
            updateYCHistory: action.bound,
            filterTimeseriesXDomain: action.bound,
            togglePeriodSelected: action.bound,
            deselectPeriod: action.bound,
            deleteYieldCurve: action.bound,
            yieldCurveSource: observable, 
            setYieldCurveSource: action.bound,


        });
    }

    setYieldCurveSource(source) {
        this.yieldCurveSource = source;
    }

    setInitialState() {
        this.yieldCurvesList = [];
    }

    setLoading(loading) {
        this.loading = loading;
    }

    applyFilter() {
        this.yieldCurvesListFiltered = this.yieldCurvesList.filter((yc) => {
            return (this.yieldCurvesDatesFilter.length === 0 || this.yieldCurvesDatesFilter.includes(yc.Date)) &&
                (this.yieldCurvesCurrencyFilter.length === 0 || this.yieldCurvesCurrencyFilter.includes(yc.Currency)) &&
                (this.yieldCurvesTenorFilter.length === 0 || this.yieldCurvesTenorFilter.includes(yc.Tenor)) &&
                (this.yieldCurvesIsEODFilter.length === 0 || this.yieldCurvesIsEODFilter.includes(yc.EODFlag)) &&
                (this.yieldCurvesIsThereVolLink.length === 0 || this.yieldCurvesIsThereVolLink.includes(yc.IsThereVolLink))
        });
    }

    async getList() {
        this.setInitialState()

        mobXStoreHelper(APIS.LIST_YIELD_CURVES, { YCSource: this.yieldCurveSource }, (response) => {
            this.yieldCurvesList = response.message.YCList

            this.yieldCurvesListFiltered = this.yieldCurvesList;

            const allCurrencies = this.yieldCurvesList.map((yc) => { return yc.Currency })
            this.yieldCurvesCurrencies = [...new Set(allCurrencies)];

            const allTenors = this.yieldCurvesList.map((yc) => { return yc.Tenor })
            this.yieldCurvesTenors = [...new Set(allTenors)];

            const allDates = this.yieldCurvesList.map((yc) => { return yc.Date })
            this.yieldCurvesDates = [...new Set(allDates)];
            this.yieldCurvesDatesFiltered = this.yieldCurvesDates;

            this.yieldCurvesEarliestDate = Math.min(...this.yieldCurvesList.map((yc) => { return yc.Date }));
            this.yieldCurvesLatestDate = Math.max(...this.yieldCurvesList.map((yc) => { return yc.Date }));

            this.setLoading(false);
        });
    }

    async getYieldCurve(yc, cb) {
        mobXStoreHelper(APIS.GET_YIELD_CURVE, { YCID: yc.ID }, (response) => {
            const r = response;

            // Timestamp is not a string in JSON, so we need to make it a string
            const rFixed = JSON.parse(r.message.slice(0, r.message.indexOf("TimeStamp") + 11) + '"' + r.message.slice(r.message.indexOf("TimeStamp") + 11, r.message.indexOf(',"Yie')) + '"' + r.message.slice(r.message.indexOf(',"Yie')));

            cb(rFixed);
        })
    }

    resetTimeseriesTransformation () {
        this.timeseriesTransformation.previous = this.timeseriesTransformation.active;
        this.timeseriesTransformation.active = this.timeseriesTransformation.options[0].field;
    }

    toggleYCSelection(yc, unlockList) {
        this.yieldCurveHistories = [];
        this.selectedPeriods = [];
        this.timeseriesDates.range.start = 0;
        this.timeseriesDates.range.end = 0;
        this.timeseriesDates.selected.start = this.timeseriesDates.selected.end = 0;
        this.timeseriesXDomain = [];

        this.resetTimeseriesTransformation();

        if (this.selectedYieldCurves.some((sYC) => sYC.ID === yc.ID)) {
            this.selectedYieldCurves = this.selectedYieldCurves.filter((sYC) => {
                return sYC.ID !== yc.ID;
            })

            if (unlockList) {
                unlockList();
            }
        } else {
            if (this.selectedYieldCurves.length >= 5) {
                snackbarStore.handleOpen("Maximum 5 Yield Curves", "info");
                return unlockList();;
            }
            
            this.getYieldCurve(yc, (ycData) => {
                this.selectedYieldCurves = [...this.selectedYieldCurves, {
                    ...yc,
                    Data: ycData.YieldCurve,
                    MinDate: ycData.MinDate,
                    MaxDate: ycData.MaxDate,
                }];

                if (unlockList) {
                    unlockList();
                }
            });
        }
    }

    async getYieldCurveRawData(ycID, cb) {
        this.yieldCurveRawData = [];

        mobXStoreHelper(APIS.GET_SWAP_YIELD_CURVE_RAW_DATA, { YCID: ycID }, (response) => {
            const ttt = response;

            // Timestamp is not a string in JSON, so we need to make it a string
            const r = ttt.message.slice(0, ttt.message.indexOf("TimeStamp") + 11) + '"' + ttt.message.slice(ttt.message.indexOf("TimeStamp") + 11, ttt.message.indexOf(',"YCR')) + '"' + ttt.message.slice(ttt.message.indexOf(',"YCR'));

            const data = JSON.parse(r).YCRawData;

            this.yieldCurveRawData = data.map((item, i) => {
                return {
                    ...item,
                    id: i
                };
            });
        })
    }

    coatTimeStamp (message) {
        const timeStampNameLength = "TimeStamp".length + 2;
        const timeStampLength = 19;
        const offset = 6;

        const endTag = message.slice(message.indexOf("TimeStamp") + timeStampNameLength+timeStampLength, message.indexOf("TimeStamp") + timeStampNameLength+timeStampLength+offset);

        const coated = message.slice(0, message.indexOf("TimeStamp") + timeStampNameLength) + '"' + message.slice(message.indexOf("TimeStamp")+timeStampNameLength, message.indexOf(endTag)) + '"' + message.slice(message.indexOf(endTag));

        return coated;
    }

    async getYieldCurveVolRawData(volID, cb) {
        const params = {
            VOLID: volID
        };

        mobXStoreHelper(APIS.GET_SWAPTION_MATRIX_RAW_DATA, params, (response) => {
            const coated = this.coatTimeStamp(response.message);

            function fixCorruptedJSON(jsonString) {
                // Regular expression to find all occurrences of "Option_Expiry" followed by a value
                const regex = /("Option_Expiry"\s*:\s*)([^,"{[\]}]+)/g;
            
                // Replace the values of "Option_Expiry" with double quotes
                const fixedJSON = jsonString.replace(regex, (match, p1, p2) => {
                    return `${p1}"${p2}"`;
                });
            
                return fixedJSON;
            }

            const fixedJSON = fixCorruptedJSON(coated);

            const volRawData = JSON.parse(fixedJSON);
            console.log("getVolRawData", volRawData)

            cb(volRawData);
        });
    }



    updateSpreadBaseCurve(curveID) {
        this.timeseriesTransformation.options[1].baseCurve = curveID;
    }

    updateSlopeBasePeriod(period) {
        this.timeseriesTransformation.options[2].basePeriod = period;
    }

    async getYieldCurveHistory(data, cb) {
        const { Currency, Tenor, MinDate, MaxDate, ID } = data;
        let params = {
            CurveID: ID,
            Tenor,
            CurrencyCode: Currency,
            StartDate: MinDate,
            EndDate: MaxDate,
            Transformation: this.timeseriesTransformation.active,
            Period: toJS(this.selectedPeriods)
        }

        if (this.timeseriesTransformation.active === "SPREAD") {
            if (!this.timeseriesTransformation.options[1].baseCurve) { // set base period if none is there
                this.updateSpreadBaseCurve(this.selectedYieldCurves[0].ID)
            }

            params = {
                ...params,
                CurveID: this.selectedYieldCurves.map((yc) => yc.ID),
                Tenor: this.selectedYieldCurves.map((yc) => yc.Tenor),
                CurrencyCode: this.selectedYieldCurves.map((yc) => yc.Currency),
                BasisCurveID: this.timeseriesTransformation.options[1].baseCurve
            }
        }

        if (this.timeseriesTransformation.active === "SLOPE") {
            if (!this.timeseriesTransformation.options[2].basePeriod) { // set base period if none is there
                this.updateSlopeBasePeriod(this.selectedPeriods[0])
            }

            params = {
                ...params,
                BasisPeriod: this.timeseriesTransformation.options[2].basePeriod
            }
        }

        mobXStoreHelper(APIS.GET_YIELD_CURVE_HISTORY, {...params, YCSource: this.yieldCurveSource }, (response) => {
            if (!response.message.YCHist) {
                return cb(null);
            }

            const tempData = {};

            const { StartDate, EndDate, Dates } = response.message;

            this.timeseriesXDomain = Dates;

            if (EndDate > this.timeseriesDates.range.end) {
                this.timeseriesDates.range.end = this.timeseriesDates.selected.end = EndDate;
            }

            if (StartDate < this.timeseriesDates.range.start) { 
                const sixMonthsFromEnd = goMonthsBack(EndDate, 6);

                if (StartDate < sixMonthsFromEnd) {
                    const nearestDateToSixMonthsFromEnd = Dates.filter((date)=>{return date >= sixMonthsFromEnd; }).reduce((prev, curr) => Math.abs(curr - sixMonthsFromEnd) < Math.abs(prev - sixMonthsFromEnd) ? curr : prev);

                    this.timeseriesDates.range.start = StartDate;
                    this.timeseriesDates.selected.start = nearestDateToSixMonthsFromEnd;
                }
                else { // start date is more recent than 6m before end date
                    this.timeseriesDates.range.start = this.timeseriesDates.selected.start = StartDate;
                }                
            }

            response.message.YCHist.forEach(item => {
                item.Data.forEach(dataItem => {
                    if (!tempData[dataItem.TradingDate]) {
                        tempData[dataItem.TradingDate] = {
                            TradingDate: dataItem.TradingDate
                        };
                    }
                    if (this.timeseriesTransformation.active === "SPREAD") {
                        tempData[dataItem.TradingDate][item.Period] = dataItem.IR_Spread;
                    }
                    else {
                        tempData[dataItem.TradingDate][item.Period] = dataItem.Spot_Rate;
                    }
                });
            });

            cb(response.message)
        })
    }


    async updateYCHistory() {
        // initially we do a full update when any state changes to make sure we have the correct data
        const oldYieldCurveHistories = this.yieldCurveHistories;
        this.yieldCurveHistories = [];

        this.selectedYieldCurves.forEach((yc, i) => {
            this.getYieldCurveHistory(this.selectedYieldCurves[i], (yieldCurveHistory) => {
                if (!yieldCurveHistory) {
                    this.yieldCurveHistories = oldYieldCurveHistories;
                    this.timeseriesTransformation.active = this.timeseriesTransformation.previous;
                    return snackbarStore.handleOpen("There is no data to show for selected parameters.", "error");
                }

                this.yieldCurveHistories = {
                    ...this.yieldCurveHistories,
                    [`${i}`]: yieldCurveHistory
                }

                // SET DATES
                const mergedDatesSet = new Set();
                
                Object.keys(this.yieldCurveHistories).forEach((key) => {
                  const datesArray = this.yieldCurveHistories[key].Dates;
              
                  // Add unique dates to the Set
                  datesArray.forEach((date) => {
                    mergedDatesSet.add(date);
                  });
                });

                const uniqueDates = Array.from(mergedDatesSet);

                this.timeseriesXDomain = uniqueDates;
                this.filterTimeseriesXDomain();
            })
        });
    }

    filterTimeseriesXDomain () {
        this.timeseriesXDomainFiltered = this.timeseriesXDomain.filter((date) => date >= this.timeseriesDates.selected.start && date <= this.timeseriesDates.selected.end);
    }

    resetBeforePeriodUpdate() {
        this.resetTimeseriesTransformation();
        this.updateSlopeBasePeriod(null);
        this.updateSpreadBaseCurve(null);
    }

    togglePeriodSelected(period) {       
        if (!this.selectedPeriods.includes(period)) {
            if (this.selectedPeriods.length >= 5) {
                return snackbarStore.handleOpen("Maximum 5 periods for one Yield Curve", "info");
            }

            if (this.selectedYieldCurves.length > 2 && this.selectedPeriods.length >= 1) {
                return snackbarStore.handleOpen("Maximum 1 period for more than 2 Yield Curves", "info");
            }

            if (this.selectedYieldCurves.length === 2 && this.selectedPeriods.length >= 2) {
                return snackbarStore.handleOpen("Maximum 2 periods for 2 Yield Curves", "info");
            }

            this.resetBeforePeriodUpdate();

            this.selectedPeriods = [...this.selectedPeriods, period];

            this.updateYCHistory();
        }
        else {
            this.resetBeforePeriodUpdate();
            this.deselectPeriod(period);
        }
    }

    deselectPeriod(period) {
        this.timeseriesTransformation.active = this.timeseriesTransformation.options[0].field;

        this.selectedPeriods = this.selectedPeriods.filter((p) => {
            return p !== period;
        });

        if (this.selectedPeriods.length > 0) {
            this.updateYCHistory();
        }
    }

    async deleteYieldCurve(ycID, cb) {
        mobXStoreHelper(APIS.DELETE_YIELD_CURVE, { YCID: ycID }, (response) => {
            this.yieldCurvesListFiltered = this.yieldCurvesListFiltered.filter((yc) => {
                return yc.ID !== ycID
            });

            if (cb) {
                cb();
            }
        })
    }
}

// this makes it possible to access it via console
const store = (window.swapCurvesStore = new SwapCurvesStore());

export default store;

// Theme 24/07/2023
// background: #fafbff
// secondary: #D39664
// primary: #1E3A48
// error: #E28C78
// success: #ADCDBF

export const colors = {
  background: '#CEC7A9',
  primary: '#363445',
  primaryLight: '#809bb3',
  primaryDark: '#455d73',
  primaryContrast: '#FFFFFF',
  secondary: "#ebe4de",

  info: "#DAEDF8",

  error: '#e28c78',
  errorLight: '#F7B5A6',
  errorDark: '#b57060',
  errorContrast: '#FFFFFF',

  success: '#ADCDBF',
  successLight: '#BDE0D1',
  successDark: '#8BA499',
  successContrast: '#FAFFFD',

  warning: '#FFD277',
  warningLight: '#FFE2A8',
  warningDark: '#CCA85F',
  warningContrast: '#FFFFFF',

  textprimary: '#00000066',
  textSecondary: '#969696',

  contrastPalette: {
    one: "#65829a",
    two: "#8286bb",
    three: "#c17fc0",
    four: "#fc749f",
    five: "#ff7f63",
    six: "#ffa600",
  },
  marselisColors: ["#B0B8CB", "#B86445"],
  contrastPaletteArray: ["#577590", "#d16666", "#bfd8bd", "#5c585c", "#eea243", "#0b132b", "#3B4785", "#A7D873", "#4FAC95", "#8A55AE"],
  singleHueScale: {
    one: "#004c6d",
    two: "#295d7e",
    three: "#426f8e",
    four: "#5a82a0",
    five: "#7195b1",
    six: "#88a8c3",
    seven: "#9fbcd5",
    eight: "#b6d0e7",
    nine: "#cee5fa",
    ten: "#e4f8fa",
  },
  singleHueScaleArray: ["#004c6d", "#295d7e", "#426f8e","#5a82a0", "#7195b1", "#88a8c3","#9fbcd5", "#b6d0e7", "#cee5fa", "#e4f8fa",]
};

// export const chartColorsArray = ['#003366', '#0055A4', '#0077BE', '#009ACD', '#00BFFF', '#4682B4', '#87CEEB']
export const chartColorsArray = ['#383553ff', '#46407dff', '#534bafff', '#4e639aff', '#707cb9ff', '#2f61b2ff', '#366d6aff', '#85c5c0ff', '#9fd3bdff', '#b8eac9ff']



// Function to get colors for pie chart
// export function getPieChartColors(numberOfPiePieces) {
//   const colors = [];
//   const totalColors = chartColorsArray.length;

//   if(numberOfPiePieces === 1) {
//     colors.push(chartColorsArray[0]);
//   } else if (numberOfPiePieces === 2) {
//       // Use the first and last colors if there are only two pie pieces
//       colors.push(chartColorsArray[0], chartColorsArray[totalColors - 1]);
//   } else if(numberOfPiePieces === 3) {
//       // Use the first, middle and last colors if there are only three pie pieces
//       colors.push(chartColorsArray[0], chartColorsArray[4], chartColorsArray[totalColors - 1]);
//   } else {
//       // Calculate the step to select colors from the middle
//       const step = Math.floor(totalColors / (numberOfPiePieces + 1));
//       for (let i = 1; i <= numberOfPiePieces; i++) {
//           colors.push(chartColorsArray[i * step]);
//       }
//   }

//   // Ensure we do not exceed 8 colors
//   return colors.slice(0, 8);
// }
 
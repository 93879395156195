/* eslint-disable react/prop-types */
import { Snackbar, Alert as MuiAlert } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import stores from "stores";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomizedSnackbar = observer(() => {
    const { snackbar } = stores

    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    snackbar.handleClose();
  };

  return (
    <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={snackbar.open} autoHideDuration={snackbar.delay} onClose={handleClose}>
      <div>
        <Alert onClose={handleClose} severity={snackbar.severity}>
            {snackbar.text}
        </Alert>
      </div>
    </Snackbar>
  );
})

export default CustomizedSnackbar;
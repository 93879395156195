import { CALCULATION_STATUS, DATA_TYPES, EXCEPTION_ERROR_CODES, NODE_TYPES, REPORT_CALCULATION_IDS } from "api/constants";
import { toJS } from 'mobx';
import { mergeArrays } from "utils/arrayHelpers";
import { portfolioIsNavBased, getPortfolioTypeLabel } from "api/constants"
import { v4 as uuidv4 } from 'uuid';

export const arrayListsToArrayObjects = (payload) => {
    // go through each element and add all that are not arrays to
    let objItems = {}
    Object.keys(payload).forEach((payloadKey, index) => {
        const isNotAnArray = !Array.isArray(payload[payloadKey])
        if (isNotAnArray) {
            objItems[payloadKey] = payload[payloadKey]
        }
    })

    const filtered = Object.values(payload).filter((el) => Array.isArray(el));
    if (filtered.length === 0) {
        return [objItems]
    }


    const keys = Object.keys(payload).filter((key) =>
        Array.isArray(payload[key])
    );
    // TODO: add all elements that are not an array 
    const dataArray = [];

    filtered[0].forEach((listValue, listValueIndex) => {
        const newObj = {
            ...objItems,
        };
        keys.forEach((key, keyIndex) => {
            newObj[keys[keyIndex]] = filtered[keyIndex][listValueIndex];
        });
        dataArray.push(newObj);
    });
    return dataArray;
};

export const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const handleReduceToNumberByKey = (arr, key, decimals = 2) => {
    const accumulatedResult = arr.reduce((acc, item) => {
        return acc += item[key]
    }, 0)

    return parseFloat(accumulatedResult)
}

export const externalApiCallErrorHandler = (errorCode, cb) => {
    const errorExceptions = [
        EXCEPTION_ERROR_CODES.NO_PORTFOLIO_EXISTS,
        EXCEPTION_ERROR_CODES.HAS_NO_CALCULATIONS_ERROR,
        EXCEPTION_ERROR_CODES.NO_PORTFOLIO_EXISTS_ON_CUSTOMER,
        EXCEPTION_ERROR_CODES.NO_CASHFLOW_PROJECTIONS_EXISTS,
        EXCEPTION_ERROR_CODES.NO_CASHFLOW_PROJECTIONS_EXISTS2,
        EXCEPTION_ERROR_CODES.NO_PRICES_TO_CONSTRUCT,
        EXCEPTION_ERROR_CODES.NO_ASSETS,
        EXCEPTION_ERROR_CODES.NO_CUSTOMER_INFO,
        EXCEPTION_ERROR_CODES.NO_PRICES,
        EXCEPTION_ERROR_CODES.NO_PRICES_ON_ASSET,
        EXCEPTION_ERROR_CODES.FILE_DOES_NOT_EXIST,
    ];

    const isErrorException = errorExceptions.indexOf(errorCode) > -1;

    if (!isErrorException) {
        cb()
    }

}

const nodeTypeToString = (nodeType) => {
    if (nodeType === 1) {
        return NODE_TYPES.PORTFOLIO
    } else if (nodeType === 2) {
        return NODE_TYPES.GROUP
    }

    return NODE_TYPES.ASSET
}

const nestByNodeType = (arr, groupBy) => {
    const rootNodeType = {};
    arr.forEach((el, i) => {

        if (el.NodeType === 1) {

            if (el.calculationsObject) {
                rootNodeType[el.keys] = el.calculationsObject[el.keys]
            } else {
                rootNodeType[el.keys] = el
            }

        } else if (el.NodeType === 2) {

            const newObj = { ...el.calculationsObject[el.keys], children: [] };

            if (rootNodeType[el.keys]) {
                // Groups exists allready
                if (rootNodeType[el.keys].children) {
                    rootNodeType[el.keys].children.push(newObj)
                } else {
                    rootNodeType[el.keys] = {
                        ...rootNodeType[el.keys],
                        children: []
                    }
                    rootNodeType[el.keys].children.push(newObj)
                }
            } else {
                rootNodeType[el.keys] = el.calculationsObject[el.keys]
            }

        } else {
            if (rootNodeType[el.keys] && rootNodeType[el.keys].children) {
                const child = rootNodeType[el.keys].children.find((group) => group.ID === el.ParentID);
                const newObj = el.calculationsObject[el.keys];
                if (child) {
                    if (child.children && child.children.length) {
                        child.children.push(newObj)
                    } else {
                        child.children = [newObj]
                    }
                }
            }

        }
    })

    return rootNodeType
}

// Then we format everything to look the right way
// objectifyArrayByKey, keysArr
const formatter = (arrPayload, settings, nestedSubtractKey = undefined, nestedSubArray = undefined) => arrPayload.map((el, index) => {

    const type = nodeTypeToString(el.NodeType || 1)
    const finalObject = {
        keys: settings.keys,
        type,
        NodeType: el.NodeType || 1,
        name: el.ID,
        ParentID: el.ParentID || el.ID,
    }
    // we also want to format maxDrawdown withing the element itself..
    if (el.CalculationStatus === CALCULATION_STATUS.BE_NOT_ASSIGNED) {
        // Add parentID
        return finalObject
    }

    let nestedArrFormatting = []
    // nestedSubtractKey is a value used, if we have a nested element inside an object of arrays that needs formatting
    // we then want to format that as well
    if (nestedSubtractKey && el[nestedSubtractKey] && el[nestedSubtractKey].length) {
        nestedArrFormatting = el[nestedSubtractKey].map((maxDDHistElement) => {
            const formatted = arrayListsToArrayObjects(maxDDHistElement);
            return {
                periods: maxDDHistElement.Number_Of_Periods,
                data: formatted
            };
        })

        // If a nested element is there, add to element before formatting arrays
        el[nestedSubtractKey] = nestedArrFormatting
    }

    let formattedElementArr = []
    // This is for nested arrays that contains the calculations. Some elements are on the same level
    // But in some cases the list is in a nested object
    if (nestedSubArray) {
        el[nestedSubArray].forEach((keyEl) => {
            const formattedKeyElem = arrayListsToArrayObjects(keyEl)
            if (Array.isArray(formattedKeyElem) && formattedKeyElem.length) {
                formattedElementArr.push(formattedKeyElem[0])
            } else {
                formattedElementArr.push(formattedKeyElem);
            }
        })

    } else {
        formattedElementArr = arrayListsToArrayObjects(el)
    }



    const objectified = {}


    formattedElementArr.forEach((arrElem) => {
        const elementKey = arrElem[settings.name]
        objectified[elementKey] = arrElem
    })

    finalObject.calculationsList = formattedElementArr.length > 0 ? formattedElementArr : [];
    finalObject.calculationsObject = objectified;

    return finalObject
})

export const handleCleanUpGlobalCalculations = (globalCalc, assumptionSettings, customerPortfolio) => {
    const formattedData = {};

    // Portfolio
    formattedData.Portfolio = {
        ID: "Portfolio",
        list: customerPortfolio,
        type: "Portfolio"
    }

    /* KEY_FIGURES */
    const keyFiguresArr = globalCalc.find(el => el.ID === REPORT_CALCULATION_IDS.KEY_FIGURES).KeyFigures
    const formattedKeyFiguresArr = formatter(keyFiguresArr, assumptionSettings.periods, "MaxDD_Hist")
    const groupedKeyFigures = nestByNodeType(formattedKeyFiguresArr, assumptionSettings.periods.name, "MaxDD_Hist")

    formattedData[REPORT_CALCULATION_IDS.KEY_FIGURES] = {
        ID: REPORT_CALCULATION_IDS.KEY_FIGURES,
        list: groupedKeyFigures,
        keys: assumptionSettings.periods.keys,
        type: DATA_TYPES.NESTED_PERIODS
    }

    /* RELATIVE_RISK_FIGURES */
    const relativeRiskFiguresArr = globalCalc.find(el => el.ID === REPORT_CALCULATION_IDS.RELATIVE_RISK_FIGURES).RelativeKeyFigures
    const formattedRelativeRiskFiguresArr = formatter(relativeRiskFiguresArr, assumptionSettings.periods)

    const groupedRelativeRiskFigures = nestByNodeType(formattedRelativeRiskFiguresArr, assumptionSettings.periods.name, "MaxDD_Hist")

    formattedData[REPORT_CALCULATION_IDS.RELATIVE_RISK_FIGURES] = {
        ID: REPORT_CALCULATION_IDS.RELATIVE_RISK_FIGURES,
        list: groupedRelativeRiskFigures,
        keys: assumptionSettings.periods.keys,
        type: DATA_TYPES.NESTED_PERIODS
    }

    // /* HISTORICAL_VAR */
    const historicalVaRArr = globalCalc.find(el => el.ID === REPORT_CALCULATION_IDS.HISTORICAL_VAR).HistoricalVaR

    const formattedHistoricalVaRArr = formatter(historicalVaRArr, assumptionSettings.confidenceInterval)
    const groupedHistoricalVaR = nestByNodeType(formattedHistoricalVaRArr, assumptionSettings.confidenceInterval.name)
    formattedData[REPORT_CALCULATION_IDS.HISTORICAL_VAR] = {
        ID: REPORT_CALCULATION_IDS.HISTORICAL_VAR,
        list: groupedHistoricalVaR,
        keys: assumptionSettings.confidenceInterval.keys,
        type: DATA_TYPES.NESTED_CONFIDENCE_INTERVALS
    }

    /* NORMAL_VAR */
    // const normalVaRArr = globalCalc.find(el => el.ID === REPORT_CALCULATION_IDS.NORMAL_VAR).NormalVaR
    // const formattedNormalVaRArr = formatter(normalVaRArr, assumptionSettings.confidenceInterval)
    // const groupedNormalVaR = nestByNodeType(formattedNormalVaRArr, assumptionSettings.confidenceInterval.name)
    // formattedData[REPORT_CALCULATION_IDS.NORMAL_VAR] = {
    //     ID: REPORT_CALCULATION_IDS.NORMAL_VAR,
    //     list: groupedNormalVaR,
    //     keys: assumptionSettings.confidenceInterval.keys,
    //     type: DATA_TYPES.NESTED_CONFIDENCE_INTERVALS
    // }



    /*
        TODO: Add future exposure

        LowerBound: 11340843.18298311
        LowerBoundFirstStep: 11340843.18298311
        LowerBoundLastStep: 11105469.73155478


        UpperBound: 11340843.18298311
        UpperBoundFirstStep: 11340843.18298311
        UpperBoundLastStep: 11576216.63441143


        Value: 11340843.18298311
        ValueFirstStep: 11340843.18298311
        ValueLastStep: 11340843.18298311

    */

    /* FUTURE_EXPOSURE */
    // const futureExposureArr = globalCalc.find(el => el.ID === REPORT_CALCULATION_IDS.FUTURE_EXPOSURE).FutureExposure
    // const fePortfolio = futureExposureArr[0]


    // const feCharts = {}
    // fePortfolio.Results.forEach((el) => {
    //     const confidenceInterval = el.Confidence_Interval[0];

    //     feCharts[confidenceInterval] = el.Value.map((val, i) => {
    //         return {
    //             step: i,
    //             value: val.toFixed(3),
    //             upperBound: el.UpperBound[i].toFixed(3),
    //             lowerBound: el.LowerBound[i].toFixed(3)
    //         }
    //     })
    // })


    // formattedData[REPORT_CALCULATION_IDS.FUTURE_EXPOSURE] = {
    //     ID: REPORT_CALCULATION_IDS.FUTURE_EXPOSURE,
    //     list: feCharts,
    //     keys: assumptionSettings.confidenceInterval.keys,
    //     type: DATA_TYPES.NESTED_CONFIDENCE_INTERVALS_CHART
    // }

    /* CF_PROJECTION */
    // const cFProjectionArr = globalCalc.find(el => el.ID === REPORT_CALCULATION_IDS.CFPROJECTION).CFProjection
    // formattedData[REPORT_CALCULATION_IDS.CFPROJECTION] = {
    //     ID: REPORT_CALCULATION_IDS.CFPROJECTION,
    //     list: cFProjectionArr,
    //     type: DATA_TYPES.PROJECTION
    // }

    return formattedData
}

export const isRef = (item) => {
    if (item.key) {
        return true
    }

    return false
}

const handleSetPortfolioValues = (data) => {
    let portfolioItem = {}

    if (data.ESG && data.ESG.ESG_Portfolio) {
        portfolioItem = {
            ...data.ESG.ESG_Portfolio
        };
    }

    portfolioItem.PortfolioCurrency = data.PortfolioCurrency
    portfolioItem.PortfolioName = data.PortfolioName
    portfolioItem.PortfolioType = data.PortfolioType
    portfolioItem.AUM = data.AssetAmount + (data.CashAmount || 0)
    if(data.GroupResult && data.GroupResult.length) {
        portfolioItem.BenchMark = data.GroupResult[0].BenchMark
    }

    // Only include num of certifcates if port type is nav
    if (portfolioIsNavBased(data.PortfolioType)) {
        portfolioItem.NumberOfCertificates = data.NumberOfCertificates
        portfolioItem.NAV = data.NAV
    }

    // Add return data for entire portfolio 
    portfolioItem = {
        ...portfolioItem,
        ...data.Return[0]
    }

    return portfolioItem
}

const handleNonCalculatedAssets = (data) => {
    const errorData = [];
    data.forEach((el) => {
        
        if (el.NotIncludedIDs && el.NotIncludedIDs.length) {
            el.NotIncludedIDs.forEach((errorIsin, index) => {
                errorData.push({ id: errorIsin, CurrencyCode: el.NotIncludedIDsCurrencyCode[index], description: "Prices are missing. Go to the asset and add at least 2 prices to get a price approximation" });
            })
        }

        // We want to consistently use GroupID as our key
        // return { ...el, GroupID: el.Group_ID.toString() }
    })

    return errorData;
}

export const formatPortfolio = (portfolioWeightData) => {
    if (!portfolioWeightData) {
        return;
    }

    let portfolioWeightDataClone = JSON.parse(JSON.stringify(portfolioWeightData)); // make sure we don't alter the original object

    // Check if calculations are returned
    const hasGroupResult = portfolioWeightDataClone.GroupResult && portfolioWeightDataClone.GroupResult.length
    const hasKeyFigures = portfolioWeightDataClone.KeyTable && portfolioWeightDataClone.KeyTable.length;
    const hasReturnData = portfolioWeightDataClone.Return && portfolioWeightDataClone.Return.length;
    const hasESG = portfolioWeightDataClone.ESG && portfolioWeightDataClone.ESG.ESG_Details && portfolioWeightDataClone.ESG.ESG_Details.length;

    let allPortfolioData = []
    let portfolioGroups = []
    // Used to return overall portfolio data
    // Used for assets with no calculations
    let errorData = [];
    // Used for assets with calculations
    let data = []

    let rawArrays = {
        AssetID: [],
        AssetCurrencyCode: [],
    };

    let portfolioValues = handleSetPortfolioValues(portfolioWeightDataClone)

    if (hasGroupResult) {
        portfolioValues.BenchMark = portfolioWeightDataClone.GroupResult[0].BenchMark;
        portfolioValues.RiskClassification = portfolioWeightDataClone.GroupResult[0].RiskClassification

        errorData = handleNonCalculatedAssets(portfolioWeightDataClone.GroupResult)

        const onlyIncludeGroupDetailsAssets = portfolioWeightDataClone.GroupResult.filter((group) =>{
            return !!group.GroupDetails
        })
        portfolioWeightDataClone = {...portfolioWeightDataClone, GroupResult: onlyIncludeGroupDetailsAssets}

        const portfolioItem = { ...portfolioWeightDataClone.GroupResult[0], GroupDetails: portfolioWeightDataClone.GroupResult[0].GroupDetails.map((el) => ({ ...el, Group_ID: el.ID })) }
        portfolioWeightDataClone.GroupResult.shift();
        const someDome = [portfolioItem, ...portfolioWeightDataClone.GroupResult]
        allPortfolioData = someDome;
    }

    let returnDataFields = [];

    if (hasReturnData) {
        // remove notIncluded ones
        // if there are still IDs not in the portfolio, then add them to non-included with explaination that they have been sold.
        const allowedGroupNames = portfolioWeightDataClone.GroupResult.map((el) => el.Group_ID)
        let returnGroups = portfolioWeightDataClone.Return.filter((rGroup) => allowedGroupNames.includes(rGroup.Group_ID))
        
        const catOrder = portfolioWeightDataClone.GroupResult.map((item) => item.Group_ID);
        const sortedOrderReturn = returnGroups.sort((a, b) => {
            return catOrder.indexOf(a.Group_ID) - catOrder.indexOf(b.Group_ID);
        });

        // remove bits that are not in the portfolio (not from notIncluded, but unexplained)
        const filteredOnlyIncludedSortedOrderReturn = sortedOrderReturn.map((group, i) => {
            if(!group.GroupDetails) {
                return group;
            }

            // TODO: This piece of code checks for assets that have been sold this should be in its own table
            // const extra = group.GroupDetails.filter((item) => { 
            //     return !portfolioWeightDataClone.GroupResult[i].GroupDetails.find((el) => { 
            //         return el.ID === item.AssetID 
            //     }) 
            // });
            // if (extra.length) {
            //     debugger
            //     extra.forEach((item)=>{
            //         errorData.push({ id: item.AssetID, CurrencyCode: item.AssetCurrencyCode, description: "Asset has been sold." });
            //     })
            // }

            return {
                ...group,
                GroupDetails: group.GroupDetails.filter((item) => { 
                    return portfolioWeightDataClone.GroupResult[i].GroupDetails.find((el) => { 
                        return el.ID === item.AssetID 
                    })
                }).sort((a, b) => {
                    const catOrder = portfolioWeightDataClone.GroupResult[i].GroupDetails.map((el) => { 
                        return el.ID
                    })
                    return catOrder.indexOf(a.AssetID) - catOrder.indexOf(b.AssetID);
                })
            }
        })

        let returnTable = [
            allPortfolioData[0],
            ...filteredOnlyIncludedSortedOrderReturn
        ]

        allPortfolioData.forEach((group, index) => {
            const returnGroup = returnTable.find((el) => el.Group_ID === group.Group_ID)

            if (!returnGroup) {
                return group
            }
            group.GroupDetails = group.GroupDetails.map((asset) => {
                if(!returnGroup.GroupDetails) {
                    return asset
                }
                const returnAsset = returnGroup.GroupDetails.find((el) => el.AssetID === asset.ID)
                if(!returnAsset) {
                    return asset
                }
                return {
                    ...asset,
                    ...returnAsset
                }
            })




        })

        returnDataFields = Object.keys(filteredOnlyIncludedSortedOrderReturn[0])
        allPortfolioData = mergeArrays(allPortfolioData, returnTable, "Group_ID", "GroupDetails")
    }

    if (hasKeyFigures) {
        // loop GroupDetails and filter out elements from NotIncludedIDs array
        const filteredKeyTableData = portfolioWeightDataClone.KeyTable.map((group, index) => {
            if (group.GroupDetails && group.GroupDetails.length) {
                group.GroupDetails = group.GroupDetails.filter((el) => {
                    if(!allPortfolioData[index + 1]) {
                        return true;
                    }
                    const notIncludedIDs = allPortfolioData[index + 1].NotIncludedIDs;

                    return notIncludedIDs ? !allPortfolioData[index + 1].NotIncludedIDs.includes(el.ID) : true;
                })
            }

            return group;
        })

        let keytable = [
            // allPortfolioData[0],
            ...filteredKeyTableData
        ]

        allPortfolioData = mergeArrays(allPortfolioData, keytable, "Group_ID", "GroupDetails")
    }

    if (hasESG) {
        allPortfolioData = allPortfolioData.map((group)=>{
            return {
                ...group,
                GroupDetails: group.GroupDetails.map((item)=>{
                    const assetID = item.AssetID;
                    const found = portfolioWeightDataClone.ESG.ESG_Details.find((esgItem)=>{
                        return esgItem.Isin === assetID;
                    });

                    if (found) {
                        return {
                            ...item,
                            ...found
                        }
                    }

                    return item;
                })
            }
        })
    }

    // add portfolio values to the first element
    allPortfolioData[0] = { ...allPortfolioData[0], ...portfolioValues }

    let portfolioRow = allPortfolioData.shift();
    portfolioRow = { ...portfolioRow, ...portfolioValues }

    const mergedGroups = mergeArrays(portfolioRow.GroupDetails, allPortfolioData, "Group_ID")
    const groupRows = mergedGroups;
  
    // THE PORTFOLIO / TOTAL
    data.push({
        ...portfolioRow,
        name: "Total",
        id: portfolioValues.PortfolioName,
        ID: "", // portfolioValues.PortfolioName,
        itemID: portfolioValues.PortfolioName,
        BenchMark: portfolioValues.BenchMark,
        AssetType: getPortfolioTypeLabel(portfolioValues.PortfolioType),
        // group: [groupId],
        CurrencyCode: portfolioValues.PortfolioCurrency,
        variant: "portfolio",
        // name: portfolioValues.PortfolioName,
        NodeType: 1,
        RiskClassification: portfolioValues.RiskClassification,
        Value: portfolioValues.AUM,
        Weight: 100,
        AssetID: "PORTFOLIO",
    })

    // Format the nested data to fit a mui x table
    groupRows.forEach((group) => {
        portfolioGroups.push(group.Group_ID)

        // extracting all return fields to contruct new obj
        const returnSubset = returnDataFields
            .filter(key => key in group) // line can be removed to make it inclusive
            .reduce((obj2, key) => (obj2[key] = group[key], obj2), {});

        const includedKeyfigFields = [
            "Adjusted_Sharpe_Ratio",
            "Alpha",
            "Beta",
            "CVaR_Hist",
            "CVaR_In_Kroner_Hist",
            "CVaR_In_Kroner_Normal",
            "CVaR_Normal",
            "DrawDown_Duration",
            "Expected_Return",
            "FXRate",
            "FXRateDate",
            "Information_Ratio",
            "LowerBoundFirstStep",
            "LowerBoundLastStep",
            "Lower_AnnualReturnFirstStep",
            "Lower_AnnualReturnLastStep",
            "Max_DD",
            "Mean",
            "Omega",
            "Risk_Adjusted_Return",
            "Sharpe_Ratio",
            "Sortino",
            "Standard_Deviation",
            "Tracking_Error",
            "UpperBoundFirstStep",
            "UpperBoundLastStep",
            "Upper_AnnualReturnFirstStep",
            "Upper_AnnualReturnLastStep",
            "VaR_Hist",
            "VaR_In_Kroner_Hist",
            "VaR_In_Kroner_Normal",
            "VaR_Normal",
            "ValueFirstStep",
            "ValueLastStep",
        ];

        const includedKeyfigsObject = includedKeyfigFields
            .filter(key => key in group) // line can be removed to make it inclusive
            .reduce((obj2, key) => (obj2[key] = group[key], obj2), {});

        // construct new obj
        data.push({
            ...returnSubset,
            ...includedKeyfigsObject,
            id: group.Group_ID,
            itemID: group.Group_ID,
            ID: group.Group_ID,
            // group: [groupId],
            BenchMark: group.BenchMark,
            variant: "group",
            name: group.Group_ID,
            NodeType: group.NodeType || null,
            RiskClassification: group.RiskClassification || null,
            Value: group.Value || null,
            Weight: group.Weight || null,
        })

        // GroupDetails will always be assets, so first we push the groups and then the assets of that group. Mui will take care of the grouping 
        if (group.GroupDetails && group.GroupDetails.length) {
            group.GroupDetails.forEach((asset) => {
                // ASSETS / ISINS
                const item = {
                    ...asset,
                    id: uuidv4(),
                    ID: asset.ID,
                    itemID: asset.ID,
                    AssetID: asset.ID,
                    GroupName: group.Group_ID,
                    // group: [groupId, asset.ID],
                    AssetName: asset.AssetName || asset.IDName,
                    name: asset.AssetName || asset.IDName,
                    // Asset can only have one be, but we need to format it like the rest
                    BenchMark: [[asset.BenchMark, 100]],
                    variant: "asset",
                }
                rawArrays.AssetID.push(asset.ID)
                rawArrays.AssetCurrencyCode.push(asset.CurrencyCode)
                data.push(item)
            })
        }

    })

    if (errorData.length) {
        const errorGroupName = "non-calculated-assets-group-id"
        // Note: error data should NOT be part of portfolioGroups as portfolioGroups are meant for user selection
        data.push({
            id: errorGroupName,
            itemID: errorGroupName,
            BenchMark: [],
            variant: "non-calculated-group",
            name: "Non calculated assets",
            NodeType: 2,
            RiskClassification: null,
            Value: null,
            Weight: null,
        })
        errorData.forEach((error) => {
            data.push({
                ...error,
                id: error.id,
                itemID: error.id,
                GroupName: errorGroupName,
                variant: "non-calculated-asset",
                name: error.id,
                BenchMark: [],
            })
        })
    }

    return { data, errorData, portfolioInformation: portfolioValues, rawArrays, portfolioGroups }
}

export const normalize = (window.normalize = (data, keyRef) => {
    const items = {};
    const refs = []
    data.forEach((el) => {
        refs.push(el[keyRef])
        items[el.id] = el;
    })

    return { items, refs }
});

export const deNormalize = (window.deNormalize = (itemsObject, refsArray) => {
    return refsArray.map((key) => {
        return itemsObject[key]
    })
});

export const globalToJS = (window.globalToJS = (payload) => {
    return toJS(payload)
})

export const formatExportPortfolioToExcel = (portfolioList) => {
    const jsData = toJS(portfolioList)
    const assets = [];
    const exportedData = [];

    // Group everything based on variant
    for (let i = 0; i < jsData.length; i++) {
        let item = jsData[i];
        if (item.variant === "asset") {
            assets.push(item)
        }
    }

    for (let i = 0; i < assets.length; i++) {
        let asset = assets[i];
        if (i === 0) {
            const headers = Object.keys(asset).map((keyHeader) => {
                return keyHeader
            })

            exportedData.push(headers)
        }
        const values = Object.values(asset).map((value) => {
            return value
        })

        exportedData.push(values)


    }
    return exportedData;
}

const refactoredWeightHelper = (dataSet, postfixLabel = null, nameKey = null) => {
    let lastItems = [];
    if(dataSet.GroupDetails.length > 10) {
        lastItems = dataSet.GroupDetails.filter((el) => el.Weight <= 0.8)//.splice(10, dataSet.GroupDetails.length)
    }

    const sortedBySizeData = dataSet.GroupDetails.filter((el) => el.Weight > 0.8).sort((a, b) => b.Value -a.Value)
    
    // Format the items to fit into a chart
    const formatted = sortedBySizeData.map((element, i) => {
        
        const item = {
            key: `${postfixLabel ? postfixLabel : ""} ${element.ID}`,
            name: nameKey ? element[nameKey] : `${postfixLabel ? postfixLabel : ""} ${element.ID}`,
            value: element.Weight,
        }

        if(element.Value) {
            item.secondaryValue = element.Value
        }

        return item;
    })

    // No last items to return the array as is
    if(!lastItems.length) {
        return formatted;
    }

    // Group the last items together
    let Value = 0;
    let Weight = 0;
    lastItems.forEach((element) => {
        if(element.Value) {
            Value += element.Value;
        }
        Weight += element.Weight;
    })
    const groupedItem = {
        key: `${postfixLabel ? postfixLabel : ""} *Andre`,
        name: `${postfixLabel ? postfixLabel : ""} *Andre`,
        value: Weight,
    }
    
    if(Value) {
        groupedItem.secondaryValue = Value
    }
    
    formatted.push(groupedItem)
    return formatted;
}


const getChartAndTableFromWeights = (dataCopy, postfixLabel, nameKey) => {
    let dataSet;
    let chart = [];
    let table = [];
    let errorData = [];

    dataCopy.forEach((el, index) => {
        // If first element, we want to construct the chart, based on that dataset
        if(index === 0) {
            dataSet = el;
            if(!dataSet || !dataSet.GroupDetails || !dataSet.GroupDetails.length) {
                return;
            }
            chart = refactoredWeightHelper(dataSet, postfixLabel, nameKey)
        } else {
            
            if(el.NotIncludedIDs && el.NotIncludedIDs.length) {
                el.NotIncludedIDs.forEach((errorIsin, index) => {
                    errorData.push({ id: errorIsin, Currency: el.NotIncludedIDsCurrencyCode[index], description: "Missing prices"  });
                })
            }

            if(!el.GroupDetails || el.GroupDetails.length === 0) {
                return;
            }

            el.GroupDetails.forEach((asset) => {
                const item = {
                    ...asset,
                    Group_ID: el.Group_ID,
                    id: uuidv4(),
                }

                table.push(item)
            })
        }
    })

    return {
        chart,
        table,
        // errorData,
    }
}

export const handleFormatWeights = (resultArray, postfixLabel, nameKey) => {
    let dataCopy = resultArray;

    // Make sure data is there
    if(!dataCopy || dataCopy.length === 0) {
        return [];
    }

    return getChartAndTableFromWeights(dataCopy, postfixLabel, nameKey)
}

export const handleFormatAllocations = (allocations) => {
    const topElements = [];
    const chartAndTableData = []
    // this gives me a subset of the top elements
    allocations.forEach((allocation, index) => {
        const item = getChartAndTableFromWeights(allocation);
        topElements.push(allocation[0]);
        chartAndTableData.push(item);
    })

    const formatted = topElements.map((chart, index) => {
        const allocationItem = {
            AllocationName: chart.AllocationName,
            id: chart.AllocationID,
            chart: chartAndTableData[index].chart,
            table: chartAndTableData[index].table,
            valuePostFix: "%",
            dataKey: "value",
            nameKey: "name"
        }

        return allocationItem
    })

    const mapped = formatted.reduce((acc, item) => acc.set(item.id, item), new Map());

    return {
        data: formatted,
        mapped, 
    }
}


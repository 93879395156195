export const dataSourceOptionsConstants = {
    VENDOR: "VENDOR",
    PROJECTION: "PROJECTION",
    DEPENDENT: "DEPENDENT"
}
export const dataSourceOptions = [{
    value: dataSourceOptionsConstants.VENDOR,
    label: "Vendor"
}, {
    value: dataSourceOptionsConstants.PROJECTION,
    label: "Projection"
}, {
    value: dataSourceOptionsConstants.DEPENDENT,
    label: "Dependent"
}]

export const complexTypes = [{
    value: "ALTERNATIVE",
    label: "Alternative"
}, {
    value: "PRIVATE_EQUITY",
    label: "Private equity"
}, {
    value: "REAL_ESTATE",
    label: "Real estate"
}]

/*
if not private equity
1,10,50



*/
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';

const PageFormContainer = styled(Container)(() => ({
    alignItems: 'center',
    display: 'flex',
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    flexDirection: "column",
    justifyContent: "center",
    top: 0
}))

export default PageFormContainer;
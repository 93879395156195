import { APIS } from 'api/constants';
import { action, makeObservable, observable } from 'mobx';
import {mobXStoreHelper} from "utils/mobXStoreHelper"
import data from "./dataStore";
import { getAssetInfo } from "utils/urlHelpers";
import { v4 as uuidv4 } from 'uuid';
import { numberWithCommas } from "utils/numberManipulators";
import {dateToInt} from "utils/dateFormatters";
import stores from ".";

class AssetReturnStore {
    assetsListOptions = [];
    selectedAssetReturnData = {};
    selectedAssetReturnKeys = [];
    tempStoredAssetReturnData = [];
    assetReturn = {
        assets: [],
        CurrencyCode: null,
        EndDate: null,
    };
    
    constructor() {
        makeObservable(this, {
            fetchAssetReturnChartData: action.bound,
            assetsListOptions: observable,
            selectedAssetReturnData: observable,
            selectedAssetReturnKeys: observable,
            tempStoredAssetReturnData: observable,
            calcAssetReturn: action.bound,
            assetReturn: observable,
        });
    }

    selectAssetReturnComparison(selectedAssetIdsArray) {
        const keys = []
        const valueObj = {}
        selectedAssetIdsArray.forEach((assetId) => {
            const found = this.tempStoredAssetReturnData.find((el) => el.AssetID === assetId)
            if(Object.keys(found).length) {
                valueObj[found.AssetName] = found.returns
                keys.push(found.AssetID)
            }
        })

        this.selectedAssetReturnKeys = keys;
        this.selectedAssetReturnData = valueObj
    }

    async fetchAssetReturnChartData(customerId, portfolioId, assetId, portfolioCurrency) {
        const params = { CurrencyCode: portfolioCurrency, CalculationDate: data.calcDate, UseDefaultStartDates: "Yes", CustomerID: Number(customerId), PortfolioName: portfolioId }
        
        mobXStoreHelper(APIS.CALC_ASSET_RETURN, params, (response) => {
            const sorted = response.message.Return.sort((a,b) => {
                return a.Order - b.Order
            })

            const newConstructionAssetReturn = []
            sorted.forEach((el, ind) => {
                el.Asset.forEach((assetItem, i) => {
                    // On the first row we initialize the items in the new structure
                    if(ind === 0) {
                        newConstructionAssetReturn.push({
                            AssetID: assetItem.AssetID,
                            AssetName: assetItem.AssetName,
                            BenchMarkID: assetItem.BenchMarkID,
                            returns: [{
                                xAxisLabel: el.XAxisLabel,
                                [assetItem.AssetName]: assetItem.Simple_Return
                            }]
                        })
                    } else {
                        newConstructionAssetReturn[i].returns.push({
                            xAxisLabel: el.XAxisLabel,
                            [assetItem.AssetName]: assetItem.Simple_Return
                        })
                    }
                })
            })

            this.assetsListOptions = newConstructionAssetReturn.map((el) => {
                return {
                    value: el.AssetID,
                    label: el.AssetName
                }
            })

            const found = newConstructionAssetReturn.find((el) => el.AssetID === assetId)

            if(Object.keys(found).length) {
                this.selectedAssetReturnData = {
                    [found.AssetName]: found.returns,
                }

                this.selectedAssetReturnKeys.push(found.AssetID)
            }
            
            this.tempStoredAssetReturnData = newConstructionAssetReturn
        })
    }

    async calcAssetReturn(assetUrl) {
        const { AssetID, CurrencyCode } = getAssetInfo(assetUrl);
        const { asset } = stores;
        const params = { AssetID, AssetCurrencyCode: CurrencyCode, CalculationDate: dateToInt(asset.assetHistoryDataToDate), StartDate: dateToInt(asset.assetHistoryDataFromDate) };

        mobXStoreHelper(APIS.CALC_ASSET_RETURN, params, (response) => {
            const formattedAssets = [];
            const sorted = response.message.Return.sort((a,b) => {
                return a.Order - b.Order
            })
            sorted.forEach((el) => {             
                el.Asset.forEach((assetEl) => {  
                    const row = {
                        ...assetEl,
                        id: `${assetEl.AssetID}-${uuidv4()}`,
                        StartDate: el.StartDate,
                        group: [el.XAxisLabel, assetEl.AssetID],
                        Order: el.Order,
                        XAxisLabel: el.XAxisLabel,
                    }

                    if(assetEl.Simple_Return) { row.Simple_Return = numberWithCommas(assetEl.Simple_Return) }
                    if(assetEl.Annual_Return) { row.Annual_Return = numberWithCommas(assetEl.Annual_Return) }
                    if(assetEl.BM_Simple_Return) { row.BM_Simple_Return = numberWithCommas(assetEl.BM_Simple_Return) }
                    if(assetEl.BM_Annual_Return) { row.BM_Annual_Return = numberWithCommas(assetEl.BM_Annual_Return) }

                    if(assetEl.KR_Asset) { row.KR_Asset = numberWithCommas(assetEl.KR_Asset) }
                    if(assetEl.KR_Currency) { row.KR_Currency = numberWithCommas(assetEl.KR_Currency) }
                    if(assetEl.SR_Asset) { row.SR_Asset = numberWithCommas(assetEl.SR_Asset) }
                    if(assetEl.SR_Currency) { row.SR_Currency = numberWithCommas(assetEl.SR_Currency) }

                    formattedAssets.push(row)
                })

            })

            this.assetReturn = {
                CurrencyCode: response.message.CurrencyCode,
                EndDate: response.message.EndDate,
                assets: formattedAssets,
            };
        })
    }

}

const assetReturnStore = (window.assetReturnStore = new AssetReturnStore());

export default assetReturnStore;

import { APIS } from 'api/constants';
import { action, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { mobXStoreHelper } from 'utils/mobXStoreHelper';
import { formattedDateToString } from "utils/dateFormatters";
import snackbar from "./snackbarStore";
import shortid from 'shortid';

class IndicesStore {
    list = [];
    searchedList = [];

    constructor() {
        makeObservable(this, {
            list: observable,
            searchedList: observable,
            fetchList: action.bound,
            search: action.bound,
        });
    }

    search(searchString) {
        let newList = this.list
        newList = newList.filter(({IndexName}) => IndexName.toLowerCase().includes(searchString.toLowerCase()))
        this.searchedList = newList

    }

    async fetchList() {
        mobXStoreHelper(APIS.GET_LIST_OF_INDICES, {}, (response) => {
            this.list = response.message.Indices.map((el) => ({ ...el, id: shortid.generate() }));
        })
    }
}

const indicesStore = (window.indicesStore = new IndicesStore());

export default indicesStore;

import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      exact={false}
      render={(props) => {
        return (
          <Suspense fallback={<></>}>
            <Component
              nextPage={rest.nextPage}
              prevPage={rest.prevPage}
              pageTitle={rest.pageTitle}
              {...props}
            />
          </Suspense>
        );
      }}
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.object.isRequired
};

export default PublicRoute;

export const everyArr = (arr, nth) => arr.filter((e, i) => i % nth === nth - 1);

// Note in order for the nested arrays to work, the two arrays have to be of same length
export const mergeArrays = (arr1, arr2, key, nestedKey) => {
    let merged = [];

    if (nestedKey) {
        merged = arr1.map((item1) => {
            const matchingItem2 = arr2.find((item2) => item2[key] === item1[key]);
            if (matchingItem2) {
                const mergedNestedArray = item1[nestedKey].map((nestedItem1, index) => {
                    const nestedItem2 = matchingItem2[nestedKey][index];
                    return { ...nestedItem1, ...nestedItem2 };
                });
                return { ...matchingItem2, ...item1, [nestedKey]: mergedNestedArray };
            }
            return item1;
        });
    } else {
        merged = arr1.map((item1) => ({
            ...item1,
            ...arr2.find((item2) => item2[key] === item1[key]),
        }));
    }

    return merged;
};

export const updateArrayObject = (array, identifier, key, value) => {
    return array.map((el) => {
        if(el[identifier] === identifier) {
            el[key] = value
        }
        return el
    })
}

export function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

import { GroupsRounded, LocalAtmRounded, DashboardRounded, BusinessCenterRounded, HandshakeRounded, CategoryRounded, StackedLineChartRounded, FunctionsRounded, ReportOutlined, DocumentScannerOutlined } from "@mui/icons-material";
import { CUSTOMERS_OVERVIEW, ASSETS_OVERVIEW, PORTFOLIOS_OVERVIEW, TRADE_MANAGEMENT, DASHBOARD, ALLOCATIONS_OVERVIEW, SERVICES, SWAP_CURVES_OVERVIEW, REPORT_EDITOR } from 'routes/privateRoutes';

let menuItems = [{            
    link: DASHBOARD,
    label: 'Dashboard',
    Icon: DashboardRounded,
    hasBreak: true
}, {
    link: PORTFOLIOS_OVERVIEW,
    label: 'Portfolios',
    Icon: BusinessCenterRounded,
}, {
    link: CUSTOMERS_OVERVIEW,
    label: 'Customers',
    Icon: GroupsRounded,
}, {
    link: ASSETS_OVERVIEW,
    label: 'Assets database',
    Icon: LocalAtmRounded,
    hasBreak: true
}, {
    link: REPORT_EDITOR,
    label: 'Report editor',
    Icon: DocumentScannerOutlined,
    hasBreak: true
},{
    link: TRADE_MANAGEMENT,
    label: 'Trade mgmt.',
    Icon: HandshakeRounded
},{
    link: ALLOCATIONS_OVERVIEW,
    label: 'Allocations',
    Icon: CategoryRounded
}, {
    link: SWAP_CURVES_OVERVIEW,
    label: 'Swap curves',
    Icon: StackedLineChartRounded
}, {
    link: SERVICES,
    label: 'Services',
    Icon: FunctionsRounded
}];


export default menuItems;
/* eslint-disable no-unused-vars */
import call from 'api/call';
import { APIS } from "api/constants";
import { action, makeObservable, observable, runInAction } from 'mobx';
import { externalApiCallErrorHandler } from "utils/apiDataHelpers";
import snackbar from "./snackbarStore";
import { v4 as uuidv4 } from 'uuid';
import navigation from "./navigationStore";
import {mobXStoreHelper} from "utils/mobXStoreHelper"
import MuiColumns from "utils/MuiColumns"

const initialColumnVisibilityModel = {
    TradingDate: true,
    SettlementDate: true,
    TransactionID: true,
    Transaction_Type_ID: true,
    Transaction_Type_IDName: true,
    AccountID: true,
    Account_CurrencyCode: true,
    SecurityAccountID: true,
    AssetID: true,
    AssetName: true,
    Asset_CurrencyCode: true,
    Holding: true,
    TradedPrice: true,
    AcrruedIR: true,
    FXRate: true,
    Amount: true,
    Text: true
}

const localStorageKey = "portfolioTransactionsColumnVisibilityModel"
class TransactionsStore {
    assetTransactions = [];
    currencyTransactions = [];
    transList = [];

    constructor() {
        makeObservable(this, {
            transList: observable,
            fetchPortfolioTransactions: action.bound,
        });
    }

    //Fetch
    async fetchPortfolioTransactions(customerId, portfolioId) {
        const params = { CustomerID: Number(customerId), PortfolioName: portfolioId }
        mobXStoreHelper(APIS.FETCH_TRANSACTIONS, params, async (response) => {
            this.transList = response.message.TransList.map((el) => ({ ...el, id: uuidv4() }));


        })
    }
}

// this makes it possible to access it via console
const store = (window.transactionsStore = new TransactionsStore());

export default store;

import { APIS } from 'api/constants';
import { action, makeObservable, observable } from 'mobx';
import { arrayListsToArrayObjects } from "utils/apiDataHelpers";
import { capitalizeFirstLetter } from '../utils/stringManipulators';
import portfoliosStore from "./portfoliosStore";
import snackbar from "./snackbarStore";
import {mobXStoreHelper} from "utils/mobXStoreHelper"
import snackbarStore from './snackbarStore';


const getFullName = (firstName, middleName, lastName) => {
    let fullName = ""
    if(firstName && firstName.length) {
        fullName += capitalizeFirstLetter(firstName)
    }

    if(middleName && middleName.length) {
        fullName += ` ${capitalizeFirstLetter(middleName)}`
    }

    if(lastName && lastName.length) {
        fullName += ` ${capitalizeFirstLetter(lastName)}`
    }

    return fullName
}
const handleCleanCustomerData = (c, riskProfileData = undefined) => {
    const customer = arrayListsToArrayObjects(c)[0]

    if(customer.Account) {
        customer.Account = arrayListsToArrayObjects(c.Account);
    }
    if(customer.Depot) {
        customer.Depot = arrayListsToArrayObjects(c.Depot);
    }

    const riskProfileNumber = Array.isArray(customer.RiskProfileCategory) ? customer.RiskProfileCategory[0] : customer.RiskProfileCategory
    let riskProfileNumberValue = ""
    if(riskProfileNumber === 0) {
        riskProfileNumberValue = "Not submitted"
    } else {
        riskProfileNumberValue = riskProfileNumber
    }
    
    let onboarding;
    if (customer.KYCDone && customer.RiskProfileCategory) {
        onboarding = 100;
    } else if (customer.KYCDone) {
        onboarding = 50;
    } else {
        onboarding = 0;
    }

    let riskProfileInformation = null;
    if(riskProfileNumber > 0 && riskProfileData) {
        const index = Number(customer.RiskProfileCategory) - 1

        riskProfileInformation = {
            AllowedInvestmentUniverse: riskProfileData["Allowed_Investment_Universe"][index],
            DkFSAClassification: riskProfileData["DK_FSA_Classification"][index],
            Horizon: riskProfileData.Horizon[index],
            RiskProfileCategory: riskProfileData.RiskProfileCategory[index]
        };
    }

    return {
        id: customer.CustomerID,
        CustomerType: customer.CustomerType  || "",
        EMail: customer.EMail || "",
        name: getFullName(customer.FirstName, customer.MiddleName, customer.LastName),
        FirstName: customer.FirstName ? capitalizeFirstLetter(customer.FirstName) : "",
        MiddleName: customer.MiddleName ? capitalizeFirstLetter(customer.MiddleName) : "",
        LastName: customer.LastName ? capitalizeFirstLetter(customer.LastName) : "",
        riskProfileInformation,
        isCustomerOnboarded: onboarding === 100,
        kycDone: customer.KYCDone === 1,
        onboarding,
        riskProfile: riskProfileNumberValue,
        numberOfPortfolios: customer.NoOfPortfolios,
        fetched: false,
        portfolios: [],
    }
}

const handleMapCustomersById = (customers) => {
    const mapped = {}
    customers.forEach((el) =>{
        mapped[el.id] = el
    })
    return mapped;
}

const initialSelectedCustomer = {
    portfolios: [],
    isCustomerOnboarded: false,
    fetched: false,
};

class CustomersStore {
    error = null;
    list = localStorage.getItem("customers") ? JSON.parse(localStorage.getItem("customers")) : [];
    searchedList = [];
    customersById = localStorage.getItem("customersById") ? JSON.parse(localStorage.getItem("customersById")) : {}
    selectedCustomer = initialSelectedCustomer
    loading = false;

    constructor() {
        makeObservable(this, {
            // STATES
            error: observable,
            loading: observable,
            list: observable,
            searchedList: observable,
            selectedCustomer: observable,
            
            // ACTIONS
            search: action.bound,
            getAll: action.bound,
            create: action.bound, // C
            update: action.bound, // U
            getOne: action.bound, // R
            delete: action.bound, // D
            setKYCToDone: action.bound,
            setRiskProfileValue: action.bound,
        });
    }

    search(searchString) {
        let newList = this.list
        newList = newList.filter(({name, EMail}) => {
            return name.toLowerCase().includes(searchString.toLowerCase()) || EMail.toLowerCase().includes(searchString.toLowerCase())
        })
        this.searchedList = newList

    }

    async create(params, cb) {
        mobXStoreHelper(APIS.ADD_CUSTOMER, params, (response) => {
            const riskProfile =
                params.RiskProfileCategory &&
                params.RiskProfileCategory.length &&
                params.RiskProfileCategory[0] > 0
                ? params.RiskProfileCategory[0]
                : "Not submitted";

            const onboarding = 0;

            const customerFullName = `${params.FirstName[0]} ${params.MiddleName[0]} ${params.LastName[0]}`
            const newCustomer = {
                id: Number(params.CustomerID[0]),
                name: customerFullName,
                email: params.EMail[0],
                riskProfile,
                onboarding,
                type: capitalizeFirstLetter(params.CustomerType[0]),
                kycDone: params.KYCDone[0],
                firstName: capitalizeFirstLetter(params.FirstName[0]),
                middleName: capitalizeFirstLetter(params.MiddleName[0]),
                lastName: capitalizeFirstLetter(params.LastName[0]),
            }

            this.list.push(newCustomer);
            const customersById = handleMapCustomersById(this.list)

            this.customersById = customersById

            snackbar.handleOpen(`Customer ${customerFullName} created`, "success");
            if(cb) {
                cb(newCustomer)
            }
        })
    }

    async getAll(cb) {
        this.loading = true;
        mobXStoreHelper(APIS.GET_ALL_CUSTOMER, {}, (response) => {
            const data = response.message.Customers.map((c) => handleCleanCustomerData(c));
            const customersById = handleMapCustomersById(data)

            this.list = data;
            this.customersById = customersById
            this.loading = false;    
            localStorage.setItem("customers", JSON.stringify(data))
            localStorage.setItem("customersById", JSON.stringify(customersById))
            if(cb) {
                cb(data)
            }
        })
    }

    async update(customerId, params, cb) {
        mobXStoreHelper(APIS.UPDATE_CUSTOMER, { ...params, CustomerID: Number(customerId) }, async () => {
            this.selectedCustomer = {
                ...this.selectedCustomer,
                ...params,
            }

            snackbarStore.handleOpen("Customer updated", "success")

            await this.getAll()
            if(cb) {
                cb()
            }
        })
    }

    async setKYCToDone(customerId, cb) {
        const kycDone = 1;
        mobXStoreHelper(APIS.UPDATE_CUSTOMER, { KYCDone: kycDone, CustomerID: Number(customerId) }, () => {
            this.selectedCustomer = {
                ...this.selectedCustomer,
                onboarding: "50%",
                kycDone: kycDone,
            }
            if(cb) {
                cb()
            }
        })
    }

    async setRiskProfileValue(customerId, riskValueState, cb) {
        mobXStoreHelper(APIS.UPDATE_CUSTOMER, { RiskProfileCategory: Number(riskValueState), CustomerID: Number(customerId) }, () => {
            this.getOne(Number(customerId))
            if(cb) {
                cb()
            }
        })
    }

    async getOne(customerId, cb) {
        this.selectedCustomer = initialSelectedCustomer

        mobXStoreHelper(APIS.GET_SELECTED_CUSTOMER, {CustomerID: Number(customerId)}, (customerResponse) => {
            mobXStoreHelper(APIS.GET_RISK_PROFILE_DATA, {}, (riskProfileResponse) => {
                const customerCleanedData = handleCleanCustomerData(customerResponse.message.Customers[0], riskProfileResponse.message);
                this.selectedCustomer = {...customerCleanedData, fetched: true };
                portfoliosStore.fetchCustomerPortfolios(customerId, (list) => {
                    this.selectedCustomer.portfolios = list
                })
                if(cb) {
                    cb(this.selectedCustomer)
                }
            })
        })
    }

    async delete(customerId, cb) {
        mobXStoreHelper(APIS.DELETE_CUSTOMER, { CustomerID: customerId }, () => {
            this.list = this.list.filter((customer) => customerId !== customer.id);
            snackbar.handleOpen("Customer deleted permanently", "success")
            if(cb) {
                cb()
            }
        })
    }
}

// this makes it possible to access it via console
const customersStore = (window.customersStore = new CustomersStore());

export default customersStore;

const BASE_URL = process.env.REACT_APP_BASE_URL;
const PORT = localStorage.getItem("IRISKDEBUGGER") === "true" ? 5089 : process.env.REACT_APP_PORT; // 5089
const IRISK_BO_BASE_URL = process.env.NODE_ENV === "production" ? "https://seashell-app-k9zqa.ondigitalocean.app" : "http://localhost:8080"
export const IRISK_BO_API_URL = `${IRISK_BO_BASE_URL}/api`
//const DEBUG_PORT = process.env.DEBUG_PORT;

export const API_URL = `${BASE_URL}:${PORT}`;

export const REQUEST_RESPONSE_CODES = {
    REQUEST_SUCCESS_CODE: 0,
    INVALID_TOKEN_ERROR_CODE: -1008,
    TOKEN_EXPIRE_ERROR_CODE: -1005,
    LOGIN_HAS_EXPIRED_ERROR_CODE: -1003
};

export const APIS = {
    LOGIN: `${API_URL}/iRisk_LogInToken`, // `${API_URL}/iRisk_LogIn`,
    END_OF_YEAR_BANK_DATE: `${API_URL}/iRisk_EndOfYearBankDate`,
    GET_ALL_PORTFOLIOS: `${API_URL}/iRisk_ListAllPortfolios`,
    GET_ALL_CUSTOMER: `${API_URL}/iRisk_GetAllCustomerInformation`,
    GET_SELECTED_CUSTOMER: `${API_URL}/iRisk_GetSelectedCustomerInformation`,
    GET_RISK_PROFILE_DATA: `${API_URL}/iRisk_GetRiskProfileDescriptions`,
    ADD_CUSTOMER: `${API_URL}/iRisk_AddCustomer`,
    UPDATE_CUSTOMER: `${API_URL}/iRisk_UpdateCustomer`,
    DELETE_CUSTOMER: `${API_URL}/iRisk_DeleteCustomer`,
    GET_PORTFOLIO_DATA: `${API_URL}/iRisk_GetPortfolio`,
    GET_PORTFOLIOS: `${API_URL}/iRisk_GetPortfolioAll`,
    GET_PORTFOLIO_ASSETS: `${API_URL}/iRisk_GetPortfolioTree`,
    ADD_PORTFOLIO: `${API_URL}/iRisk_AddPortfolio`,
    DELETE_PORTFOLIO: `${API_URL}/iRisk_DeletePortfolio`,
    MOVE_ASSET_TO_GROUP: `${API_URL}/iRisk_MoveAsset`,
    GET_TEMPLATE_DATA: `${API_URL}/iRisk_ListWTCTemplates`,
    DEFINE_WTC_TEMPLATE: `${API_URL}/iRisk_DefineWTCTemplate`,
    DELETE_WTC_TEMPLATE: `${API_URL}/iRisk_DeleteWTCTemplate`,
    GET_ASSUMPTIONS: `${API_URL}/iRisk_RetrieveAssumptions`,
    DEFINE_ASSUMPTIONS: `${API_URL}/iRisk_DefineAssumptions`,
    GET_LIST_OF_ADVISORS: `${API_URL}/iRisk_GetListOfAdvisors`,
    DEFINE_ADVISOR: `${API_URL}/iRisk_DefineAdvisor`,
    DELETE_ADVISOR: `${API_URL}/iRisk_DeleteAdvisor`,
    CHANGE_ADVISOR_PASSWORD: `${API_URL}/iRisk_ChangeAdvisorPassword`,
    GET_CURRENT_ASSET_CLASS: `${API_URL}/iRisk_GetListOfAssetClassesCurrent`,
    GET_BENCHMARK_DESCRIPTION: `${API_URL}/iRisk_GetBenchMarkDescription`,
    GET_DEFAULT_ASSET_CLASS: `${API_URL}/iRisk_GetListOfAssetClassesDefault`,
    REPLACE_BENCHMARK: `${API_URL}/iRisk_ReplaceBenchMark`,
    GET_BENCHMARK_DATA: `${API_URL}/iRisk_GetBenchMarkData`,
    GET_REPLACED_BENCHMARKS: `${API_URL}/iRisk_WhichBenchMarksHasBeenReplaced`,
    REVERT_TO_DEFAULT_BENCHMARK: `${API_URL}/iRisk_RevertToDefaultBenchMark`,
    CALC_BENCHMARK_STATS: `${API_URL}/iRisk_CalcBenchMarkStatistics`,
    CALC_PORTFOLIO_RETURN: `${API_URL}/iRisk_CalcReturn`,
    GET_ASSIGNED_WTC_TEMPLATE: `${API_URL}/iRisk_GetAssignedWTCTemplate`,
    GET_COMPLIANCE_BREACHES: `${API_URL}/iRisk_GetComplianceBreach`,
    GET_TEMPLATE_DETAILS: `${API_URL}/iRisk_GetWTCTemplate`,
    GET_COMPLIANCE_RULES: `${API_URL}/iRisk_GetComplianceRules`,
    UPDATE_COMPLIANCE_RULES: `${API_URL}/iRisk_UpdateComplianceRules`,
    ASSIGN_WTC_TEMPLATE: `${API_URL}/iRisk_AssignWTCTemplate`,
    RUN_GLOBAL_CALCULATION: `${API_URL}/iRisk_RunGlobalCalculation`,
    GET_CALCULATIONS_RESULT: `${API_URL}/iRisk_GetCalculationResult`,
    CALC_PORTFOLIO_WEIGHT: `${API_URL}/iRisk_CalcPortfolioWeight`,
    GET_AUM: `${API_URL}/iRisk_CalcPortfolioAUM`,
    CALC_ASSET_RETURN: `${API_URL}/iRisk_CalcAssetReturnAsset`,
    SET_DEFAULT_WTC_TEMPLATE: `${API_URL}/iRisk_SetDefaultWTCTemplate`,
    GET_USER_PROPERTIES: `${API_URL}/iRisk_GetUserProperties`,
    GET_KID_RISK_CLASS: `${API_URL}/iRisk_GetKIDRiskClass`,
    GET_CURRENCY_CODE_LIST: `${API_URL}/iRisk_GetCurrencyCodeList`,
    GET_PREVIOUS_BANK_DATE: `${API_URL}/iRisk_PreviousBankDate`,
    ADD_REAL_TIME_ASSET_DATA: `${API_URL}/iRisk_AddRealTimeData`,
    GET_REAL_TIME_DATA: `${API_URL}/iRisk_GetRealTimeData`,
    DELETE_REAL_TIME_DATA: `${API_URL}/iRisk_DeleteRealTimeData`,    
    GET_LIST_OF_INDICES: `${API_URL}/iRisk_GetListOfIndices`,
    GET_NUMBER_OF_CERTIFICATES: `${API_URL}/iRisk_GetNumberOfCertificates`,
    SAVE_NUMBER_OF_CERTIFICATES: `${API_URL}/iRisk_AddNumberOfCertificates`,
    GET_NAV_OFFICIAL: `${API_URL}/iRisk_NAVOfficial`,
    GET_ASSIGNED_BENCHMARKS: `${API_URL}/iRisk_GetAssignedBenchMarks`,
    UPDATE_ASSIGNED_BENCHMARKS: `${API_URL}/iRisk_UpdateAssignedBenchMarks`,
    UPDATE_PORTFOLIO: `${API_URL}/iRisk_UpdatePortfolio`,
    MERGE_PORTFOLIOS: `${API_URL}/iRisk_MergePortfolio`,
    UPDATE_PORTFOLIO_TREE: `${API_URL}/iRisk_UpdatePortfolioTree`,
    DELETE_ASSET: `${API_URL}/iRisk_DeleteAsset`,
    SEARCH_ASSET: `${API_URL}/iRisk_SearchAssets`,
    ADD_GROUP: `${API_URL}/iRisk_AddGroup`,
    DELETE_GROUP: `${API_URL}/iRisk_DeleteGroup`,
    RENAME_GROUP: `${API_URL}/iRisk_RenameGroup`,
    UPDATE_ASSET_PRICES: `${API_URL}/iRisk_UpdateComplexAssetPrices`,
    DELETE_ASSET_PRICES: `${API_URL}/iRisk_DeleteComplexAssetPrices`,
    SEND_REPORT_NOTIFICATION_TO_CUSTOMER: "http://localhost:8080/send-email",
    GET_ASSET_META_DATA: `${API_URL}/iRisk_GetAssetMetaData`,
    GET_ASSET_DATA: `${API_URL}/iRisk_GetAssetData`,
    GET_ASSETS_STATIC_DATA: `${API_URL}/iRisk_GetAssetStaticData`,
    CONSTRUCT_ASSET_PRICES: `${API_URL}/iRisk_ConstructHistoricalAssetPrices`,
    GET_ASSET_PRICES: `${API_URL}/iRisk_GetComplexAssetPrices`,
    UPDATE_ASSET_META_DATA: `${API_URL}/iRisk_UpdateAssetMetaData`,
    UPDATE_ASSET_PRICE_DATA: `${API_URL}/iRisk_UpdateAssetPriceSource`,
    ADD_ASSET: `${API_URL}/iRisk_AddAssetAssetDB`,
    DELETE_ASSET_DB: `${API_URL}/iRisk_DeleteAssetAssetDB`,
    GET_PRIVATE_EQUITY_DATA: `${API_URL}/iRisk_PEDashBoard`,
    UPDATE_PRIVATE_EQUITY: `${API_URL}/iRisk_UpdatePrivateEquity`,
    GET_ASSET_CASH_FLOW: `${API_URL}/iRisk_GetCFProjections`,
    UPDATE_CF_PROJECTIONS: `${API_URL}/iRisk_UpdateCFProjections`,
    DELETE_ASSET_CASH_FLOW_ITEM: `${API_URL}/iRisk_DeleteCFProjections`,
    RESET_ASSET_META_DATA: `${API_URL}/iRisk_ResetAssetMetaData`,
    GET_CF_TYPES_LISTS: `${API_URL}/iRisk_GetCFTypeList`,
    FETCH_TRANSACTIONS: `${API_URL}/iRisk_GetTransactions`,
    GET_LATEST_TRANSACTION_DATE: `${API_URL}/iRisk_GetLatestTransactionsDate`,
    GET_ETF_LOOPTHROUGH_DATA: `${API_URL}/iRisk_UnpackETF`,
    LIST_ALLOCATIONS: `${API_URL}/iRisk_ListAllocations`,
    GET_ALLOCATION: `${API_URL}/iRisk_GetAllocation`,
    ADD_ALLOCATION: `${API_URL}/iRisk_AddAllocation`,
    GET_ALLOCATION_CONNECTIONS: `${API_URL}/iRisk_GetAllocationConnections`,
    GET_ALLOCATION_CATEGORIES: `${API_URL}/iRisk_GetAllocationCategories`,
    SAVE_CATEGORY_TARGETS: `${API_URL}/iRisk_SaveCategoryTargets`,
    DELETE_ALLOCATION: `${API_URL}/iRisk_DeleteAllocation`,
    UPDATE_ALLOCATION: `${API_URL}/iRisk_UpdateAllocation`,
    OVERWRITE_ALLOCATION: `${API_URL}/iRisk_OverWriteAllocation`,
    ASSIGN_ALLOCATION: `${API_URL}/iRisk_AssignAllocation`,
    FIND_MISSING_ASSETS: `${API_URL}/iRisk_FindMissingAssets`,
    FIND_ALL_ASSETS: `${API_URL}/iRisk_FindAllAssets`,
    CALC_PORTFOLIO_DUR_CONV: `${API_URL}/iRisk_ServiceCalcPortfolioDurConv`,
    LIST_YIELD_CURVES: `${API_URL}/iRisk_ListYC`,
    GET_SWAP_YIELD_CURVE_RAW_DATA: `${API_URL}/iRisk_GetSwapYCRawData`,
    GET_YIELD_CURVE: `${API_URL}/iRisk_GetYC`,
    DELETE_YIELD_CURVE: `${API_URL}/iRisk_DeleteYC`,
    GET_YIELD_CURVE_HISTORY: `${API_URL}/iRisk_GetYCHist`,
    LIST_SWAPTION_MATRICES: `${API_URL}/iRisk_ListVol`,
    GET_SWAPTION_MATRIX: `iRisk_GetVol`,
    GET_SWAPTION_MATRIX_RAW_DATA: `iRisk_GetSwaptionVolRawData`,
    GET_SWAPTION_MATRIX_HIST: `iRisk_GetVolHist`,
    DELETE_SWAPTION_MATRIX: `iRisk_DeleteVol`,
    GET_CALCULATION_RESULT_HIST_CAT: `${API_URL}/iRisk_GetCalculationResultHistCat`,
    GET_LIST_OF_CALCULATIONS: `${API_URL}/iRisk_ListCalculations`,
    GET_CALCULATION_RESULT_HISTORICAL: `${API_URL}/iRisk_GetCalculationResultHist`,
    ADD_ASSET_REPO: `${API_URL}/iRisk_AddAssetAssetDBRepo`,
    ADD_ASSET_TERMIN: `${API_URL}/iRisk_AddAssetAssetDBTermin`,
    ADD_ASSET_SWAP: `${API_URL}/iRisk_AddAssetAssetDBSwap`, 

    UPLOAD_YIELD_CURVES: `${API_URL}/iRisk_UploadSwapYC`, 
    UPLOAD_TRANSACTIONS_DIRECT: `${API_URL}/iRisk_UploadTransactionsDirect`,
};

export const METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
};
export const EXTERNAL_REQUEST = {
    NO_REQUEST_METHODS: [METHODS.GET, METHODS.DELETE, METHODS.HEAD],
    NO_RESPONSE_METHODS: [METHODS.DELETE],
    REQUEST_SUCCESS_MESSAGE: 'Request was successful.',
    SESSION_EXPIRED: 'Session expired. Please login again.',
    REQUEST_FAILURE_MESSAGE: 'Something went wrong.',
    UNAUTHORIZED_MESSAGE: 'Access Denied.',
    REQUEST_SUCCESS_CODE: 0,
    INVALID_TOKEN_ERROR_CODE: -1008,
    TOKEN_EXPIRE_ERROR_CODE: -1005,
    LOGIN_HAS_EXPIRED_ERROR_CODE: -1003,
};

export const EXCEPTION_ERROR_CODES = {
    NO_PORTFOLIO_EXISTS: -233,
    HAS_NO_CALCULATIONS_ERROR: -278,
    NO_PORTFOLIO_EXISTS_ON_CUSTOMER: -155,
    NO_CASHFLOW_PROJECTIONS_EXISTS: -288,
    NO_CASHFLOW_PROJECTIONS_EXISTS2: -289,
    NO_PRICES_TO_CONSTRUCT: -273,
    NO_ASSETS: -64,
    NO_CUSTOMER_INFO: -152,
    NO_PRICES: -272,
    NO_PRICES_ON_ASSET: -73,
    FILE_DOES_NOT_EXIST: -501
}

export const REPORT_CALCULATION_IDS = {
    KEY_FIGURES: "Key_Figures",
    RELATIVE_RISK_FIGURES: "Relative_Risk_Figures",
    HISTORICAL_VAR: "Historical_VaR",
    // NORMAL_VAR: "Normal_VaR",
    // FUTURE_EXPOSURE: "Future_Exposure",
    // CFPROJECTION: "CFProjection",
    PORTFOLIO_WEIGHT: "Portfolio_Weight",
}

export const NODE_TYPES = {
    PORTFOLIO:"PORTFOLIO",
    GROUP:"GROUP",
    ASSET:"ASSET",
}

export const CALCULATION_STATUS = {
    BE_NOT_ASSIGNED: "BenchMark not Assigned",
    OK: "Ok"
}

export const DATA_TYPES = {
    PROJECTION: "PROJECTION",
    NESTED_CONFIDENCE_INTERVALS_CHART: "NESTED_CONFIDENCE_INTERVALS_CHART",
    NESTED_CONFIDENCE_INTERVALS: "NESTED_CONFIDENCE_INTERVALS",
    NESTED_PERIODS: "NESTED_PERIODS"
}

export const DUMMY_BRAND = "kh_investment_partner.png"
// export const DUMMY_BRAND = "brygge_logo.png"

export const PORTFOLIO_TYPES = {
    HOLDING: 1,
    TRANSACTIONS: 2,
    HOLDING_MODEL: 3,
    HOLDING_NAV: 4,
    TRANSACTIONS_NAV: 5,
    VALUE_WEIGHTED: 6,
    HOLDINGS_FIXED: 7,
    MANUAL_TRANSACTIONS_BASED: 8,
}

export const portfolioIsTransactionsBased = (portfolioType) => portfolioType === PORTFOLIO_TYPES.TRANSACTIONS || portfolioType === PORTFOLIO_TYPES.TRANSACTIONS_NAV // || portfolioType === PORTFOLIO_TYPES.MANUAL_TRANSACTIONS_BASED;
export const portfolioIsValueWeighted = (portfolioType) => portfolioType === PORTFOLIO_TYPES.VALUE_WEIGHTED;
export const portfolioIsNavBased = (portfolioType) => portfolioType === PORTFOLIO_TYPES.TRANSACTIONS_NAV || portfolioType === PORTFOLIO_TYPES.HOLDING_NAV;
export const getPortfolioTypeLabel = (portfolioType) => {
    switch (portfolioType) {
        case PORTFOLIO_TYPES.HOLDING:
            return "Holding";
        case PORTFOLIO_TYPES.TRANSACTIONS:
            return "Transactions";
        case PORTFOLIO_TYPES.HOLDING_MODEL:
            return "Holding Model";
        case PORTFOLIO_TYPES.HOLDING_NAV:
            return "Holding NAV";
        case PORTFOLIO_TYPES.TRANSACTIONS_NAV:
            return "Fond";
        case PORTFOLIO_TYPES.VALUE_WEIGHTED:
            return "Value weighted";
        default:
            return "";
    }
}
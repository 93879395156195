import { APIS } from 'api/constants';
import { action, makeObservable, observable, toJS } from 'mobx';
import snackbar from "./snackbarStore";
import {mobXStoreHelper} from "utils/mobXStoreHelper"
import { defaultData } from "Pages/Settings/defaultValues"

class AssumptionStore {
    groups = {};
    originalGroups = {};

    constructor() {
        makeObservable(this, {
            groups: observable,
            originalGroups: observable,
            getAssumptions: action.bound
        });
    }

    findAssumptionbyName (name) {
        // loop groups and look for assumption
        for (const key in this.groups) {
            if (this.groups.hasOwnProperty(key)) {
                const assumption = this.groups[key].find(assumption => assumption.Variable === name);
                if (assumption) {
                    return assumption.Value;
                }
            }
        }
    }

    async getAssumptions() {
        mobXStoreHelper(APIS.GET_ASSUMPTIONS, {}, (response) => {
            const data = response.message.Assumptions;

            data.forEach(entry => {
                let { Group, Value } = entry;
                if (!this.groups[Group]) {
                    this.groups[Group] = [];
                }

                this.groups[Group].push({ Value, Variable: entry.Variable });
            });

            if (response.message.RiskClassification) {
                const riskClassification = {
                    Group: response.message.RiskClassification.Group,
                    Variable: "RiskClassification",
                    Value: {
                        ...response.message.RiskClassification
                    }
                };
    
                const riskClassificationGroup = riskClassification.Group;
                delete riskClassification.Group;
    
                this.groups[riskClassificationGroup] = this.groups[riskClassificationGroup] ? [...this.groups[riskClassificationGroup], riskClassification] : [riskClassification];
            }

            if (response.message.YCLink) { 
                const ycLink = {
                    Group: response.message.YCLink.Group,
                    Variable: "YCLink",
                    Value: {
                        CurrencyCode: response.message.YCLink.CurrencyCode,
                        Links: [
                            {
                                label: "Tenor",
                                value: response.message.YCLink.Tenor
                            },
                            {
                                label: "Discounting",
                                value: response.message.YCLink.Discounting
                            }
                        ],
                        Options: defaultData().YCLink.Options
                    }
                };

                const ycLinkGroup = ycLink.Group;
                delete ycLink.Group;

                this.groups[ycLinkGroup] = this.groups[ycLinkGroup] ? [...this.groups[ycLinkGroup], ycLink] : [ycLink];
            }


            this.originalGroups = toJS(this.groups);
        })
    }

    async saveAssumptions() {
        const newAssumptions = {};

        for (const key in this.groups) {
            if (this.groups.hasOwnProperty(key)) {
                this.groups[key].forEach(item => {
                    if (item.Variable === "YCLink") {
                        newAssumptions[item.Variable] = {
                            CurrencyCode: toJS(item.Value.CurrencyCode),
                            Tenor: toJS(item.Value.Links[0].value),
                            Discounting: toJS(item.Value.Links[1].value)
                        };
                    } 
                    else {
                        newAssumptions[item.Variable] = toJS(item.Value);
                    }
                });
            }
        }

        console.log("new assumptions", newAssumptions)
        mobXStoreHelper(APIS.DEFINE_ASSUMPTIONS, newAssumptions, (response) => {
            if (response.success && response.message.ErrorCode === 0) {
                this.originalGroups = toJS(this.groups);
                snackbar.handleOpen("Assumptions saved successfully", "success");
            }
        })
    }
}

const assumptionStore = (window.assumptionStore = new AssumptionStore());

export default assumptionStore;

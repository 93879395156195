
import { getDateByNumber } from "utils/dateFormatters";
export const benchmarkPeriods = [{
    label: "1 Month",
    value: getDateByNumber(1, "months"),
    interval: 5,
}, {
    label: "3 Months",
    value: getDateByNumber(3, "months"),
    interval: 50,
},{
    label: "6 Months",
    value: getDateByNumber(6, "months"),
    interval: 100,
}, {
    label: "1 Year",
    value: getDateByNumber(1, "years"),
    interval: 160,
}, {
    label: "3 Years",
    value: getDateByNumber(3, "years"),
    interval: 500,
}, {
    label: "5 Years",
    value: getDateByNumber(3, "years"),
    interval: 700,
}, {
    label: "10 Years",
    value: getDateByNumber(10, "years"),
    interval: 3000,
}]


export const benchmarkReturnTypes = [{
    label: "Index",
    value: "Index"
}, {
    label: "Price",
    value: "Raw"
}]
export const dataKeys = {
    Global_ComplianceRules: "Global_ComplianceRules",
    AssetID_ComplianceRules: "AssetID_ComplianceRules",
    AssetIDTotal_ComplianceRules: "AssetIDTotal_ComplianceRules",
    BondIssuerExposure_ComplianceRules: "BondIssuerExposure_ComplianceRules",
    BondTypeExposure_ComplianceRules: "BondTypeExposure_ComplianceRules",
    CurrencyExposure_ComplianceRules: "CurrencyExposure_ComplianceRules",
    Group_ComplianceRules: "Group_ComplianceRules",
    RiskClass_ComplianceRules: "RiskClass_ComplianceRules",
    Sector_ComplianceRules: "Sector_ComplianceRules"
}

export const dataKeysElementIds = {
    Global_ComplianceRules: "RiscFactor",
    AssetID_ComplianceRules: "AssetID",
    AssetIDTotal_ComplianceRules: "AssetID",
    BondIssuerExposure_ComplianceRules: "IssuerName",
    BondTypeExposure_ComplianceRules: "BondType",
    CurrencyExposure_ComplianceRules: "CurrencyCode",
    Group_ComplianceRules: "GroupName",
    RiskClass_ComplianceRules: "RiskClassification",
    Sector_ComplianceRules: "SectorName"
}

export const dataKeysTableGroups = {
    Global_ComplianceRules: "Portfolio",
    AssetID_ComplianceRules: "Assets",
    AssetIDTotal_ComplianceRules: "Assets total",
    BondIssuerExposure_ComplianceRules: "Bond issuer exposure",
    BondTypeExposure_ComplianceRules: "Bond type exposure",
    CurrencyExposure_ComplianceRules: "Currency exposure",
    Group_ComplianceRules: "Group",
    RiskClass_ComplianceRules: "Risk classification",
    Sector_ComplianceRules: "Sector"
}

export const dataKeysFilters = {
    Global_ComplianceRules: "Global_ComplianceRules",
    AssetID_ComplianceRules: "AssetIDActual_ComplianceRules",
    AssetIDTotal_ComplianceRules: "AssetIDActualTotal_ComplianceRules",
    BondIssuerExposure_ComplianceRules: "BondIssuerExposure_ComplianceRules",
    BondTypeExposure_ComplianceRules: "BondTypeExposure_ComplianceRules",
    CurrencyExposure_ComplianceRules: "CurrencyExposureActual_ComplianceRules",
    Group_ComplianceRules: "Group_ComplianceRules",
    RiskClass_ComplianceRules: "RiskClassActual_ComplianceRules",
    Sector_ComplianceRules: "SectorActual_ComplianceRules"
}

export const complianceGroups = [
    { dataKey: dataKeys.Global_ComplianceRules, label: "Portfolio", key: "PortfolioRules", headers: ["Risk Factor", "Minimum Value", "Maximum Value", "Tolerance"], columns: ["RiscFactor", "MinValue", "MaxValue", "Tolerance"]},
    { dataKey: dataKeys.AssetID_ComplianceRules, label: "Assets", key: "AssetsRules", headers: ["Asset", "Minimum Value", "Maximum Value", "Minimum Weight", "Maximum Weight", "Tolerance"], columns: ["AssetName", "MinValue", "MaxValue", "MinWeight", "MaxWeight", "Tolerance"]},
    { dataKey: dataKeys.AssetIDTotal_ComplianceRules, label: "Assets total", key: "AssetsTotalRules", headers: ["Asset", "Minimum Value", "Maximum Value", "Minimum Weight", "Maximum Weight", "Tolerance"], columns: ["AssetName", "MinValue", "MaxValue", "MinWeight", "MaxWeight", "Tolerance"]},
    { dataKey: dataKeys.BondTypeExposure_ComplianceRules, label: "Bond type exposure", key: "BondTypeExposure", headers: ["BondType", "Minimum Value", "Maximum Value", "Minimum Weight", "Maximum Weight", "Tolerance"], columns: ["BondType", "MinValue", "MaxValue", "MinWeight", "MaxWeight", "Tolerance"]},
    { dataKey: dataKeys.BondIssuerExposure_ComplianceRules, label: "Bonds issuer exposure", key: "BondsIssuerExposureRules", headers: ["Issuer name", "Minimum Value", "Maximum Value", "Minimum Weight", "Maximum Weight", "Tolerance"], columns: ["IssuerName", "MinValue", "MaxValue", "MinWeight", "MaxWeight", "Tolerance"]},
    { dataKey: dataKeys.Group_ComplianceRules, label: "Groups", key: "GroupsRules", headers: ["Group name", "Minimum Value", "Maximum Value", "Minimum Weight", "Maximum Weight", "Tolerance"], columns: ["GroupName", "MinValue", "MaxValue", "MinWeight", "MaxWeight", "Tolerance"]},
    { dataKey: dataKeys.RiskClass_ComplianceRules, label: "Risk Classification", key: "RiskClassificationRules", headers: ["Risk Class", "Minimum Value", "Maximum Value", "Minimum Weight", "Maximum Weight", "Tolerance"], columns: ["RiskClassification", "MinValue", "MaxValue", "MinWeight", "MaxWeight", "Tolerance"]},
    { dataKey: dataKeys.Sector_ComplianceRules, label: "Sector", key: "SectorRules", headers: ["Sector Name", "Minimum Value", "Maximum Value", "Minimum Weight", "Maximum Weight", "Tolerance"], columns: ["SectorName", "MinValue", "MaxValue", "MinWeight", "MaxWeight", "Tolerance"]},
    { dataKey: dataKeys.CurrencyExposure_ComplianceRules, label: "Currency Exposure", key: "CurrencyExposureRules", headers: ["Currency", "Minimum Value", "Maximum Value", "Minimum Weight", "Maximum Weight", "Tolerance"], columns: ["CurrencyCode", "MinValue", "MaxValue", "MinWeight", "MaxWeight", "Tolerance"]}
];

export const riscFactorOptions = [
    {
        name: "Historical VaR",
        value: 'VaRHistorical'
    },
    {
        name: "Historical VaR in DKK",
        value: 'VarHistoricalInKroner'
    },
    {
        name: "Historical CVaR",
        value: 'CVaRHistorical'
    },
    {
        name: "Historical CVaR in DKK",
        value: 'CVaRHistoricalInKroner'
    },
    {
        name: "Normal VaR",
        value: 'VaRNormal'
    },
    {
        name: "Normal VaR in DKK",
        value: 'VaRNormalInKroner'
    },
    {
        name: "Normal CVaR",
        value: 'CVaRNormal'
    },
    {
        name: "Normal CVaR in DKK",
        value: 'CVaRNormalInKroner'
    },
    {
        name: "Standard Deviation",
        value: 'Standard_Deviation'
    },
    {
        name: "Max Drawdown",
        value: 'Max_DD'
    },
];

export const riskClassificationOptions = [1,2,3,4,5,6,7]

import { lazy } from 'react';
import appRouteConstructor from 'routes/appRouteConstructor';

const AssetsOverview = lazy(() => import('Pages/Assets/Overview'));
const RealTimeAssets = lazy(() => import('Pages/Assets/RealTimeAssets'));
const Indices = lazy(() => import('Pages/Assets/Indices'));
const Benchmarks = lazy(() => import('Pages/Benchmarks/Benchmarks'));
const BenchmarkDetails = lazy(() => import('Pages/Benchmarks/BenchmarkDetails'));

export const REAL_TIME_ASSETS = '/real-time-assets';
export const INDICES = '/indices';
export const BENCHMARKS_OVERVIEW = '/benchmarks-overview';
export const BENCHMARK_DETAILS = '/benchmarks-overview/:benchmarkId';
export const ASSETS = '/';

export const ROUTES = [
    appRouteConstructor('RealTimeAssets', REAL_TIME_ASSETS, RealTimeAssets),
    appRouteConstructor('Indices', INDICES, Indices),
    appRouteConstructor('Benchmark details', BENCHMARK_DETAILS, BenchmarkDetails),
    appRouteConstructor('Benchmarks', BENCHMARKS_OVERVIEW, Benchmarks, true),
    appRouteConstructor('Assets', ASSETS, AssetsOverview, true),
];

import React, { useState } from 'react';
import { TextField, Button, Stack } from '@mui/material';
import supabase from 'utils/supabase';
import stores from 'stores';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleEmailChange = (e) => {
        let emailError = "";
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(e.target.value)) {
            emailError = 'Invalid email format';
        }

        setError(emailError);
        setEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Email validation using regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address');
            return;
        }

        // Reset error state and perform further actions
        setError('');
        // Add your logic for handling the forgotten password here

        supabase.auth.resetPasswordForEmail(email, {
            redirectTo: 'http://localhost:3000/update-password',
        }).then(() => {
            stores.snackbar.handleOpen('We have sent you an email to reset your password', 'success');
        })
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
                <TextField
                    label="Email"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    error={!!error}
                    helperText={error}
                    required
                    fullWidth
                />
                <Button type="submit" variant="contained" color="primary">
                    Reset Password
                </Button>
            </Stack>
        </form>
    );
};

export default ForgotPassword;
import React from 'react';
import {LinearProgress } from '@mui/material';
import { observer } from 'mobx-react';
import stores from "stores";
import "./loaders.css"

const GlobalLoader = observer(() => {
    const { navigation } = stores;
    if(navigation.loading === false) {
        return null
    }

    return (
        <div className='global-loader'>
            <LinearProgress />
        </div>
    )
})

export default React.memo(GlobalLoader);
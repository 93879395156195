import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import {ChevronRight} from '@mui/icons-material';
import {  GRID_TREE_DATA_GROUPING_FIELD } from '@mui/x-data-grid-premium';
import { useHistory } from 'react-router-dom';
import { numberWithCommas } from "utils/numberManipulators"
import { BENCHMARKS_OVERVIEW } from "routes/privateRoutes";

/* eslint-disable react/prop-types */
const Actions = (props) => {
    const history = useHistory();

    return (
        <Box display={"flex"} alignItems={"baseline"} justifyContent={"center"}>
            <Tooltip title={"Go to details"}>
                <IconButton size='small' onClick={() => history.push(`${BENCHMARKS_OVERVIEW}/${props.row.AssetClass_ShortName}`)}>
                    <ChevronRight fontSize='small' style={{ color: "#BEBEBE" }} />
                </IconButton>            
            </Tooltip>
        </Box>

        // <RowActions data={props.row} apiRef={props.api} onClick={handleClickAsset} />
    )
} 

const columns = [
    { field: 'ParentAC', headerName: 'Group', flex: 1, minWidth: 120, },
    { field: GRID_TREE_DATA_GROUPING_FIELD, width: 300 },
    { field: 'AssetClass_LongName', headerName: 'Logn name', flex: 1, minWidth: 260 },
    { field: 'BenchMark_Name', headerName: 'Benchmark', flex: 1, minWidth: 440 },
    { field: 'BenchMark_Currency', headerName: 'Currency', flex: 1, minWidth: 100 },
    { field: 'Mean', headerName: 'Mean', flex: 1, minWidth: 120, renderCell: ({ value }) => numberWithCommas(value) },
    { field: 'Standard_Deviation', headerName: 'Std. deviation', flex: 1, minWidth: 120, renderCell: ({ value }) => numberWithCommas(value) },
    { field: 'StartDate', headerName: 'From', flex: 1, minWidth: 120 },
    { field: 'EndDate', headerName: 'To', flex: 1, minWidth: 120, },
    { field: "actions", headerName: '', width: 60, hideable: false, renderCell: Actions },
];

export default columns;
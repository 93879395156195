/* eslint-disable react/display-name */
import { lazy } from 'react';
import appRouteConstructor from './appRouteConstructor';
import { BENCHMARKS_OVERVIEW as benchmarksOverview, BENCHMARK_DETAILS as benchmarkDetails } from "Pages/Assets/routes";

export const CUSTOMER = '/customer';
export const CUSTOMER_PROFILE_ROOT = '/customer-profile';
export const CUSTOMER_PROFILE = '/customer-profile/:customerId';
export const PORTFOLIO = `/customer/:customerId/portfolio/:portfolioId`
export const RISK_LIMITS_OVERVIEW = '/risk-limits-overview';
export const ASSET_OVERVIEW = '/asset/:assetId';
export const DASHBOARD = '/dashboard';
export const ONBOARDING = '/onboarding';
// export const STATISTICS = '/statistics';
export const TRADE_MANAGEMENT = '/trade-management';
// export const ALLOCATION = '/data-tools/allocations-overview/allocation/:allocationId';
export const ESG_DEFINITION = '/esg-definition';
export const TEMPLATE_FILES = "/template-files";
export const SETTINGS = '/settings';
export const ORGANISATION_SETTINGS = '/organisation-settings';
export const KITCHEN_SINK = '/kitchen-sink'
export const PRICINGS = '/pricings'
export const SERVICES = '/services'
export const REPORT_EDITOR = '/report-editor'
export const REPORT_EDITOR_REPORT_TYPE = `${REPORT_EDITOR}/:reportType?/:customerId?/:portfolioId?/:reportId?`

export const LOGIN_PAGE_PATH = '/login';
export const ASSETS_OVERVIEW = '/assets-overview';
export const BENCHMARKS_OVERVIEW = `${ASSETS_OVERVIEW}${benchmarksOverview}`;
export const BENCHMARK_DETAILS = `${ASSETS_OVERVIEW}${benchmarkDetails}`;
export const ORGANISATION_INDEX = '/organisation';

export const CUSTOMERS_OVERVIEW = '/customers';
export const PORTFOLIOS_OVERVIEW = '/portfolios';
export const CUSTOMER_STATISTICS = '/customer/:customerId/statistics';
export const TRADING_PORTFOLIO = '/trading-portfolio';
export const INITIAL_ONBOARDING = '/initial-onboarding/:userId';
// export const REPORT_PDF_EDITOR = '/report-pdf-editor/:id/customer/:customerId/portfolio/:portfolioId';



const ESGDefinitionPage = lazy(() => import('Pages/ESGDefinition/ESGDefinition'));
const Settings = lazy(() => import('Pages/Settings/Settings'));
const OrganisationSettings = lazy(() => import('Pages/OrganisationSettings/OrganisationSettings'));
const Dashboard = lazy(() => import('Pages/Dashboard/index'));
const Onboarding = lazy(() => import('Pages/Onboarding/Onboarding'));
// const Statistics = lazy(() => import('Pages/Statistics/Statistics'));

const Portfolio = lazy(() => import('Pages/Portfolio'));
// const CustomersOverview = lazy(() => import('Pages/Organisation/CustomersOverview'));
const CustomerProfile = lazy(() => import('Pages/Customer/Overview'));
const AssetsOverview = lazy(() => import('Pages/Assets'));
const SingleAsset = lazy(() => import('Pages/Asset'));
const KitchenSink = lazy(() => import('Pages/KitchenSink/KitchenSink'));
const TemplateFiles = lazy(() => import('Pages/TemplateFiles/TemplateFiles'));
// const Reports = lazy(() => import('Pages/Reports/Reports'));
const OrganisationIndex = lazy(() => import('Pages/Organisation'));
const PricingsIndex = lazy(() => import('Pages/Pricings'));
const PortfoliosOverview = lazy(() => import('Pages/Organisation/PortfoliosOverview'));
const CustomersOverview = lazy(() => import('Pages/Organisation/CustomersOverview'));
const Services = lazy(() => import('Pages/Services'));
const TradingPortfolio = lazy(() => import('Pages/TradingPortfolio'));
const InitialOnboarding = lazy(() => import('Pages/InitialOnboarding'));
const TradeManagement = lazy(() => import('Pages/TradeManagement/TradeManagement'));

export const ALLOCATIONS_OVERVIEW = '/allocations-overview';
export const ALLOCATION = '/allocations-overview/allocation/:allocationId';
export const SWAP_CURVES_OVERVIEW = '/swap-curves-overview';

const Allocations = lazy(() => import('Pages/Allocation/Overview'));
const Allocation = lazy(() => import('Pages/Allocation/Allocation'));
const SwapCurves = lazy(() => import('Pages/SwapCurves'));

const ReportEditor = lazy(() => import('Pages/ReportEditor'));

const PRIVATE_ROUTES = [
    appRouteConstructor('Customer', CUSTOMER_PROFILE, CustomerProfile),
    appRouteConstructor('Initial onboarding', INITIAL_ONBOARDING, InitialOnboarding),
    appRouteConstructor('PortfolioIndex', PORTFOLIO, Portfolio),
    appRouteConstructor('AssetsOverview', ASSETS_OVERVIEW, AssetsOverview),
    appRouteConstructor('Asset', ASSET_OVERVIEW, SingleAsset),
    appRouteConstructor('Allocation', ALLOCATION, Allocation),
    appRouteConstructor('SwapCurves', SWAP_CURVES_OVERVIEW, SwapCurves),
    appRouteConstructor('Allocations', ALLOCATIONS_OVERVIEW, Allocations, true),
    appRouteConstructor('Dashboard', DASHBOARD, Dashboard),
    appRouteConstructor('TradeManagement', TRADE_MANAGEMENT, TradeManagement),
    appRouteConstructor('Onboarding', ONBOARDING, Onboarding),
    appRouteConstructor('Settings', SETTINGS, Settings),
    appRouteConstructor('OrganisationSettings', ORGANISATION_SETTINGS, OrganisationSettings),
    appRouteConstructor('Kitchen sink', KITCHEN_SINK, KitchenSink),
    appRouteConstructor('ESGDefinition', ESG_DEFINITION, ESGDefinitionPage),
    appRouteConstructor('Template files', TEMPLATE_FILES, TemplateFiles),
    appRouteConstructor('Report editor', REPORT_EDITOR_REPORT_TYPE, ReportEditor),
    appRouteConstructor('Organisation', ORGANISATION_INDEX, OrganisationIndex),
    appRouteConstructor('Pricings', PRICINGS, PricingsIndex),
    appRouteConstructor('CustomersOverview', CUSTOMERS_OVERVIEW, CustomersOverview),
    appRouteConstructor('PortfoliosOverview', PORTFOLIOS_OVERVIEW, PortfoliosOverview),
    appRouteConstructor('Services', SERVICES, Services),
    appRouteConstructor('Trading portfolio', TRADING_PORTFOLIO, TradingPortfolio),
    
];

export default PRIVATE_ROUTES;

import call from 'api/call';
import { APIS } from 'api/constants';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { arrayListsToArrayObjects, externalApiCallErrorHandler } from "utils/apiDataHelpers";
import { formattedDateToString, dateToInt, stringToInt } from "utils/dateFormatters";
import { v4 as uuidv4 } from 'uuid';
import snackbar from "./snackbarStore";
import assetStore from "./assetStore";
import proxyAssetStore from "./proxyAssetStore";
import navigation from "./navigationStore";
import { getAssetInfo } from "utils/urlHelpers";
import { numberWithCommas } from "utils/numberManipulators";
import {mobXStoreHelper} from "utils/mobXStoreHelper"
import shortid from 'shortid';

class PricesAssetsStore {
    assetPrices = [];
    manualPricesToggle = false;

    constructor() {
        makeObservable(this, {
            assetPrices: observable,
            manualPricesToggle: observable,
            fetchPrices: action.bound,
            addPriceToAsset: action.bound,
            deleteAssetPrice: action.bound,
            updateAssetPrice: action.bound,
            handleSwitchManualPricesToggle: action.bound,
            fetchConstructedAssetprices: action.bound,
        });
    }

    handleSwitchManualPricesToggle(val) {
        this.manualPricesToggle = val;
    }

    addPriceToAsset(id) {
        const row = {
            id: shortid.generate(),
            Price: '',
            Date: new Date(),
            ManuallyAdded: "YES"
        }

        this.assetPrices = [row, ...this.assetPrices];
    }

    async fetchConstructedAssetprices(assetUrl) {
        proxyAssetStore.constructAssetPrices(assetStore.selectedAsset, null, 1, () => {
            this.fetchPrices(assetUrl, true)
        })
    }

    async deleteAssetPrice(id, assetUrl) {
        navigation.setLoader(true);
        const { AssetID, CurrencyCode } = getAssetInfo(assetUrl);

        try {
            const newList = this.assetPrices.filter((el) => (el.id !== id && el.ManuallyAdded.toUpperCase() === "YES"))
            const params = { AssetID, CurrencyCode }
            let response

            if(newList.length > 0) {
                params.TradingDate = newList.map((el) => stringToInt(el.Date));
                params.Price = newList.map((el) => parseFloat(el.Price || 0));
                response = await call(APIS.UPDATE_ASSET_PRICES, params);
            } else {
                response = await call(APIS.DELETE_ASSET_PRICES, params);
            }
        
            
            if(response.success) {
                runInAction(() => {
                    this.assetPrices  = newList;
                    proxyAssetStore.constructAssetPrices(assetStore.selectedAsset, proxyAssetStore.newProxyKey, 1)
                })
            }
        } catch(error) {
            console.error(error);
            snackbar.handleOpen(error, "error")
        }

        navigation.setLoader(false);
    }




    async bulkUpdateAssetPrice(assetUrl, newPrices) {
        const { AssetID, CurrencyCode } = getAssetInfo(assetUrl);

        const TradingDate = [];
        const Price = [];

        newPrices.forEach((el) => {
            Price.push(el.Price)
            TradingDate.push(el.Date)
        })

        // const TradingDate = rows.forEach(() => )
        const params = {
            AssetID,
            CurrencyCode,
            TradingDate,
            Price,
        }

         mobXStoreHelper(APIS.UPDATE_ASSET_PRICES, params, (response) => {
             snackbar.handleOpen("Prices was added successfully", "success")
             if(newPrices.length >= 2) {
                 proxyAssetStore.constructAssetPrices(assetStore.selectedAsset)
             }
             this.fetchPrices(assetUrl)
        })
    }




    async updateAssetPrice(assetUrl, row, successCb) {
        navigation.setLoader(true);
        const { AssetID, CurrencyCode } = getAssetInfo(assetUrl);

        try {
            const existingItem = this.assetPrices.find((el) => el.id === row.id)

            if(existingItem && existingItem.id) {
                // is existing row
                const updatedRows = this.assetPrices.map(p => p.id === row.id ? { ...row } : p).filter((el) => el.ManuallyAdded.toUpperCase() === "YES")
                this.assetPrices = updatedRows;
            } else {
                // is new row
                this.assetPrices = [{ ...row, isNew: false }, ...this.assetPrices].filter((el) => el.ManuallyAdded.toUpperCase() === "YES")
            }

            const params = {
                AssetID,
                CurrencyCode,
                TradingDate: this.assetPrices.map((el) => dateToInt(el.Date)),
                Price: this.assetPrices.map((el) => parseFloat(el.Price || 0)),
            }

            const response = await call(APIS.UPDATE_ASSET_PRICES, params);
            if(response.success) {
                snackbar.handleOpen("Price was saved", "success")
                if(successCb) {
                    successCb()
                }
                // proxyAssetStore.constructAssetPrices(assetStore.selectedAsset)
            } else {
                snackbar.handleOpen("Unable to update asset prices", "error")
            }
        } catch(error) {
            runInAction(() => {
                console.error(error);
                snackbar.handleOpen("Unable to update asset prices", "error")
            })
        }

        navigation.setLoader(false);
    }

    async fetchPrices(assetUrl) {
        this.assetPrices = [];
        navigation.setLoader(true);
        const { AssetID, CurrencyCode } = getAssetInfo(assetUrl);

        try {
            const response = await call(APIS.GET_ASSET_PRICES, { AssetID, CurrencyCode, OnlyDefinedPrices: "No" });
            if (response.success) {

                runInAction(() => {
                   const payload = {
                       InputSource: response.message.InputSource,
                       Price: response.message.Price,
                       SettlementDate: response.message.SettlementDate,
                       TradingDate: response.message.TradingDate
                   }
                   const formattedPayload = arrayListsToArrayObjects(payload)

                    const sorted = formattedPayload.sort((a,b) => b.TradingDate - a.TradingDate)
                    this.assetPrices = sorted.map((el, i) => {
                        return {
                            id: uuidv4(),
                            Price: el.Price.toFixed(2),
                            Date: formattedDateToString(el.TradingDate),
                            ManuallyAdded: el.InputSource === 1 ? "YES" : "NO"
                        }
                    } )
                })

            } else {
                externalApiCallErrorHandler(response.meta.data.ErrorCode, () => {
                    snackbar.handleOpen("Unable to fetch asset prices, contact support", "error")
                })
            }
        } catch(error) {
            console.error(error);
            snackbar.handleOpen(error, "error")
        }

        navigation.setLoader(false);
    }
}

const pricesAssetsStore = (window.pricesAssetsStore = new PricesAssetsStore());

export default pricesAssetsStore;

import { APIS } from 'api/constants';
import { action, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { mobXStoreHelper } from 'utils/mobXStoreHelper';
import { formattedDateToString } from "utils/dateFormatters";
import { getAssetInfo } from "utils/urlHelpers";
import snackbar from "./snackbarStore";

class RealTimeAssetStore {
    assets = [];
    date = null;
    time = null;

    constructor() {
        makeObservable(this, {
            assets: observable,
            date: observable,
            time: observable,
            fetchRealTimeAssets: action.bound,
            addRealTimeAssets: action.bound,
            addRealTimeAssets: action.bound,
        });
    }

    async fetchRealTimeAssets() {
        mobXStoreHelper(APIS.GET_REAL_TIME_DATA, {}, (response) => {
            if(response.message.RealTime_Time && response.message.RealTime_Time.length === 3) {
                this.time = `${response.message.RealTime_Time[0]}:${response.message.RealTime_Time[1]}:${response.message.RealTime_Time[2]}`;
            }
            if(response.message.RealTime_Date) {
                this.date = formattedDateToString(response.message.RealTime_Date);
            }
            if(response.message.RealTime_Data && response.message.RealTime_Data.length) {
                this.assets = response.message.RealTime_Data.map((el) => ({ id: uuidv4(), ...el }));
            } 
        })
    }

    async addRealTimeAssets(rows, cb) {

        const params = {
            "AssetID":[],
            "CleanPrice":[],
            "CurrencyCode":[],
            "FXRate":[]
        }

        rows.forEach((el) => {

            if(el.AssetID) {params.AssetID.push(el.AssetID)}
            if(el.CleanPrice) {params.CleanPrice.push(Number(el.CleanPrice))}
            if(el.CurrencyCode) {params.CurrencyCode.push(el.CurrencyCode)}
            if(el.FXRate) {params.FXRate.push(el.FXRate)}
            
        })

        mobXStoreHelper(APIS.ADD_REAL_TIME_ASSET_DATA, params, (response) => {
            if(response.message.RealTime_Time && response.message.RealTime_Time.length === 3) {
                this.time = `${response.message.RealTime_Time[0]}:${response.message.RealTime_Time[1]}:${response.message.RealTime_Time[2]}`;
            }
            if(response.message.RealTime_Date) {
                this.date = formattedDateToString(response.message.RealTime_Date);
            }
            if(response.message.RealTime_Data && response.message.RealTime_Data.length) {
                this.assets = response.message.RealTime_Data.map((el) => ({ id: uuidv4(), ...el }));
            }

            snackbar.handleOpen("Prices was added successfully", "success")

            if(cb) {
                cb();
            }
        })
    }

    async deleteRealTimeAssets() {
        mobXStoreHelper(APIS.DELETE_REAL_TIME_DATA, {}, (response) => {
            if(response.message.RealTime_Time && response.message.RealTime_Time.length === 3) {
                this.time = `${response.message.RealTime_Time[0]}:${response.message.RealTime_Time[1]}:${response.message.RealTime_Time[2]}`;
            }
            if(response.message.RealTime_Date) {
                this.date = formattedDateToString(response.message.RealTime_Date);
            }
            this.assets = [];
        })
    }
}

const realTimeAssetsStore = (window.realTimeAssetsStore = new RealTimeAssetStore());

export default realTimeAssetsStore;

import { SMALL_PAPER, LARGE_LG, LARGE_PAPER, SMALL_LG, WIDGET_TYPES } from './constants';

export const getWidgetIds = (widgets) => Object.values(widgets);

export const getCategorizedWidgets = (widgetsIds) => widgetsIds.reduce((acc, widget) => {
    if (!acc[widget.category]) {
      acc[widget.category] = [];
    }
    acc[widget.category].push(widget);
    return acc;
}, {});

export const getUniqueWidgetIds = (layout) => {
  const uniqueIds = new Set();

  const addUniqueIds = (items) => {
    items.forEach(item => uniqueIds.add(item.i));
  };

  addUniqueIds(layout.lg);
  addUniqueIds(layout.paper);

  return Array.from(uniqueIds);
}

export const constructDynamicWidgetSize = (CalcName, Category) => {
  if(CalcName === "Normal VaR") {
    return {
      paper: { w: LARGE_PAPER, h: 2 },
      lg: { w: LARGE_LG, h: 3 },
    }
  }
  return {
    paper: { w: SMALL_PAPER, h: 2 },
    lg: { w: SMALL_LG, h: 2 },
  };

  // switch (Category) {
  //   case 'comparison':
  //     return {
  //       paper: { w: 2, h: 2 },
  //       lg: { w: 2, h: 2 },
  //     };
  //   default:
  //     return {
  //       paper: { w: 1, h: 2 },
  //       lg: { w: 2, h: 2 },
  //     };

}

export const constructNewWidgetItem = (widget, breakpoint) => {
  const newWidgetModified = {
      i: widget.id,
      static: false,
      x : 0, // (widgetLayout.layout[breakpoint].length - 1 % 3 === 0 ? 0 : 6),
      y : Infinity,
      ...widget.sizes[breakpoint]
  }

  return newWidgetModified;
}

export const getDataTypeFromCategory = (category) => {
  switch (category) {
    case 'WEIGHT':
      return WIDGET_TYPES.barChart;
    case 'HIST':
      return WIDGET_TYPES.areaChart;
    case 'AUM_NAV':
      return WIDGET_TYPES.areaChart;
    default:
      return null;
  }
}

export const getAUMRelatedWidgets = (widgetsCopy, CalcName, Category) => {
  const foundWidget = widgetsCopy[CalcName]
  
  if(!foundWidget) {
    widgetsCopy = {
      ...widgetsCopy,
      [CalcName]: {}
    }

    widgetsCopy["AUM"] = {
      id: "AUM",
      headline: "AUM",
      category: Category,
      dataType: WIDGET_TYPES.areaChart,
      independentFetchingURL: 'iRisk_GetCalculationResultHist',
      paths: {
        chart: 'HistData',
        investAmount: 'InvestAmount',
      },
      sizes: {
        paper: { w: LARGE_PAPER, h: 2 },
        lg: { w: LARGE_LG, h: 2 },
      }
    }

    widgetsCopy["TableBenchMark"] = {
      id: "TableBenchMark",
      headline: "Benchmark return table",
      category: "HistoricalTable",
      dataType: WIDGET_TYPES.table,
      independentFetchingURL: 'iRisk_GetCalculationResultHist',
      paths: {
        rows: 'rows',
        columnVisibilityModel: 'columnVisibilityModel',
      },
      sizes: {
        paper: { w: LARGE_PAPER, h: 2 },
        lg: { w: LARGE_LG, h: 2 },
      }
    }
  }
  return widgetsCopy;
}
import { action, makeObservable, observable, toJS } from "mobx";
// import PDFEditorSettingsStore from "../PDFEditorSettings";
import { blankPage, defaultPDFReport } from "./constants";
import shortid from "shortid";
import { initialPortfolioWidgets } from "../portfolioWidgets/constants";
import pdfEditorSettingsStore from "../PDFEditorSettings/PDFEditorSettingsStore";
import portfolioWidgetsStore from "../portfolioWidgets/portfolioWidgetsStore";
// import { ROW_HEIGHT } from "../../Table/constants";
// import { setPageDataHeight } from "./utils";

class PDFEditorStore {
    report = defaultPDFReport;
    // hasRunTasks = false;

    constructor() {
        makeObservable(this, {
            report: observable,
            addPage: action,
            initialize: action,
            updateLayout: action,
            addWidgetToLayout: action,
            // checkAndAdjustLayout: action,
        })
    }

    initialize() {
        // fetch from backend
        this.report = defaultPDFReport;
        pdfEditorSettingsStore.initialize(this.report.settings);

        if(this.report.pagesList.length === 0) {
            this.addPage();
        }
        // this.selectedPageId = this.report.pagesList[0];
    }

    updatePageHeadline(pageId, newHeadline) {
        this.report.pagesContent[pageId].headline = newHeadline;
    }

    updateLayout(pageId, newLayout) {
        this.report.pagesContent[pageId].widgets = newLayout
    }

    // setSelectedPageId(pageId) {
    //     this.selectedPageId = pageId;
    // }

    addWidgetToLayout(widget, pageId) {
        const widgetJS = toJS(widget)
        const widgetItem = { i: widgetJS.id, x: 0, y: Infinity, static: false, ...widgetJS.sizes.paper };

        this.report.pagesContent[pageId].widgets.push(widgetItem)
    }

    addPage(prevPageIndex = null, page = null) {
        const pageId = shortid.generate()
        if(prevPageIndex !== null) {
            // add page to the right index in the pagesList'
            this.report.pagesList.splice(prevPageIndex + 1, 0, pageId);
        } else {
            this.report.pagesList.push(pageId);
        }
        const pageToAdd = page === null ? blankPage : page;
        this.report.pagesContent[pageId] = { ...pageToAdd, id: pageId };
        return pageId;
    }

    createHoldingPages(pageIndex, rowsForCurrentPage, holdingData){
        
        const programmedHoldingID = `holding_${pageIndex}`;
        // const rowsForCurrentPage = rowsStateCopy.slice(0, numOfRowsWithinPage);
        // rowsStateCopy = rowsStateCopy.slice(numOfRowsWithinPage);
    
        // Create a new page
        const newHoldingPage = {
            id: shortid.generate(),
            name: 'Holding',
            headline: 'Holding',
            displayHeaderBanner: true,
            displayFooter: true,
            widgets: []
          }
        const currentPageId = this.addPage(null, newHoldingPage);
        const currentPage = this.report.pagesContent[currentPageId];
    
        // Add widget to that page
        currentPage.widgets.push({
            i: programmedHoldingID,
            x: 0,
            y: 0,
            w: 3,
            h: Infinity,
            static: false
        });
    
        // Create a new system generated widget
        portfolioWidgetsStore.injectWidgetData(programmedHoldingID, {
            ...holdingData,
            rows: rowsForCurrentPage,
        });
    
        portfolioWidgetsStore.widgets[programmedHoldingID] = {
            ...initialPortfolioWidgets.holding,
            id: programmedHoldingID,
        };
    }
}

const store = (window.pdfEditorStore = new PDFEditorStore());

export default store
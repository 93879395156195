/* eslint-disable react/prop-types */
import * as React from "react";
import { styled, Box, List  } from "@mui/material";
import { observer } from "mobx-react";
import { createContext } from "react";
import { useHistory } from "react-router-dom";
import BackgroundImage from "./new-york-80s.png"
import RebusFinanceInitials from "./REBUSFINANCELOGOINITIALS.jsx"
import stores from "stores";
import "./drawer.css";
import menuItems from "./menuItems";

const StyledMain = styled(Box, {
    shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    marginLeft: "56px",
}));

export const MenuContext = createContext(null);

export const buildMenuSelectItem = (value, list, urlRoot, handleSelect) => {
    return {
        type: "select",
        value: value,
        list: list,
        urlRoot: urlRoot,
        handleSelect: handleSelect
    }
}

const DrawerListItem = ({ Icon, label, onClick, selected }) => {
    return (
        <div onClick={onClick} className={`drawer-menu-list-item ${selected ? "drawer-menu-list-item-focus" : ""}`} >
            <Icon fontSize="1.25rem" />
            <span className="drawer-menu-list-item-label">{label}</span>
        </div>
    )
}

const Drawer = observer((props) => {
    const { navigation } = stores;
    const history = useHistory();

    const handleNavigate = (link) => {
        navigation.setDrawerSelectedElement(link);
        history.push(link);
    }

    return (
        <Box display={"flex"} height={"100vh"}>
            <div className="drawer">
                <div className="drawer-logo-container">
                    <RebusFinanceInitials />
                </div>
                <div className="drawer-header">
                    <div className="drawer-header-overlay" />
                    <div className="image-container">
                        <img className="drawer-bg-image" src={BackgroundImage} alt="Rebus Finance" />
                    </div>   
                </div>
                <div className="drawer-menu-container">
                    <List className="drawer-menu-list">
                        {menuItems.map(({ hasBreak, label, Icon, link }, index) => (
                            <div key={`link-${index}`}>
                                <DrawerListItem
                                    
                                    id={link}
                                    onClick={() => handleNavigate(link)}
                                    selected={navigation.drawerSelectedElement === link}
                                    Icon={Icon}
                                    label={label}
                                />
                                {hasBreak && <hr className="drawer-menu-divider" />}
                            </div>
                        ))}
                    </List>
                </div>
            </div>
            <StyledMain className="main">{props.children}</StyledMain>
        </Box>
    );
});

export default Drawer; 
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { CircularProgress, Box } from '@mui/material';
import { Route } from 'react-router-dom';
import Drawer from 'components/Drawer/Drawer';

const Loader = () => {
    const styles = {
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        display: 'flex'
    };

    return (
        <Box sx={styles}>
            <CircularProgress />
        </Box>
    )
}

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={() => {
                return (
                    <Suspense fallback={<Loader />}>
                        <Drawer>
                            <Component />
                        </Drawer>
                    </Suspense>
                );
            }}
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default PrivateRoute;

import { APIS } from 'api/constants';
import { action, makeObservable, observable } from 'mobx';
import { mobXStoreHelper } from "utils/mobXStoreHelper"
import cashFlowStore from  "stores/CashFlow";
import { getAssetInfo } from "utils/urlHelpers";
import { v4 as uuidv4 } from 'uuid';
import { formattedDateToString, goYearsBack } from "utils/dateFormatters"

class PrivateEquityAssetStore {
    privateEquityAssetTableData = [];
    privateEquityCFChartData = [];
    privateEquityUnpackedCFTableData = [];
    privateEquityUnpackedCFChartData = [];
    privateEquityLastDataDate = null;
    showNoCashflowAlert = false;

    constructor() {
        makeObservable(this, {
            privateEquityAssetTableData: observable,
            privateEquityCFChartData: observable,
            privateEquityUnpackedCFTableData: observable,
            privateEquityUnpackedCFChartData: observable,
            updatePrivateEquity: action.bound,
            getPrivateEquityAssetData: action.bound,
            privateEquityLastDataDate: observable,
            showNoCashflowAlert: observable,
        });
    }

    async updatePrivateEquity(assetUrl) {
        const { AssetID, CurrencyCode } = getAssetInfo(assetUrl);

        mobXStoreHelper(APIS.UPDATE_PRIVATE_EQUITY, { AssetID, CurrencyCode }, () => {
            this.privateEquityAssetTableData = [];
            this.privateEquityCFChartData = [];
        })
    }

    async getPrivateEquityAssetData(assetUrl, PercentageOwned, OutstandingAmount, DiscountRateCapitalCalls) {
        const { AssetID, CurrencyCode } = getAssetInfo(assetUrl);
    
        cashFlowStore.fetchCashFlow(assetUrl, (cashFlow) => {
            const datesArray = []
            cashFlow.forEach((cf) => {
                if(cf.FirstDate) {
                    datesArray.push(cf.FirstDate)
                }
                if(cf.LastDate) {
                    datesArray.push(cf.LastDate)
                }

            });

            const sorted = datesArray.sort((a,b) => b - a)
            if(sorted.length) {
                const latestCFDate = sorted[0]
                this.privateEquityLastDataDate = latestCFDate;

                const params = {
                    AssetID,
                    AssetCurrencyCode: CurrencyCode,
                    CalculationDate: latestCFDate,
                    PercentageOwned: Number(PercentageOwned),
                    OutstandingAmount: Number(OutstandingAmount),
                    DiscountRateCapitalCalls: Number(DiscountRateCapitalCalls),
                    StartDate: goYearsBack(latestCFDate, 1)
                }

                mobXStoreHelper(APIS.GET_PRIVATE_EQUITY_DATA, params, (response) => {
                    this.privateEquityAssetTableData = response.meta.data.PECF.map((el) => ({id: uuidv4(), ...el }));
                    this.privateEquityUnpackedCFTableData = response.meta.data.UnpackedCF.map((el) => ({id: uuidv4(), ...el }));
        
                    this.privateEquityCFChartData = response.meta.data.PECF.map((el) => {
                        return {
                            key: formattedDateToString(el.Date),
                            "label": formattedDateToString(el.Date),
                            "Net cash flow": el.NetCF,
                            "IRR":el.IRR.toFixed(2),
                            "DPI":el.DPI.toFixed(2),
                            "RVPI":el.RVPI.toFixed(2),
                        }
                    });
                })
            } else {
                this.showNoCashflowAlert = true;
            }
        })
    }
}

const privateEquityAssetStore = (window.privateEquityAssetStore = new PrivateEquityAssetStore());

export default privateEquityAssetStore;

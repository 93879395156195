/* eslint-disable no-unused-vars */
// import call from 'api/call';
// import { APIS, METHODS } from 'api/constants';
import { action, makeObservable, observable } from 'mobx';

class NavigationStore {
  loading = false;
  drawerSelectedElement = null;
  sidebar = {
    visible: false
  }

  constructor() {
        makeObservable(this, {
            loading: observable,
            setLoader: action.bound,
            drawerSelectedElement: observable,
            setDrawerSelectedElement: action.bound,
        });
    }

    setLoader(value) {
        this.loading = value;
    }

    setDrawerSelectedElement(value) {
        this.drawerSelectedElement = value;
    }
}

// this makes it possible to access it via console
const navigationStore = (window.initialisationStore =
  new NavigationStore());

export default navigationStore;

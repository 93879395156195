
import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, CircularProgress, Stack  } from '@mui/material';
import supabase from 'utils/supabase';
import PageFormContainer from 'Layout/PageFormContainer';
import UpdatePasswordForm from 'Forms/UpdatePassword';



const UpdatePassword = () => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token_hash = urlParams.get('token_hash')
    const type = urlParams.get('type')

    const [loading, setLoading] = useState(true)
    const [session, setSession] = useState(null);

    useEffect(() => {
        supabase.auth.verifyOtp({ token_hash, type }).then(({ data: { session } }) => {
            setSession(session);
            setLoading(false);
          });
          const {
            data: { subscription },
          } = supabase.auth.onAuthStateChange((_event, session) => {
              setSession(session);
          });
          return () => subscription.unsubscribe();
    }, [token_hash, type]);

    
    if(loading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <CircularProgress />
            </Box>
        )

    }

    if(!session) {
        return <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Typography variant='h3'>You are not allowed to see this page. please reach out to pg@rebus-finance.com so we can resolve the issue</Typography>
        </Box>
    }

    return (
        <PageFormContainer fluid={'true'}>
            <div>
                <Typography variant="h1" gutterBottom>Rebus Finance</Typography>
            </div>
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={6} md={4}>
                    <Stack spacing={2}>
                        <Typography variant="h6" >Update password for: <b>{session.user.email}</b></Typography>
                        <UpdatePasswordForm />
                    </Stack>
                </Grid>
            </Grid>
        </PageFormContainer>
    )

}

export default UpdatePassword;

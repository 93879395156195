import { action, makeObservable, observable, toJS } from "mobx";
import { defaultDashboardReport } from "./constants";

class DashboardEditorStore {
    settings = {
        unit: "mio",
        startDate: '2024-01-03',
        endDate: '2024-03-01',
    };
    report = defaultDashboardReport;
    selectedPageId = null;

    constructor() {
        makeObservable(this, {
            selectedPageId: observable,
            report: observable,
            initialize: action,
            updateLayout: action,
            addWidgetToLayout: action,
            settings: observable,

        })
    }

    initialize() {
        // fetch from backend
        this.report = defaultDashboardReport;
    }

    updateLayout(newLayout) {
        this.report.widgets = newLayout
    }

    addWidgetToLayout(widget) {
        const widgetJS = toJS(widget)
        const widgetItem = { i: widgetJS.id, x: 0, y: Infinity, static: false, ...widgetJS.sizes.lg };
        this.report.widgets.push(widgetItem)
    }
}

const store = (window.dashboardEditor = new DashboardEditorStore());

export default store
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from "react-router-dom";
import supabase from "utils/supabase";
import { APIS } from 'api/constants';
import call from 'api/call';

const AuthProvider = observer(({ children }) => {

    const history = useHistory()

    useEffect(() => {
      supabase.auth.getSession().then(async ({ data: { session } }) => {
        if (!session || !localStorage.getItem("TOKEN")) {
          localStorage.clear();
          history.push("/login")
        }
      })

      const {
        data: { subscription },
      } = supabase.auth.onAuthStateChange((_event, session) => {

        if (_event === 'INITIAL_SESSION') {
          // handle initial session
        } else if (_event === "SIGNED_IN") {
          // handle sign in event
        } else if (_event === 'SIGNED_OUT') {
          // handle sign out event
        } else if (_event === 'PASSWORD_RECOVERY') {
          // handle password recovery event
        } else if (_event === 'TOKEN_REFRESHED') {
          // localStorage.setItem("TOKEN", JSON.stringify(session.access_token));
          // handle token refreshed event

          // extract jwt and store it in memory for further use in application.
          // Avoid frequent calls to supabase.auth.getSession() for the same purpose.
          // There is a background process that keeps track of when the session should be refreshed so you will always receive valid tokens by listening to this event.
          // The frequency of this event is related to the JWT expiry limit configured on your project.


        } else if (_event === 'USER_UPDATED') {
          // handle user updated event
        }

      })

      return () => subscription.unsubscribe()
    }, [history])

    return (
      <>{children}</>
    )
})

export default AuthProvider;

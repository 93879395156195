import { APIS } from 'api/constants';
import { action, makeObservable, observable, runInAction } from 'mobx';
import supabase from "utils/supabase";
import snackbar from "./snackbarStore";
import {mobXStoreHelper} from "utils/mobXStoreHelper"
// TODO: This could go somewhere else
import { IMAGE_TYPES, ALLOWED_IMAGE_EXT } from "stores/PDFEditorSettings/constants";
import { convertImageToBase64, getCroppedImg } from "utils/imageManipulators";
import snackbarStore from './snackbarStore';

class OrganisationSettingsStore {
    fetched = false;
    advisors = [];
    brand = {
        logo: "",
        name: "",
        backgroundImage: ""
    }
    organisationID = null;

    constructor() {
        makeObservable(this, {
            advisors: observable,
            getAdvisors: action.bound,
            addAdvisor: action.bound,
            deleteAdvisor: action.bound,
            changeAdvisorPassword: action.bound,
            initializeOrganisationSettings: action.bound,
            saveReportBackgroundImage: action.bound,
            saveOrganisationLogo: action.bound,
            organisationID: observable,
            brand: observable,
        });
    }

    async saveOrganisationLogo(imageFile) {
        if(!imageFile) {
            snackbarStore.handleOpen("No file selected", 'error')
            return
        }
        
        if(!this.organisationID) {
            snackbarStore.handleOpen("Organisation ID not found", 'error')
            return
        }

        const organisationid = this.organisationID;
        const that = this
        const reader = new FileReader();
        reader.onload = async function(e) {
            
            try {
                const imageAsArrayBuffer = e.target.result
                const blob = new Blob([imageAsArrayBuffer], { type: `image/${ALLOWED_IMAGE_EXT}` });
                const preview = URL.createObjectURL(blob);
                // Here, you can save the cropped image to your server or use it further
                const fileName = `${organisationid}/${IMAGE_TYPES.companyLogo}.${ALLOWED_IMAGE_EXT}`
                const file = new File([blob], fileName, { type: `image/${ALLOWED_IMAGE_EXT}` });
                
                runInAction(() => that.brand.logo = preview)

                // Upload cropped image to storage
                const { data, error } = await supabase
                    .storage
                    .from('Images')
                    .upload(fileName, file, {
                        cacheControl: '3600',
                        upsert: true
                    })
                
                if (error) {
                    snackbarStore.handleOpen(error.toString(), 'error')
                }

                snackbarStore.handleOpen('Image uploaded successfully', 'success')
            } catch (e) {
                snackbarStore.handleOpen(e.toString(), 'error')
            }
        }
            reader.readAsArrayBuffer(imageFile);

            
    
    
            

    }


    async saveReportBackgroundImage(image, sizes) {
        
        try {
            const croppedImageBlob = await getCroppedImg(image, sizes);
            const preview = URL.createObjectURL(croppedImageBlob);
            // Here, you can save the cropped image to your server or use it further
            convertImageToBase64(preview, (base64ImageURL) => {
                runInAction(() => this.brand.backgroundImage = base64ImageURL)
            });
            
            const imageType = 'header-banner'
            const fileName = `${this.organisationID}/${imageType}.${ALLOWED_IMAGE_EXT}`
            const file = new File([croppedImageBlob], fileName, { type: `image/${ALLOWED_IMAGE_EXT}` });
            
            // Upload cropped image to storage
            const { data, error } = await supabase.storage
                .from('Images')
                .upload(fileName, file, {
                    cacheControl: '3600',
                    upsert: true
                })

            if (error) {
                throw new Error(error.message)
            }
            snackbarStore.handleOpen('Image uploaded successfully', 'success')

            // setCropModalOpen(false)
        } catch (e) {
            snackbarStore.handleOpen(e.toString(), 'error')
        }
    }

    async getAdvisors() {
        mobXStoreHelper(APIS.GET_LIST_OF_ADVISORS, {}, (response) => {
            let advisors = [];

            response.meta.data.AdvisorList.forEach((advisor, index)=>{
                advisors.push({
                    id: advisor["AdvisorID"],
                    email: advisor["E_Mail"],
                    firstname: advisor["First_Name"],
                    lastname: advisor["Last_Name"],
                    omkpermission: advisor["OmkPermission"]
                })
            });
            
            this.advisors = advisors;
        })
    }

    async addAdvisor (advisor, callback) {
        const newAdvisor = {
            E_Mail: advisor.email,
            Password: advisor.password,
            First_Name: advisor.firstname,
            Last_Name: advisor.lastname
        };

        mobXStoreHelper(APIS.DEFINE_ADVISOR, newAdvisor, () => {
            snackbar.handleOpen("Advisor created.", "success")
            callback();
            this.getAdvisors();
        });
    }

    async deleteAdvisor (advisorId, callback) {
        const advisorDetails = {
            AdvisorID: advisorId
        };

        mobXStoreHelper(APIS.DELETE_ADVISOR, advisorDetails, () => {
            snackbar.handleOpen("Advisor deleted.", "success")
            callback();
            this.getAdvisors();
        });
    }

    async changeAdvisorPassword (email, password, callback) {
        const details = {
            E_Mail: email,
            Password: password
        };

        mobXStoreHelper(APIS.CHANGE_ADVISOR_PASSWORD, details, () => {
            snackbar.handleOpen("Advisor password updated.", "success")
            callback();
        });
    }

    async initializeOrganisationSettings(userId) {
        const employeeDataRes = await supabase
            .from('employees')
            .select('id, organisations ( id, name ), organisation_roles (id, name)')
            .eq('user_id', userId)
            .single();


            if(employeeDataRes.error) {
                snackbar.handleOpen(employeeDataRes.error.message, "error")
                return;
            }


        runInAction(() => {
            this.organisationID = employeeDataRes.data.organisations.id
            this.brand.name = employeeDataRes.data.organisations.name
        })
    
        
        await this.getBrand();
        // await this.getAdvisors();
        runInAction(() => this.fetched = true);
    }

    async getBrand() {
        const bannerImgResponse = await supabase.storage.from('Images').createSignedUrl(`${this.organisationID}/${IMAGE_TYPES.headerBanner}.${ALLOWED_IMAGE_EXT}`, 3600);
        if (!bannerImgResponse.error) {
            convertImageToBase64(bannerImgResponse.data.signedUrl, (base64ImageURL) => {
                runInAction(() => this.brand.backgroundImage = base64ImageURL)
            })
        }


        const logoResponse = await supabase.storage.from('Images').createSignedUrl(`${this.organisationID}/${IMAGE_TYPES.companyLogo}.${ALLOWED_IMAGE_EXT}`, 3600);

        if (!logoResponse.error) {            
            convertImageToBase64(logoResponse.data.signedUrl, (base64ImageURL) => {
                runInAction(() => this.brand.logo = base64ImageURL)
            })
        }
    }

    
}

const organisationSettingsStore = (window.organisationSettingsStore = new OrganisationSettingsStore());

export default organisationSettingsStore;

import { APIS } from 'api/constants';
import { action, makeObservable, observable } from 'mobx';
import {mobXStoreHelper} from "utils/mobXStoreHelper"
import snackbar from './snackbarStore';
import call from "api/call"
import { groupBy } from "utils/arrayHelpers"
import shortid from 'shortid';

const initialSelectedAllocation = {
        columns: [],
        AllocationName: "",
        Allocation: [],
        AllocationID: null,
        CustomerID: [],
        IsItASubAllocation: false,
        IsTherePortfolioLinked: false,
        PortfolioName: [],
        categoryNames: [],
    }
class AssetAllocationStore {
    loading = false;
    assetAllocations = {
        list: [],
        mapping: new Map([]),
    };
    // allocatedAssets = [];
    selectedAllocation = initialSelectedAllocation
    
    constructor() {
        makeObservable(this, {
            assetAllocations: observable,
            loading: observable,
            listAllocations: action.bound,
            get: action.bound,
            deleteAllocation: action.bound,
            updateAllocation: action.bound,
            addAllocation: action.bound,
            startLoading: action.bound,
            endLoading: action.bound,

            // allocatedAssets: observable,
            assignPortfolios: action.bound,
            removeCategory: action.bound,
            // findPortfolioAssets: action.bound,
            selectedAllocation: observable,
            addRowsToSheet: action.bound,
            handleChangeAllocationMatrix: action.bound,
            initializeSelectedAllocation: action.bound,
            setCategoryNames: action.bound,
            updateRowInSheet: action.bound,
        });
    }

    async initializeSelectedAllocation(CustomerID = null, portfolioName = null, cb) {
        console.log("initializeSelectedAllocation")
        this.selectedAllocation = initialSelectedAllocation
        if(CustomerID && portfolioName) {
            const params = { CustomerID: Number(CustomerID), PortfolioName: portfolioName }

            mobXStoreHelper(APIS.GET_PORTFOLIO_DATA, params, (response) => {
                // Give me a list of all the assets that are in the portfolios, but not in the allocation
                if(response.message.Portfolio && response.message.Portfolio.length > 0) {
                    
                    const assets = response.message.Portfolio[0].Assets.map((asset, index) => {
                        return {
                            id: `${asset.AssetID}-${asset.AssetCurrency}`,
                            AssetID: asset.AssetID,
                            AssetName: asset.AssetName,
                            AssetCurrencyCode: asset.AssetCurrency,
                            AssetType: asset.AssetType,
                            inPortfolioButNotAllocated: true
                        }
                    })
                    this.selectedAllocation.Allocation = assets
                }
    
    
                snackbar.handleOpen(`initialized a new allocation from ${portfolioName}`, "success");
            })

            if(cb) {
                cb()
            }
        }
    }

    startLoading() {
        this.loading = true
    }

    endLoading() {
        this.loading = false;
    }

    setCategoryNames(categoryNames, newValue) {
        this.selectedAllocation.categoryNames = categoryNames
        let newAssetAllocation = this.selectedAllocation.Allocation
        if(!newValue) {
            newAssetAllocation = newAssetAllocation.map((item) => {
                categoryNames.forEach((catName) => {
                    if(!item[catName]) {
                        item[catName] = 0
                    }
                })

                return item
            })
        } else {

            newAssetAllocation = newAssetAllocation.map((item) => {
                return { ...item, [newValue]: 0 }
            })
        }

        this.selectedAllocation.Allocation = newAssetAllocation
    }

    addRowsToSheet(newRows) {
        let rows = this.selectedAllocation.Allocation

        rows = [...rows, ...newRows]
        this.selectedAllocation.Allocation = rows
    }

    removeCategory(el) {
        const copyState = this.selectedAllocation.categoryNames
        const result = copyState.filter((item) => {
            return item !== el
        })

        this.selectedAllocation.categoryNames = result
    }

    updateRowInSheet(newRow) {
        const newAssetAllocation = this.selectedAllocation.Allocation.map((item) => {
            if(item.id === newRow.id) {
                return newRow
            }
            return item
        })

        this.selectedAllocation.Allocation = newAssetAllocation
    }

    // addColToSheet(catName) {
    //     const copyState = this.selectedAllocation.Allocation.map((row) => ({...row, [catName]: 0}))
    //     this.selectedAllocation.Allocation = copyState;
    // }

    async assignPortfolios(AllocationID, portfolios, cb) {
        // const stateCopy = this.selectedAllocation.Allocation
        
        const params = { AllocationID: Number(AllocationID), PortfolioName: portfolios };
        mobXStoreHelper(APIS.ASSIGN_ALLOCATION, params, () => {
            mobXStoreHelper(APIS.FIND_ALL_ASSETS, { PortfolioName: portfolios }, (response) => {

                // make an array of all existing assets
                const isins = this.selectedAllocation.Allocation.map((row) => row.AssetID)

                // Give me a list of all the assets that are in the portfolios, but not in the allocation
                let nonIncludedAssets = []
                if(response.message.AllAssts) {
                    nonIncludedAssets = response.message.AllAssts.filter((el) => {
                        return !isins.includes(el.AssetID); 
                    })
                    nonIncludedAssets.forEach((el, index) => {
                        const row = {
                            id: shortid.generate(),
                            AssetID: el.AssetID,
                            AssetName: el.AssetName,
                            AssetCurrencyCode: el.AssetCurrencyCode,
                            AssetType: el.AssetType,
                            inPortfolioButNotAllocated: true
                        }
    
                        this.selectedAllocation.categoryNames.forEach((catName) => {
                            row[catName] = 0
                        })
    
                        this.selectedAllocation.Allocation.push(row)
                    })
                }


                snackbar.handleOpen("Portfolios assigned successfully", "success");
                if(cb) {
                    cb()
                }
            })
        })
    }

    async listAllocations() {
        const params = {}
        
        mobXStoreHelper(APIS.LIST_ALLOCATIONS, params, (response) => {
        
            const iterations = response.message.Allocation.map((el, index) => ({ ...el, ...response.message.AllocationLink[index], id: el.AllocationID, AllocationName: el.AllocationName }));

            const filteredIterations = iterations.filter((el) => el.AllocationType !== "Generic")

                const map = new Map()
                filteredIterations.forEach((el) => map.set(el.id, el))

                this.assetAllocations = {
                    mapping: map,
                    list: filteredIterations
                }
        })
    }

    async get(allocationId, cb) {
        this.loading = true
        this.selectedAllocation = initialSelectedAllocation
        const params = {"AllocationID":[allocationId] }

        mobXStoreHelper(APIS.GET_ALLOCATION, params, async (response) => {
            const rows = []
            let idKeys = []
            const categoryNames = [...new Set(response.message.Allocation.map(item => item.AllocationType))];
            


            const withId = response.message.Allocation.map((el) => {
                const idKey = `${el.AssetID}-${el.AssetCurrencyCode}`
                if(!idKeys.includes(idKey)) {
                    idKeys.push(idKey)
                }
                return { ...el, idKey }
            })

            const grouped = groupBy(withId, item => item.idKey);

            idKeys.forEach((el) => {
                const groupElement = grouped.get(el)
                let newRow = {}
                groupElement.forEach((item, index) => {
                    if(index === 0) {
                        newRow = {
                            id: shortid.generate(),
                            AssetID: item.AssetID,
                            AssetName: item.AssetName,
                            AssetCurrencyCode: item.AssetCurrencyCode,
                            AssetType: item.AssetType,
                        }
                    }
                    newRow[item.AllocationType] = item.Weight
                })
                rows.push(newRow)
            })

            rows.forEach((row) => {
                categoryNames.forEach((catName) => {
                    if(!row[catName]) {
                        row[catName] = 0
                    }
                })
            })

            if(response.message.PortfolioName) {
                const assetsResponse = await call(APIS.FIND_ALL_ASSETS, { PortfolioName: response.message.PortfolioName })
                const isins = rows.map((row) => row.AssetID)

                // Give me a list of all the assets that are in the portfolios, but not in the allocation
                let rowsInPortfolioThatHasNotBeenAllocated = []
                if(assetsResponse.message.AllAssts) {
                    rowsInPortfolioThatHasNotBeenAllocated = assetsResponse.message.AllAssts
                    .filter((el) => !isins.includes(el.AssetID))
                    .map((el) => {
                        const row = {
                            id: shortid.generate(),
                            AssetID: el.AssetID,
                            AssetName: el.AssetName,
                            AssetCurrencyCode: el.AssetCurrencyCode,
                            AssetType: el.AssetType,
                            inPortfolioButNotAllocated: true
                        }
    
                        categoryNames.forEach((catName) => {
                            row[catName] = 0
                        })
    
                        return row;
                    })
                    rows.push(...rowsInPortfolioThatHasNotBeenAllocated)
                }

            }
        
                    this.selectedAllocation = {
                        Allocation: rows,
                        AllocationID: response.message.AllocationID,
                        AllocationName: response.message.AllocationName,
                        CustomerID: response.message.CustomerID,
                        IsItASubAllocation: response.message.IsItASubAllocation ? true : false,
                        IsTherePortfolioLinked: response.message.IsTherePortfolioLinked ? true : false,
                        PortfolioName: response.message.PortfolioName,
                        categoryNames,
                    }
        
                    if(cb) {
                        cb(response.message.PortfolioName)
                    }
                // })

                    this.loading = false
        }, (errorText) => {
            this.loading  = false;
            snackbar.handleOpen(errorText, "error")
        })
    }


    async deleteAllocation(id, cb) {
        const params = {"AllocationID":[id]}
        
        // APIS.ADD_ALLOCATION or APIS.DEFINE_ALLOCATION
        mobXStoreHelper(APIS.DELETE_ALLOCATION, params, (response) => {
            this.listAllocations()
            snackbar.handleOpen("Allocation has been deleted", "success");

            if(cb) {
                cb()
            }
            // this.assetAllocations = new Map([]);
        })
    }

    async addAllocation(payload, cb) {
        const params = {
            IsItASubAllocation: payload.isItASubAllocation ? 1 : 0,
            AllocationName: payload.AllocationName,
            AllocationType: [],
            AssetCurrencyCode: [],
            AssetID: [],
            Weight: []
        }



        this.selectedAllocation.Allocation.forEach((allocationRow) => {
            this.selectedAllocation.categoryNames.forEach((categoryName) => {
                params.AssetID.push(allocationRow.AssetID)
                params.AssetCurrencyCode.push(allocationRow.AssetCurrencyCode)
                params.AllocationType.push(categoryName)
                params.Weight.push(allocationRow[categoryName])
            })
        })

        mobXStoreHelper(APIS.ADD_ALLOCATION, params, (response) => {
            snackbar.handleOpen("Allocation created successfully", "success");
                if(cb) {
                    cb(response.message.AllocationID)
                }
        })
    }

    handleChangeAllocationMatrix(matrix) {
        this.selectedAllocation.Allocation = matrix
    }

    async updateAllocation(allocationId, allocationName, cb) {
        const params = {
            AllocationID: Number(allocationId),
            IsItASubAllocation: this.selectedAllocation.IsItASubAllocation ? 1 : 0,
            AllocationName: allocationName,
            AllocationType: [],
            AssetCurrencyCode: [],
            AssetID: [],
            Weight: []
        }

        this.selectedAllocation.Allocation.forEach((allocationRow) => {
            this.selectedAllocation.categoryNames.forEach((categoryName) => {
                params.AssetID.push(allocationRow.AssetID)
                params.AssetCurrencyCode.push(allocationRow.AssetCurrencyCode)
                params.AllocationType.push(categoryName)
                params.Weight.push(allocationRow[categoryName])
            })
        })

        // APIS.ADD_ALLOCATION or APIS.DEFINE_ALLOCATION
        mobXStoreHelper(APIS.OVERWRITE_ALLOCATION, params, (response) => {
            snackbar.handleOpen("Allocation has been updated", "success");
            if(cb) {
                cb()
            }
        })
    }
}

const assetAllocations = (window.assetAllocations = new AssetAllocationStore());
export default assetAllocations;

export const COLS = {
  lg: 6,
  paper: 3,
}

export const A4_HEIGHT_PX = 1123; // Height of A4 page in pixels at 96 DPI

export const SMALL_PAPER = 1
export const MEDIUM_PAPER = 1
export const LARGE_PAPER = COLS.paper

export const SMALL_LG = COLS.lg / 3
export const LARGE_LG = COLS.lg

export const ROWS = 12
export const BREAKPOINTS = {
  lg: 1000,
  paper: 100,
}
export const ROW_HEIGHT = 80
export const RGL_PADDING = 12
export const BREAKPOINT_NAMES = {
  lg: 'lg',
  paper: 'paper'
}
export const widgetCategories = {
    distribution: 'distribution',     // Is a pie chart by default
    comparison: 'comparison',         // is a bar chart by default
    AUM_NAV: 'AUM_NAV',               // is a line chart by default
    HIST: 'HIST',                     // is an area chart by default
    WEIGHT: 'WEIGHT',                 // is an area chart by default
    holding: 'holding',               // is a table by default
    stat: 'stat',                     // is a number by default
    HistoricalTable: "HistoricalTable"// is a table by default
}

export const WIDGET_TYPES = {
  pieChart: 'pieChart',
  barChart: 'barChart',
  lineChart: 'lineChart',
  areaChart: 'areaChart',
  table: 'table',
  holding: 'holding',
  number: 'number',
}
export const widgetCategoryList = [{
  key: widgetCategories.distribution,
  label: 'Distribution',
}, {
  key: widgetCategories.comparison,
  label: 'Comparison',
}, {
  key: widgetCategories.AUM_NAV,
  label: 'AUM & NAV',
}, {
  key: widgetCategories.HIST,
  label: 'Historical',
}, {
  key: widgetCategories.WEIGHT,
  label: 'Weights',
}, {
  key: widgetCategories.holding,
  label: 'Holding',
}, {
  key: widgetCategories.stat,
  label: 'Stat',
}]
export const initialPortfolioWidgets = {
    "holding": {
        id: "holding",
        headline: "Holding",
        category: widgetCategories.holding,
        dataType: WIDGET_TYPES.holding,
        paths: {
          rows: 'rows',
          portfolio: 'portfolio',
          columnVisibilityModel: 'columnVisibilityModel',
        },
        sizes: {
          paper: { w: LARGE_PAPER, h: 2 },
          lg: { w: LARGE_LG, h: 3 },
        }
    },
    "groups-distribution": {
        id: "groups-distribution",
        headline: "Groups distribution",
        dataType: WIDGET_TYPES.pieChart,
        category: widgetCategories.distribution,
        paths: {
          table: 'table',
          chart: 'chart',
        },
        sizes: {
          paper: { w: SMALL_PAPER, h: 3 },
          lg: { w: SMALL_LG, h: 2 },
        }
    },
    "risk-classification": {
        id: "risk-classification",
        headline: "Risk classification",
        dataType: WIDGET_TYPES.pieChart,
        category: widgetCategories.distribution,
        paths: {
          table: 'table',
          chart: 'chart',
        },
        sizes: {
          paper: { w: SMALL_PAPER, h: 2 },
          lg: { w: SMALL_LG, h: 2 },
        }

    },
    "liquidity-distribution": {
        id: "liquidity-distribution",
        headline: "Liquidity distribution",
        dataType: WIDGET_TYPES.pieChart,
        category: widgetCategories.distribution,
        paths: {
          chart: 'data',
        },
        sizes: {
          paper: { w: SMALL_PAPER, h: 2 },
          lg: { w: SMALL_LG, h: 2 },
        }
    },
    "currency-exposure": {
        id: "currency-exposure",
        headline: "Currency exposure",
        dataType: WIDGET_TYPES.pieChart,
        category: widgetCategories.distribution,
        paths: {
          table: 'table',
          chart: 'chart',
        },
        sizes: {
          paper: { w: SMALL_PAPER, h: 2 },
          lg: { w: SMALL_LG, h: 2 },
        }
    },
    "sector-distribution": { 
        id: "sector-distribution", 
        headline: "Sector distribution",
        dataType: WIDGET_TYPES.pieChart,
        category: widgetCategories.distribution,
        paths: {
          table: 'table',
          chart: 'chart',
        },
        sizes: {
          paper: { w: LARGE_PAPER, h: 5 },
          lg: { w: LARGE_LG, h: 4 },
        }
    },
    "total-invested": { 
        id: "total-invested", 
        headline: "Total invested",
        dataType: WIDGET_TYPES.number,
        category: widgetCategories.stat,
        paths: {
          table: 'table',
          chart: 'chart',
        },
        sizes: {
          paper: { w: LARGE_PAPER, h: 2 },
          lg: { w: LARGE_LG, h: 3 },
        }
    },

    "target-weight": {
        id: "target-weight",
        headline: "Target weight",
        dataType: WIDGET_TYPES.barChart,
        category: widgetCategories.comparison,
        paths: {
          chart: 'data',
        },
        sizes: {
          paper: { w: LARGE_PAPER, h: 2 },
          lg: { w: LARGE_LG, h: 2 },
        }
    },
}

import React from 'react';
import { Grid, Typography, Stack  } from '@mui/material';
import ForgotPasswordForm from '../../Forms/ForgotPassword';
import PageFormContainer from 'Layout/PageFormContainer';

const InitializeNewOnboardingOfPrimaryCustomer = () => {
    return (
        <PageFormContainer fluid={'true'} sx={{ top: 0 }}>
            <div>
                <Typography variant="h1" gutterBottom>Rebus Finance</Typography>
            </div>
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={6} md={4}>
                    <Stack spacing={2} direction={"column"}>
                        <Typography variant="h6" >Enter your email address to reset your password.</Typography>
                        <ForgotPasswordForm />
                    </Stack>
                </Grid>
            </Grid>
        </PageFormContainer>
    )

}

export default InitializeNewOnboardingOfPrimaryCustomer;

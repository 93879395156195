import call from 'api/call';
import { APIS } from 'api/constants';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { externalApiCallErrorHandler } from "utils/apiDataHelpers";
import { formattedDateToString, dateIntToDate, dateToInt, goYearsBack } from "utils/dateFormatters";
import snackbar from "./snackbarStore";
import assetStore from "./assetStore";
import dataStore from "./dataStore";
import navigation from "./navigationStore";
import {mobXStoreHelper} from "utils/mobXStoreHelper"

class ProxyAssetStore {
    benchmarks = []
    assetHistoryDataFromDate = dateIntToDate(goYearsBack(dataStore.calcDate));
    proxyPricesFromDate = dateIntToDate(goYearsBack(dataStore.calcDate));
    currentAssetData = [];
    currentAssetDataKey = null;
    currentTrackingAsset = [];
    currentTrackingAssetKey = null;
    newTrackingAsset = [];
    newTrackingAssetKey = null;
    correlation = "";
    proxyHistoryChartInterval = 30;
    trackingAssetDataKey = null;
    dateRange = {
        min: dateIntToDate(goYearsBack(dataStore.calcDate, 3)),
        max: dateIntToDate(goYearsBack(dataStore.calcDate)),
    }

    constructor() {
        makeObservable(this, {
            fetchBenchmarks: action.bound,
            assetHistoryDataFromDate: observable,
            selectNewTrackingAsset: action.bound,
            correlation: observable,
            constructAssetPrices: action.bound,
            benchmarks: observable,
            setProxyPricesFromDate: action.bound,
            proxyPricesFromDate: observable,
            currentAssetData: observable,
            currentAssetDataKey: observable,
            currentTrackingAsset: observable,
            currentTrackingAssetKey: observable,
            newTrackingAsset: observable,
            newTrackingAssetKey: observable,
            proxyHistoryChartInterval: observable,
            trackingAssetDataKey: observable,
            dateRange: observable,
        });
    }

    setProxyHistoryChartInterval(date) {
        let newInterval = 30
        const todayAsInt = dateToInt(dateIntToDate(dataStore.calcDate));
        const yearsAgo = parseInt(todayAsInt / 10000) - parseInt(date / 10000);

        if(yearsAgo > 1) {
            newInterval = yearsAgo * 30
        }

        this.proxyHistoryChartInterval = newInterval
    }

    selectNewTrackingAsset(newTrackingAssetKey) {
        this.newTrackingAssetKey = newTrackingAssetKey
        this.constructAssetPrices(assetStore.selectedAsset, newTrackingAssetKey)
    }

    setProxyPricesFromDate(date, asset) {
        this.proxyPricesFromDate = dateIntToDate(date)
        this.setProxyHistoryChartInterval(date)
        this.constructAssetPrices(asset, asset.DependencyAsset, 1, () => {
            const key = (this.newTrackingAssetKey).match(/\((.*)\)/).pop();
            this.constructAssetPrices(asset, key)
        })
    }

    async constructAssetPrices(parameters, newTrackingAssetKey = null, Save = 0, cb) {  
        if(!parameters) {
            snackbar.handleOpen("No asset selected", "error")
            return
        }
        const { AssetID, AssetCurrencyCode, DependencyAsset } = parameters;
        
        const params = {
            TrackingAssetID: newTrackingAssetKey || DependencyAsset || "GLOBAL_EQUITY",
            Method: "TrackAsset",
            FromDate: dateToInt(this.proxyPricesFromDate),
            ToDate: dataStore.calcDate,
            AssetID, 
            CurrencyCode: AssetCurrencyCode,
            Save,
        }

        mobXStoreHelper(APIS.CONSTRUCT_ASSET_PRICES, params, (response) => {
            const trackingAssetKey = `Tracking asset (${params.TrackingAssetID})`;
            this.currentTrackingAssetKey = trackingAssetKey                
            this.correlation = response.message.BetaLabel
            this.currentTrackingAsset = response.message.TrackingAsset.Prices.map((el) => ({ [trackingAssetKey]: parseFloat(el.Price.toFixed(2)), date: formattedDateToString(el.Dates) } ))

            if(Save) {
                assetStore.selectedAsset.DependencyAsset = params.TrackingAssetID;
                const currentAssetKey = `${params.AssetID} (${assetStore.selectedAsset.DependencyAsset})`;
                this.currentAssetDataKey = currentAssetKey;

                this.currentAssetData = response.message.Data.Prices.map((el) => ({ [currentAssetKey]: parseFloat(el.Price.toFixed(2)), date: formattedDateToString(el.Dates) } ))
                this.newTrackingAsset = []
            } else {
                const currentAssetKey = `${params.AssetID} (${DependencyAsset})`;
                this.currentAssetDataKey = currentAssetKey;

                if(newTrackingAssetKey) {
                    const dataKey = `${params.AssetID} (${newTrackingAssetKey})`
                    this.trackingAssetDataKey = dataKey
                    this.newTrackingAsset = response.message.Data.Prices.map((el) => ({ [dataKey]: parseFloat(el.Price.toFixed(2)), date: formattedDateToString(el.Dates) } ))
                    this.currentAssetData = response.message.ExistingData.Prices.map((el) => ({ [currentAssetKey]: parseFloat(el.Price.toFixed(2)), date: formattedDateToString(el.Dates) } ))
                } else {
                    this.currentAssetData = response.message.Data.Prices.map((el) => ({ [currentAssetKey]: parseFloat(el.Price.toFixed(2)), date: formattedDateToString(el.Dates) } ))
                }
           }
           if(cb) {
               cb()
           } 
        })
    }

    async fetchBenchmarks() {
        mobXStoreHelper(APIS.GET_CURRENT_ASSET_CLASS, { "IncludeDateRange": 1 }, (response) => {
            this.benchmarks = response.message.AssetClasses.map((el) => {
                return {
                    value: el.AssetClass_ShortName,
                    label: el.BenchMark_Name
                }
            })
        })
    }
}

const proxyAssetStore = (window.proxyAssetStore = new ProxyAssetStore());

export default proxyAssetStore;

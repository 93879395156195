import React, { useEffect, useState } from 'react';
import { TextField, Button, Stack, MenuItem, Select, FormHelperText, FormControl, InputLabel } from '@mui/material';
import supabase from 'utils/supabase';
import stores from "stores";
import { useHistory } from 'react-router-dom';


const UpdatePassword = () => {
    const history = useHistory();
    const { snackbar } = stores;
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [retypePassword, setRetypePassword] = useState('');


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Perform validation
        const validationErrors = {};

        if (!password) {
            validationErrors.password = 'Password is required';
        }
        if (!retypePassword) {
            validationErrors.retypePassword = 'Retype password is required';
        }

        if(password !== retypePassword) {
            validationErrors.retypePassword = 'Passwords do not match';
        }

        if(password.length < 8) {
            validationErrors.password = 'Password must be at least 8 characters long';
        }

        // Update errors state
        setErrors(validationErrors);

        // Submit form if there are no validation errors
        if (Object.keys(validationErrors).length === 0) {
            // Submit logic here
            const updateUserRes = await supabase.auth.updateUser({
                password: password
            })

            if(updateUserRes.error) {
                snackbar.handleOpen(updateUserRes.error.message, "error")
                return;
            }

            snackbar.handleOpen("User created successfully", "success")
            setTimeout(() => {
                history.push("/login")
            }, 1500)
        }
    };

    const handleTypePassword = (e) => {
        const newPassword = e.target.value;
        const validationErrors = {};

        // Perform password validation
        if (newPassword.length < 8) {
            validationErrors.password = 'Password must be at least 8 characters long';
        }
        if (!/\d/.test(newPassword)) {
            validationErrors.password = 'Password must contain at least one digit';
        }
        if (!/[a-zA-Z]/.test(newPassword)) {
            validationErrors.password = 'Password must contain at least one letter';
        }

        // Update errors state
        setErrors(validationErrors);
        setPassword(newPassword);
    };

    const handlePasswordMatch = (e) => {
        const newPassword = e.target.value;
        const validationErrors = {};

        if (password !== newPassword) {
            validationErrors.retypePassword = 'Passwords do not match';
        }

        setErrors(validationErrors);
        setRetypePassword(newPassword);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
                <TextField
                    label="Password"
                    type="password"
                    value={password}
                    onChange={handleTypePassword}
                    error={!!errors.password}
                    helperText={errors.password}
                    fullWidth
                />
                <TextField
                    label="Retype password"
                    type="password"
                    value={retypePassword}
                    onChange={handlePasswordMatch}
                    error={!!errors.retypePassword}
                    helperText={errors.retypePassword}
                    fullWidth
                />
                <Button disabled={Object.keys(errors).length > 0} type="submit" variant="contained" color="primary">
                    Submit
                </Button>
            </Stack>
        </form>
    );
};

export default UpdatePassword;
export const MARGIN_MARKS = [
    {
      value: 0,
      label: '0mm',
    },
    {
      value: 10,
      label: '10mm',
    },
    {
      value: 20,
      label: '20mm',
    },
];

export const LOGO_SIZE_MARKS = [
    {
      value: 38,
      label: 'xs',
    },
    {
        value: 76,
        label: 'sm',
    },
    {
        value: 114,
        label: 'md',
    },
    {
        value: 152,
        label: 'lg',
    },
    {
        value: 190,
        label: 'xl',
    },
];

export const ALLOWED_FILETYPES = ['JPG', 'JPEG', 'PNG']

export const MAX_LOGO_HEIGHT = 190
export const LOGO_SIZES = {
    xs: 38,
    sm: 76,
    md: 114,
    lg: 152,
    xl: 190
}

export const LOGO_LABELS = {
    38: 'Extra Small',
    76: 'Small',
    114: 'Medium',
    152: 'Large',
    190: 'Extra Large'
}

export const ALLOWED_IMAGE_EXT = 'png'
export const IMAGE_TYPES = {
    companyLogo: 'company-logo',
    headerBanner: 'header-banner'
}
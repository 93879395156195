import { APIS } from 'api/constants';
import { action, makeObservable, observable } from 'mobx';
import { dataSourceOptionsConstants, complexTypes } from "Pages/Assets/constants";
import { v4 as uuidv4 } from 'uuid';
import snackbar from "./snackbarStore";
import {mobXStoreHelper} from "utils/mobXStoreHelper"
import portfoliosStore from "./portfoliosStore";
import assumptionStore from "./assumptionStore";
import benchmarksStore from "./benchmarksStore";

const constructAssetRow = (row) => {
    const rowCopy = {
        AssetID: row.AssetID.toString(),
        CurrencyCode: row.AssetCurrencyCode.toString(),
    }

    // We have to make ovetwrites to the row to match the API
    if (row.Sector) {rowCopy.Sector = row.Sector}
    if (row.SubSector) {rowCopy.SubSector = row.SubSector}
    if (row.AssetName) {rowCopy.AssetName = row.AssetName}
    if (row.CurrencyCode) {rowCopy.CurrencyCode = row.AssetCurrencyCode}
    if (row.Description) {rowCopy.Description = row.Description}
    if (row.RiskClassification) {rowCopy.RiskClassification = row.RiskClassification}
    if (row.FreeField) {rowCopy.FreeField = row.FreeField}
    if (row.ParentIssuer) {rowCopy.ParentIssuer = row.ParentIssuer}
    
    return rowCopy;
}

const constructAssetPriceSourceRow = (details) => {


    const rowToSave2 = {
        "CurrencyCode":details.AssetCurrencyCode,
        "AssetID":details.AssetID,
    }

    if (details.PriceSource) {
        rowToSave2.PriceSource = details.PriceSource

        if(details.PriceSource.toUpperCase() === dataSourceOptionsConstants.PROJECTION) {
            rowToSave2.PriceSource = dataSourceOptionsConstants.DEPENDENT
        }

        if(details.PriceSource.toUpperCase() !== dataSourceOptionsConstants.VENDOR) {
            if(details.ComplexType) {
                rowToSave2.ComplexType = details.ComplexType
            } else {
                rowToSave2.ComplexType = complexTypes[0].value
            }
            
            if(details.DependencyAsset) {
                rowToSave2.DependencyAsset = details.DependencyAsset
            } else {
                rowToSave2.DependencyAsset = details.BenchMark
            }
        } else {
            if(details.ComplexType) {
                rowToSave2.ComplexType = details.ComplexType
            }
                
            if(details.DependencyAsset) {
                rowToSave2.DependencyAsset = details.DependencyAsset
            }
        }
    }
    return rowToSave2;
}

class AssetsOverviewStore {
    assets = [];
    assetTypes = [];
    filteredAssets = [];
    isFilterActive = false;
    selectedAssetType = {
        name: null,
        columns: [],
        rows: []
    };

    constructor() {
        makeObservable(this, {
            selectedAssetType: observable,
            // The original list of assets
            assets: observable,
            // The list of unique asset types
            assetTypes: observable,

            filteredAssets: observable,
            handleFilterAssets: action.bound,
            addAsset: action.bound,
            deleteAsset: action.bound,
            nukeAllAssets: action.bound,
            fetchAssets: action.bound,
            updateAsset: action.bound,
            isFilterActive: observable,
            handleSetSelectedAssetType: action.bound,
        });
    }

    handleSetSelectedAssetType(newAssetType) {
        this.selectedAssetType = newAssetType;
    }

    handleFilterAssets(value, key) {
        this.isFilterActive = true;
        let filteredAssets = this.selectedAssetType.rows;

        const keyIsArray = Array.isArray(key);

        // Note this is a specific case when looking for assets
        // Normally we would remove the irrelevant assets, but since the portfolio creates subsets of assets
        // Their relevance overrides every other filter in the list
        if(!keyIsArray && key === "PortfolioName") {
            let assetsFromPortfolio = []
            
            let selectedPorts = portfoliosStore.allPortfolios.filter((port) => value.includes(port.PortfolioName))
            if(selectedPorts.length > 0) {
                selectedPorts.forEach((el) => {
                    const assets = el.Assets.map((el2) => ({...el2, CurrencyCode: el2.AssetCurrency, PortfolioName: el.PortfolioName, id: uuidv4()}))
                    assetsFromPortfolio.push(...assets)
                })

                assetsFromPortfolio = assetsFromPortfolio.filter((value, index, self) => {
                    
                    const filered = self.findIndex((t) => {
                        return (
                            t.CurrencyCode === value.CurrencyCode && t.AssetID === value.AssetID
                        )
                    }) === index;

                    return filered;
                }
                
                // index === self.findIndex((t) => (
                //   t.CurrencyCode === value.CurrencyCode && t.AssetID === value.AssetID
                // ))
              )

              

                filteredAssets = assetsFromPortfolio;
            }
        }

        
        if (!keyIsArray && key === "AssetType") {
            if (value === "All") {
                filteredAssets = this.selectedAssetType.rows;
            }
            else {
                filteredAssets = this.selectedAssetType.rows.filter((asset) => {
                    return value === asset.AssetType;
                });

            }
        }

        // TODO: When you have time, refactor and clean this code
        if(keyIsArray) {
                filteredAssets = this.selectedAssetType.rows.filter((asset) => {
                    const isinItem = asset[key[0]].toLowerCase();
                    const nameItem = asset[key[1]].toLowerCase();
                    return isinItem.includes(value.toLowerCase()) || nameItem.includes(value.toLowerCase());
                })
            

            //let text = "Hello world, welcome to the universe.";
            // let result = text.includes("world");
        }

        this.filteredAssets = filteredAssets;
    }

    async addAsset (assetId, currencyCode, cb = null) {
        const newRow = {
            AssetID: assetId,
            CurrencyCode: currencyCode
        };


        mobXStoreHelper(APIS.ADD_ASSET, newRow, (response) => {
            if(response) {
                snackbar.handleOpen("Asset has been added", "success");
                this.fetchAssets();

                if(cb) {
                    cb(newRow);
                }

            }
        })   
    }

    async deleteAsset (assetId, currencyCode, cb = null) {
        mobXStoreHelper(APIS.DELETE_ASSET_DB, { AssetID: assetId, CurrencyCode: currencyCode}, (response) => {
            if(response) {
                const newAssets = this.assets.filter((el) => el.AssetID !== assetId);
                this.assets = newAssets;

                if(cb) {
                    cb();
                }

                snackbar.handleOpen("Asset has been deleted", "success");
            }
        });
    }

    async nukeAllAssets (cb = null) {
        const assetsToDelete = {
            AssetID: [],
            CurrencyCode: []
        };

        for (let i = 0; i < this.assets.length; i++) {
            assetsToDelete.AssetID.push(this.assets[i].AssetID)
            assetsToDelete.CurrencyCode.push(this.assets[i].CurrencyCode)
        }

        mobXStoreHelper(APIS.DELETE_ASSET, assetsToDelete, (response) => {
            if(response) {
                this.assets = [];

                if(cb) {
                    cb();
                }

                snackbar.handleOpen("All assets have been deleted", "success");
            }
        });
    }
        
    async updateAsset(rows, cb = null, ignorePriceSource = false) {
        const bulkUpload = {};
        const bulkUploadArray = [];

        // here we construct lists to match the api and the callback after
        rows.forEach((el) => {
            const newRow = constructAssetRow(el)
            bulkUpload[el.id] = newRow;
            bulkUploadArray.push(newRow);
        });

        mobXStoreHelper(APIS.UPDATE_ASSET_META_DATA, bulkUpload, (response) => {
            
            if(!ignorePriceSource) {
        
                const bulkUpload2 = {};
                const bulkUploadArray2 = [];
        
                // here we construct lists to match the api and the callback after
                rows.forEach((el) => {
                    const newRow = constructAssetPriceSourceRow(el)
                    bulkUpload2[el.id] = newRow;
                    bulkUploadArray2.push(newRow);
                });
            mobXStoreHelper(APIS.UPDATE_ASSET_PRICE_DATA, bulkUpload2, (response2) => {
                const assetsCopy = this.assets
                const newAssets = []
    
                bulkUploadArray2.forEach((el) => {
                    bulkUploadArray.forEach((el2) => {
                        const oldAsset = this.assets.find((el3) => el3.AssetID === el.AssetID)
                        if(el.AssetID === el2.AssetID) {
                            newAssets.push({...oldAsset, ...el, ...el2})
                        }
                    })
                });
    
                this.assets = [...assetsCopy, ...newAssets];
                if(cb) {
                    cb(this.assets)
                }
    
                snackbar.handleOpen("Assets has been updated", "success")
            })
        } else {
            const assetsCopy = this.assets
            const newAssets = []

            bulkUploadArray.forEach((el2) => {
                const oldAsset = this.assets.find((el3) => el3.AssetID === el2.AssetID)
                newAssets.push({...oldAsset, ...el2})
            })

            this.assets = [...assetsCopy, ...newAssets];
            if(cb) {
                cb(this.assets)
            }

            snackbar.handleOpen("Assets has been updated", "success")
        }

        })
    }

    async fetchAssets(query) {
        if (!benchmarksStore.benchmarksList.length) {
            await benchmarksStore.fetchCurrentAssetClasses();
        }
        await assumptionStore.getAssumptions();

        mobXStoreHelper(APIS.GET_ASSETS_STATIC_DATA, {}, (response) => {

            // const merged1 = mergeArrays(response.meta.data.MetaData, response.meta.data.PriceSource, "AssetID");
            // const merged2 = mergeArrays(merged1, response.meta.data.ProcessParameters, "AssetID");
            
            // const treated = merged2.map((el, i) => ({ ...el, id: uuidv4(), PriceSource: el.PriceSource.toUpperCase() }))

            // const assetTypes = treated.map((asset)=>{
            //     return asset.AssetType;
            // }).filter((assetType) => {
            //     return typeof assetType === "string";
            // })

            const assumptionsList = [];

            const riskClassification = assumptionStore.findAssumptionbyName("RiskClassification");

            if (riskClassification) {
                riskClassification.Name.forEach((name, index)=>{
                    assumptionsList[index] = {
                        label: name,
                        value: riskClassification.Number[index]
                    }
                })
            }

            this.assetTypes = response.message.AssetDB.map((el) => {
                return {
                    name: el.AssetType,
                    columns: Object.keys(el.Filter[0]).map((col) => {
                        let options = {
                            field: col,
                            editable: !!el.Filter[0][col],
                            headerName: col.replace(/([A-Z])/g, " $1"),
                            minWidth: 200,
                        };

                        if (col === "BenchMark") {
                            options = {
                                ...options,
                                minWidth: 260,
                                type: "singleSelect",
                                valueOptions: benchmarksStore.benchmarksList
                            }
                        } 

                        if (col === "PriceSource") {
                            options = {
                                ...options,
                                type: "singleSelect",
                                valueOptions: Object.values(dataSourceOptionsConstants)
                            }
                        }
                        if (col === "ComplexType") {
                            options = {
                                ...options,
                                type: "singleSelect",
                                valueOptions: [{ label: "Private equity", value: "Private_Equity" }, { label: "Real estate", value: "Real_Estate" }, { label: "Alternative", value: "Alternative" }, { label: "Hedge Fund", value: "HedgeFund" }, { label: "Infrastructure", value: "Infrastructure" }, { label: "Private debt", value: "Private_Debt" }, { label: "Venture capital", value: "Venture_Capital" }, { label: "Growth equity", value: "Growth_Equity" }, { label: "ILS", value: "ILS" }, { label: "Secondary PE", value: "SecondaryPE" }, { label: "Renewable Energy", value: "Renewable_Energy" }],
                                getOptionValue: (value) => value.value,
                                getOptionLabel: (value) => value.label,
                                valueFormatter: (props) => {
                                    return props.label;
                                }
                            }
                        } 
                        
                        

                        
                        if (col === "RiskClassification") {
                            options = {
                                ...options,
                                type: "singleSelect",
                                valueOptions: assumptionsList,
                                getOptionValue: (value) => value.value,
                                getOptionLabel: (value) => `${value.label} (${value.value})`,
                                valueFormatter: (props) => {
                                    if(!props.value) {
                                        return ""
                                    }
                                    return `${assumptionsList[props.value-1].label} (${props.value})`;
                                }
                            }
                        }

                        return options
                    }),
                    rows: el.Data.map((row) => {
                        return {
                            ...row,
                            id: uuidv4(),
                            AssetType: el.AssetType
                        }
                    })
                }
            });

            this.handleSetSelectedAssetType(this.assetTypes[0])
        })            
    }
}

const assetsOverviewStore = (window.assetsOverviewStore = new AssetsOverviewStore());

export default assetsOverviewStore;

/* eslint-disable no-unused-vars */
import call from 'api/call';
import { APIS, IRISK_BO_API_URL } from 'api/constants';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { setToken, getToken, removeToken } from 'utils/localStorage';
import {mobXStoreHelper, mobXStoreInternalApiHelper} from "utils/mobXStoreHelper"
import { LOGIN_PAGE_PATH } from 'routes/privateRoutes';

class ClientSettingsStore {
    settings = {}
    

    constructor() {
        makeObservable(this, {
            settings: observable,
            getClientSettings: action.bound,
        });
    }

    async getClientSettings() {
        mobXStoreInternalApiHelper("GET", `${IRISK_BO_API_URL}/client/business-configuration`, {}, (response) => {
            this.settings = response.data
        }, () => {
            console.error("Unable to fetch client settings")
        })
    }

}

const clientSettingsStore = (window.clientSettingsStore = new ClientSettingsStore());

export default clientSettingsStore;

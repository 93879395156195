import { APIS } from 'api/constants';
import { action, makeObservable, observable } from 'mobx';
import snackbar from "./snackbarStore";
import { mobXStoreHelper } from "utils/mobXStoreHelper"
import portfoliosStore from "stores/portfoliosStore"
import customersStore from 'stores/customersStore';
import { CUSTOMER } from "routes/privateRoutes";

class OnboardingStore {
    currentStep = 0;
    fetched = false;
    completed = false;
    firstCustomer = {};

    constructor() {
        makeObservable(this, {
            currentStep: observable,
            fetched: observable,
            completed: observable,
            firstCustomer: observable,
            nextStep: action.bound,
            addCustomer: action.bound,
            setKYCToDone: action.bound,
            setRiskProfile: action.bound,
            savePortfolioUpload: action.bound,
            getPortfolioURL: action.bound
        });
    }

    async nextStep () {
        this.currentStep = this.currentStep + 1;
    }

    async setKYCToDone () {
        customersStore.setKYCToDone(this.firstCustomer.id, () => {
            this.nextStep();
        })
    }

    async addCustomer (customer) {
        customersStore.create(customer, (newCustomer) => {
            this.firstCustomer = newCustomer;
            this.nextStep();
        });
    }

    async setRiskProfile (riskValue) {
        customersStore.setRiskProfileValue(this.firstCustomer.id, riskValue, () => {
            this.nextStep();
        })
    }

    async savePortfolioUpload (dataArray, errorCb) {
        portfoliosStore.savePortfolioUpload(dataArray, this.firstCustomer.id, (resp) => {
            this.nextStep();
        }, (errorText) => {
            errorCb(errorText)
        })
    }

    async getPortfolioURL (cb) {
        const portfolio = await this.checkIfAnyPortfolio();

        if (portfolio) {
            const url = `${CUSTOMER}/${this.firstCustomer.id}/portfolio/${portfolio.id}`
            cb(url);
        }
        else {
            cb(null)
        }
    }

    async fetchCurrentStep(history) {
        const firstCustomer = await this.checkIfAnyCustomers();

        let currentStep = 0;

        if (!firstCustomer) {
            this.currentStep = 0;
            this.fetched = true;
            history.push("/onboarding")
        }

        

        this.firstCustomer = firstCustomer;
   
        const portfolio = await this.checkIfAnyPortfolio();

        if (!portfolio) {
            currentStep = 1;
        }
        else {
            currentStep = 2;
        }

        this.currentStep = currentStep;
        this.fetched = true;
    }

    async checkIfAnyPortfolio () {

        return new Promise((resolve, reject) => {
            portfoliosStore.fetchAllPortfolios(() => {
                resolve(portfoliosStore.allPortfolios.length > 0 ? portfoliosStore.allPortfolios[0] : null)
            }, (text, code) => {
                if (text === "No Portfolios exists - System Aborts" && code === -233) {
                    resolve(null)
                }
                else {
                    snackbar.handleOpen("Error retrieving portfolio", "error")
                }
            })
        })
    }

    async checkIfAnyCustomers () {

        return new Promise((resolve, reject) => {
            customersStore.getAll((customers) => {
                resolve(customers.length > 0 ? customers[customers.length-1] : null)
            })
        })
    }

}

const onboardingStore = (window.onboardingStore = new OnboardingStore());

export default onboardingStore;

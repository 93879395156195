// This store is going to have data for the widgets that we are going to use in our application.
// Initially we will only do repporting on each portfolio, but we can expand this to other areas of the application later on.
import { APIS, API_URL } from "api/constants";
import { action, makeObservable, observable, runInAction } from "mobx";
import { initialPortfolioWidgets, widgetCategoryList, WIDGET_TYPES } from "./constants";
import { getCategorizedWidgets, getWidgetIds, constructDynamicWidgetSize } from "./utils";
import call from 'api/call';
import snackbarStore from "stores/snackbarStore";
import portfoliosStore from "stores/portfoliosStore";
import dataStore from "stores/dataStore";
import moment from "moment";
import { dateToInt, dateIntToDate } from "utils/dateFormatters";
import PDFEditorStore from "stores/PDFEditor";
import { getAUMRelatedWidgets, getDataTypeFromCategory } from "./utils";

class PortfolioWidgetsStore {
    loading = false                       // 
    fetchedId = null
    error = null
    widgets = initialPortfolioWidgets
    widgetsMenu = {}
    widgetsData = {}
    reportItem = null
    breakpoint = null
    startDate = null
    endDate = null

    constructor() {
        makeObservable(this, {
            initializePortfolioReport: action,
            constructWidgetsMenu: action,
            selectWidget: action,
            widgetsMenu: observable,
            widgets: observable,
            loading: observable,
            fetchedId: observable,
            error: observable,
            widgetsData: observable,
            injectWidgetData: action,
            reportItem: observable,
            updateReportItemLayout: action,
            // addWidgetToLayout: action,
            removeWidgetFromLayout: action,
            // lockWidget: action,
            // unlockWidget: action,
            setBreakpoint: action,
            changeCalcDate: action,
            breakpoint: observable,
            startDate: observable,
            endDate: observable,
        });
    }

    setBreakpoint(breakpoint) {
      this.breakpoint = breakpoint
    }

    // lockWidget(widgetId) {
    //   const updatedLayout = {
    //     ...this.reportItem,
    //     layout: {
    //       ...this.reportItem.layout,
    //       [this.breakpoint]: this.reportItem.layout[this.breakpoint].map(widget => widget.i === widgetId ? { ...widget, static: true } : widget)
    //     }
    //   }

    //   this.reportItem = updatedLayout
    // }

    // unlockWidget(widgetId) {
    //   const updatedLayout = {
    //     ...this.reportItem,
    //     layout: {
    //       ...this.reportItem.layout,
    //       [this.breakpoint]: this.reportItem.layout[this.breakpoint].map(widget => widget.i === widgetId ? { ...widget, static: false } : widget)
    //     }
    //   }

    //   this.reportItem = updatedLayout
    // }

    updateReportItemLayout(newLayout) {
      const updatedLayout = {
        ...this.reportItem,
        layout: {
          ...this.reportItem.layout,
          [this.breakpoint]: newLayout
        }
      }

      this.reportItem = updatedLayout
    }

    // addWidgetToLayout(widget, breakpoint) {
    //   const constructedNewWidgetItem = constructNewWidgetItem(widget, this.breakpoint)
    //   const updatedLayout = {
    //     ...this.reportItem,
    //     layout: {
    //       ...this.reportItem.layout,
    //       [breakpoint]: [...this.reportItem.layout[breakpoint], constructedNewWidgetItem]
    //     }
    //   }

    //   this.reportItem = updatedLayout
    // }

    removeWidgetFromLayout(widgetId, breakpoint) {
      const updatedLayout = {
        ...this.reportItem,
        layout: {
          ...this.reportItem.layout,
          [breakpoint]: this.reportItem.layout[breakpoint].filter(widget => widget.i !== widgetId)
        }
      }

      this.reportItem = updatedLayout
    }

    injectWidgetData(widgetId, data) {
      this.widgetsData = {
        ...this.widgetsData,
        [widgetId]: data
      }
    }

    selectWidget(newWidget, reportType, pageId) {
      if(reportType === 'pdf') {
        PDFEditorStore.addWidgetToLayout(newWidget, pageId)
      }
      // TODO: Layout for a widget might be in a store
      // Then we can add the widget to the layout
      // When constructing the menu, we can then 
      // check if the widget is in the layout and give it a checkmark
      // const selectedItem = this.widgets[newWidget.id]
      // fetch from dynamic url and set the data in the store
      // const foundWidgetInLayout = this.reportItem.layout[this.breakpoint].find((widget) => widget.i === newWidget.id)
      // if(foundWidgetInLayout) { // Remove widget from layout
      //     // TODO remove widget from widgetLayout
      //     this.removeWidgetFromLayout(newWidget.id, this.breakpoint)
      // } else {
      //     this.addWidgetToLayout(newWidget, this.breakpoint)
      // }
    }

    async changeCalcDate(customerId, portfolioId, newDate, key) {
      this[key] = dateToInt(newDate)
      
      await portfoliosStore.fetchCustomerPortfolioDetails(customerId, portfolioId, this.startDate, this.endDate);
    }

    

    async initializePortfolioReport(customerId, portfolioId, startDate, endDate) {
      // TODO: Fetch data for the widgets (including AUM for example)
      runInAction(() => {
        this.loading = true;
        this.endDate = dataStore.calcDate
        this.startDate = dateToInt(moment(dateIntToDate(dataStore.calcDate)).subtract(1, 'years'))

      });
        try {
          // Fetch global if it is not allready done for the given portfolio
          if(portfoliosStore.fetchedId !== portfolioId) {
            await portfoliosStore.fetchCustomerPortfolioDetails(customerId, portfolioId, this.startDate, this.endDate);
          }
          
          const response = await call(APIS.GET_CALCULATION_RESULT_HIST_CAT, { CustomerID: Number(customerId), PortfolioName: portfolioId });
          
          await response.message.Category.forEach(({CalcName, Category}) => {
            let widgetsCopy = { ...this.widgets }

            if(CalcName === 'AUM') {
              widgetsCopy = getAUMRelatedWidgets(widgetsCopy, CalcName, Category)
            } else {
              const foundWidget = widgetsCopy[CalcName]
              if(!foundWidget) {
                widgetsCopy = {
                  ...widgetsCopy,
                  [CalcName]: {}
                }
              }
                
              widgetsCopy[CalcName] = {
                id: CalcName,
                headline: CalcName.split('_').join(' '),
                category: Category,
                dataType: getDataTypeFromCategory(Category),
                independentFetchingURL: 'iRisk_GetCalculationResultHist',
                paths: {
                  chart: 'HistData',
                  investAmount: 'InvestAmount',
                },
                sizes: constructDynamicWidgetSize(CalcName, Category)
              }
            }
              runInAction(() => {
                this.widgets = widgetsCopy
              });

          })
        

          runInAction(() => {
            this.loading = false;
            this.fetchedId = portfolioId;
          });
          
        } catch (error) {
          snackbarStore.handleOpen(error.toString(), 'error');
          runInAction(() => {
            this.loading = false;
            this.error = error;
            this.fetchedId = null;
          });
        }
    }

    async constructWidgetsMenu(customerId, portfolioId, reportType) {
      if(this.fetchedId !== portfolioId) {
        await this.initializePortfolioReport(customerId, portfolioId);
      }

      const widgetsByCategory = getCategorizedWidgets(getWidgetIds(this.widgets));

        const widgetsMenu = {
            label: 'Widgets',
            items: [],
        };

        widgetCategoryList.forEach(category => {
          if (widgetsByCategory[category.key]) {
            widgetsMenu.items.push({
              label: category.label,
              items: widgetsByCategory[category.key].map(widget => ({
                ...widget,
                label: widget.headline,
                callback: (event, widget) => this.selectWidget(widget, reportType)
              }))
            });
          }
        });

        runInAction(() => {
          this.widgetsMenu = widgetsMenu;
        });

    }

    // This function is used to fetch data for widgets that has an independant url / api call
    async fetchDynamicWidgetData(widgetItem, customerId, portfolioId) {
      const params = {
          CustomerID: Number(customerId),
          PortfolioName: portfolioId,
          FromDate: portfoliosStore.portfolioReturn.FromDate,
          CalcName: widgetItem.id,
          ToDate: portfoliosStore.portfolioReturn.ToDate,
      };

      try {
        const response = await call(`${API_URL}/${widgetItem.independentFetchingURL}`, params);

        this.injectWidgetData(widgetItem.id, response.message);
      } catch (error) {
          snackbarStore.handleOpen(error.toString(), 'error');
      }
  }
}

const store = (window.portfolioWidgetsStore = new PortfolioWidgetsStore());

export default store;
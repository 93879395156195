import React, { lazy } from 'react';
import { Switch, BrowserRouter, Redirect } from 'react-router-dom';
import PrivateRoute from 'routes/PrivateRoute';
import PublicRoute from 'routes/PublicRoute';
import PRIVATE_ROUTES from 'routes/privateRoutes';
import AuthProvider from 'components/AuthProvider/AuthProvider';
import InitializeNewOnboardingOfPrimaryCustomer from 'Pages/InitializeNewOnboardingOfPrimaryCustomer';
import ForgotPassword from 'Pages/Login/ForgotPassword';
import UpdatePassword from 'Pages/Login/UpdatePassword';


const Login = lazy(() => import('Pages/Login/Login'));


const App = () => {
    return (
        <BrowserRouter>  
            <Switch>
                <Redirect exact={true} from="/" to="/login" />
                <PublicRoute component={ForgotPassword} path="/forgot-password" exact={true}  />
                <PublicRoute component={UpdatePassword} path="/update-password"  />
                <PublicRoute component={InitializeNewOnboardingOfPrimaryCustomer} path="/create-user"  />
                <PublicRoute exact={true} component={Login} path="/login"  />
                {/* <PublicRoute exact={true} component={Login2} path="/login2"  /> */}
                {/* <PublicRoute exact={true} component={ChartDisplay} path="/chart-display"  /> */}
                <AuthProvider>
                    {PRIVATE_ROUTES.map((route) => (
                        <PrivateRoute
                            strict={true}
                            exact={route.exact}
                            key={`${route.key}-${route.pageTitle}`}
                            path={route.path}
                            component={route.component}
                            />
                        ))}
                        
                </AuthProvider>
            </Switch>
        </BrowserRouter>
    );
};

export default App;

import { isString } from "utils/typeChecker"

export function capitalizeFirstLetter(elem) {
  // If not string, just return
  if(!isString(elem)) {
    return elem
  }

  // if only one letter
  if(elem.length === 1) {
    return elem.charAt(0).toUpperCase();
  }
  // a string with more than one letter
  return `${elem.charAt(0).toUpperCase()}${elem.slice(1).toLowerCase()}`
  
}

export function lowerCaseAll(string) {
  return string.toLowerCase()
}

export function prettify(text) {
  const newText = text.replaceAll("_", " ")
  return capitalizeFirstLetter(newText)
}

export function stringToBoolean(str) {
  if(str) {
    return lowerCaseAll(str) === "yes";
  }

  return false
}

/* eslint-disable no-unused-vars */
import { APIS, portfolioIsTransactionsBased, API_URL } from "api/constants";
import { action, makeObservable, observable, runInAction, toJS, extendObservable } from "mobx";
import { formatPortfolio, handleFormatWeights, handleFormatAllocations } from "utils/apiDataHelpers";
import { numberWithCommas } from "utils/numberManipulators";
import snackbar from "./snackbarStore";
import dataStore from "./dataStore";
import { mobXStoreHelper } from "utils/mobXStoreHelper";
import {
    dateToInt,
    now,
} from "utils/dateFormatters";
import { v4 as uuidv4 } from "uuid";
import { mergeArrays } from "utils/arrayHelpers";
import customersStore from "./customersStore";
import portfolioWidgetsStore from "./portfolioWidgets";
import PDFEditorStore from "./PDFEditor";

function addDataToPath(data, pathItems, newData) {
    let current = data;

    // Loop through pathItems to navigate to the target object
    for (let i = 0; i < pathItems.length; i++) {
        const item = pathItems[i];

        // If it's the last item in the path, add the new data
        if (i === pathItems.length - 1) {
            current[item] = { ...current[item], ...newData };
        } else {
            // Navigate to the next level in the path
            if (!current[item]) {
                current[item] = {};
            }
            current = current[item];
        }
    }
}

const translateErrorMessage = (errorMessage) => {
    switch (errorMessage) {
        case "Error: 1":
            return "No data to obtain";
        case "Error: 2":
            return "The start-date lies before there is data on the asset";
        case "Error: 3":
            return "The asset has matured";
    }
};

const formatUploadDataToFitApi = (payload, CustomerId) => {
    const formattedPayload = {
        AssetCurrency: [],
        AssetID: [],
        AssetName: [],
        Holding: [],
        RealPortfolio: payload.RealPortfolio,
        PortfolioCurrency: payload.PortfolioCurrency,
        PortfolioName: payload.PortfolioName,
        CustomerID: Number(CustomerId),
        PortfolioType: payload.PortfolioType
    };

    payload.Assets.forEach((el) => {
        formattedPayload.AssetCurrency.push(el.AssetCurrency);
        formattedPayload.AssetID.push(el.AssetID);
        formattedPayload.AssetName.push(el.AssetName);
        formattedPayload.Holding.push(parseFloat(el.Holding));
        if (el.GroupName) {
            if (
                formattedPayload.GroupName &&
                formattedPayload.GroupName.length
            ) {
                formattedPayload.GroupName.push(el.GroupName);
            } else {
                formattedPayload.GroupName = [];
                formattedPayload.GroupName.push(el.GroupName);
            }
        }
        if (el.ParentAC) {
            if (formattedPayload.ParentAC && formattedPayload.ParentAC.length) {
                formattedPayload.ParentAC.push(el.ParentAC);
            } else {
                formattedPayload.ParentAC = [];
                formattedPayload.ParentAC.push(el.ParentAC);
            }
        }
    });
    return formattedPayload;
};

const initialPortfolioStatistics = {
    topTenMostInvestedInAssets: {
        data: []
    },
    riskDistribution: {
        data: []
    },
    weights: {
        groupDistribution: {
            chart: [],
            table: []
        },
        sectorDistribution: {
            chart: [],
            table: []
        },
        riskClassification: {
            chart: [],
            table: []
        },
        currencyExposure: {
            chart: [],
            table: []
        },
        bondIssuers: {
            chart: [],
            table: []
        },
        bondType: {
            chart: [],
            table: []
        }
    },
    liquidityDistribution: {
        data: []
    },
    totalInvested: {
        data: null
    },
    allocations: {
        data: [],
        mapped: new Map()
    },
    target: {
        data: []
    },
    historical: {},
    holding: {
        rows: [],
        columns: [],
        columnVisibilityModel: {

        }
    }
};

class PortfoliosStore {
    fetchedId = null;
    loading = false;
    list = [];
    searchedPortfolios = [];
    allPortfolios = [];
    selectedPortfolio = [];
    portfolioColumns = [];
    selectedPortfolioType = null;
    searchResults = [];
    benchmarks = [];
    portfolioNAV = {
        Certificates: []
    };
    portfolioESGDetails = {
        ESG_Details: [],
        esgColumns: []
    };
    portfolioKeyfigures = {
        KeyFigures: [],
        keyfiguresColumns: [],
        KeyTable: []
    };
    portfolioCashflow = [];
    portfolioCashflowChart = [];
    portfolioReturn = {
        AllRows: [],
        DisplayRows: [],
        columns: [],
        IsHolding: false,
        IsExpanded: false,
        FromDate: null,
        ToDate: null,
        earliestFromDate: null,
        latestFromDate: null,
        earliestToDate: null,
        latestToDate: null,
        NonIncludedAssets: [],
        ReturnTable: []
    };
    complianceBreaches = {};
    pretradeComplianceRows = [];
    selectedPortfolioErrors = [];
    selectedPortfolioCurrency = null;
    portfolioInformation = {
        PortfolioType: 2 // assume transaction based to include all columns in overview table 
    };
    selectedPortfolioBenchmark = null;
    maxTradingDate = null;
    UseBankPrices = 0;
    selectedPortfolioGroups = [];
    riskLimitChart = {
        ruleTypeKeys: [],
        data: {},
        loading: false,
    };
    portFolioOverviewTableApiRef = null;
    portfoloOverview = null;
    isPretradeMode = false;
    portfolioStatistics = initialPortfolioStatistics
    allPortfoliosByObject = {}
    isConstructPortfolioPagesTriggered = false;


    constructor() {
        makeObservable(this, {
            loading: observable,
            fetchedId: observable,
            list: observable,
            portfolioNAV: observable,
            selectedPortfolioGroups: observable,
            selectedPortfolioCurrency: observable,
            fetchCustomerPortfolios: action.bound,
            fetchCustomerPortfolioDetails: action.bound,
            selectedPortfolioType: observable,
            savePortfolioUpload: action.bound,
            deletePortfolio: action.bound,
            mergePortfolios: action.bound,
            selectedPortfolio: observable,
            isPretradeMode: observable,
            selectedPortfolioBenchmark: observable,
            updateAssignedBenchmark: action.bound,
            addGroup: action.bound,
            addAsset: action.bound,
            setSearchResults: action.bound,
            searchResults: observable,
            fetchBenchmarks: action.bound,
            benchmarks: observable,
            portfolioESGDetails: observable,
            setCustomerPortfolioKeyfigures: action.bound,
            portfolioKeyfigures: observable,
            portfolioCashflow: observable,
            portfolioCashflowChart: observable,
            portfolioReturn: observable,
            complianceBreaches: observable,
            selectedPortfolioErrors: observable,
            portfolioInformation: observable,
            maxTradingDate: observable,
            updateAssetInPortfolio: action.bound,
            getNumberOfCertificates: action.bound,
            saveNumberOfCertificates: action.bound,
            addRowToPortfolioNAV: action.bound,
            allPortfolios: observable,
            getPortfoliosData: action.bound,
            pretradeComplianceRows: observable,
            getPretradeCompliance: action.bound,
            portfolioColumns: observable,
            fetchAllPortfolios: action.bound,
            setUseBankPrices: action.bound,
            UseBankPrices: observable,
            moveAssetToGroup: action.bound,
            constructRiskLimitBreachChart: action.bound,
            handleSearchPortfolios: action.bound,
            updatePortfolioSettings: action.bound,
            calcReturnAndAddToPortfolio: action.bound,
            updateCustomerPortfolioWithPretrades: action.bound,
            portfolioStatistics: observable,
            allPortfoliosByObject: observable,
            
        });

        extendObservable(this, {
            processPages: action(function (rows, numOfPagesToCreate, numOfRowsWithinPage, holdingData) {
                console.log("processPages called");
                let rowsStateCopy = [...rows]
    
                // Run the loop within an action to ensure it runs only once
                Array.from({ length: numOfPagesToCreate }).forEach((_, i) => {
                    const rowsForCurrentPage = rowsStateCopy.slice(0, numOfRowsWithinPage);
                    rowsStateCopy = rowsStateCopy.slice(numOfRowsWithinPage);
                    PDFEditorStore.createHoldingPages(i, rowsForCurrentPage, holdingData); // Call action within action
                    console.log(`Created page ${i} with widget holding_${i}`);
                });
            })
        })
    }

    handleSearchPortfolios(searchString) {
        let newSearchedPortfolios = this.allPortfolios
        newSearchedPortfolios = newSearchedPortfolios.filter(({ PortfolioSubName }) => PortfolioSubName.toLowerCase().includes(searchString.toLowerCase()))
        this.searchedPortfolios = newSearchedPortfolios

    }

    setUseBankPrices(newValue, portfolioId, customerId) {
        if (newValue === 1) {
            this.UseBankPrices = 1;
        } else {
            this.UseBankPrices = 0;
        }

        this.fetchCustomerPortfolioDetails(customerId, portfolioId);
    }

    setReturnDate(CustomerID, PortfolioName, date, type) {
        if (type === "To") {
            this.portfolioReturn.ToDate = date;
        } else {
            this.portfolioReturn.FromDate = date;
        }

        this.calcReturnAndAddToPortfolio(CustomerID, PortfolioName);
    }

    constructRiskLimitBreachChart(Compliance, portfolioBreaches) {
        const breachCounter = [];
        let chartData = {}
        const ruleTypeKeys = []

        for (var y = 2; y <= portfolioBreaches.length; y += 2) {
            const firstItem = portfolioBreaches[y - 2];
            const secondItem = portfolioBreaches[y - 1];

            breachCounter.push({
                name: firstItem.RuleType,
                [firstItem.Test_Type]: firstItem.BreachCounter,
                [secondItem.Test_Type]: secondItem.BreachCounter
            })
        }
        // Here we construct an object with all risk limit rules in it
        Compliance.forEach((el) => {
            if (!chartData[el.RuleType]) {
                chartData[el.RuleType] = {}
                ruleTypeKeys.push(el.RuleType)
            }
            if (!chartData[el.RuleType][el.Test_Type]) {
                chartData[el.RuleType][el.Test_Type] = []
            }
            chartData[el.RuleType][el.Test_Type].push({
                ...el,
                MaxValue: el.MaxValue === 1e+75 ? el.CurrentValue : el.MaxValue
            })
        })

        chartData = { ...chartData }

        this.riskLimitChart = {
            ruleTypeKeys: ruleTypeKeys,
            data: chartData,
            loading: false,
        }
    }

    addRowToPortfolioNAV(cb) {
        const id = uuidv4();
        const emptyItem = {
            id,
            NumberOfCertifates: "",
            Date: now()
        };

        this.portfolioNAV.Certificates = [
            emptyItem,
            ...this.portfolioNAV.Certificates
        ];
        if (cb) {
            cb(id);
        }
    }

    async fetchAllPortfolios(cb) {
        await customersStore.getAll()
        mobXStoreHelper(
            APIS.GET_ALL_PORTFOLIOS,
            {},
            (response) => {
                const customers = customersStore.customersById;

                this.allPortfolios = response.message.Portfolios.map((el) => ({
                    id: el.PortfolioName,
                    CustomerName: customers[el.CustomerID].name,
                    ...el
                }))

                if (cb) {
                    cb();
                }
            },
            (errText, errCode) => {
                if (cb) {
                    cb(errText, errCode);
                }
            }
        );
    }

    async fetchBenchmarks() {
        mobXStoreHelper(APIS.GET_CURRENT_ASSET_CLASS, {}, (response) => {
            const formatted = response.message.AssetClasses.map((el) => {
                return {
                    AssetClass_ShortName: el.AssetClass_ShortName,
                    BenchMark_Name: el.BenchMark_Name,
                    Group: el.ParentAC
                };
            });

            this.benchmarks = formatted;
        });
    }

    async updateAssignedBenchmark(
        customerId,
        portfolioId,
        selectedBenchmark,
        id
    ) {
        const params = {
            AssetClass_ShortName: selectedBenchmark,
            Weight: 100,
            CustomerID: Number(customerId),
            PortfolioName: portfolioId,
            ID: id
        };
        mobXStoreHelper(APIS.UPDATE_ASSIGNED_BENCHMARKS, params, (response) => {
            const dataCopy = this.selectedPortfolio.map((el) => {
                if (el.id === id) {
                    el.BenchMark = selectedBenchmark;
                }
                return el;
            });

            this.selectedPortfolio = dataCopy;
        });
    }

    async updateAssignedBenchmarks({ customerId, portfolioId, benchmarks, weights, id }, cb) {
        const payloadParams = {
            AssetClass_ShortName: benchmarks,
            CustomerID: Number(customerId),
            ID: id,
            PortfolioName: portfolioId,
            Weight: weights
        };
        mobXStoreHelper(APIS.UPDATE_ASSIGNED_BENCHMARKS, payloadParams, (response) => {
            console.log(response.message)

            this.fetchCustomerPortfolioDetails(customerId, portfolioId);

            if (cb) {
                cb();
            }
        });
    }

    async setSearchResults(searchString = "", cb) {
        mobXStoreHelper(APIS.SEARCH_ASSET, { SearchString: searchString },
            (response) => {
                if (
                    response.message.SearchResult &&
                    response.message.SearchResult.length
                ) {
                    this.searchResults = response.message.SearchResult;
                } else {
                    this.searchResults = [];
                }

                if (cb) {
                    cb();
                }
            }
        );
    }

    async updateAssetInPortfolio(customerId, portfolioId, asset) {
        const params = {
            CustomerID: Number(customerId),
            PortfolioName: portfolioId,
            ASSET: {
                AssetName: asset.AssetName,
                AssetCurrency: asset.AssetCurrency,
                AssetID: asset.AssetID,
                Holding: Number(asset.Holding)
            }
        };

        mobXStoreHelper(APIS.UPDATE_PORTFOLIO, params, (response) => {
            this.fetchCustomerPortfolioDetails(customerId, portfolioId);
            snackbar.handleOpen(
                `Asset ${asset.AssetName} was successfully updated`,
                "success"
            );
        });
    }

    async deleteAsset(customerId, portfolioId, assetId) {
        const params = {
            CustomerID: Number(customerId),
            PortfolioName: portfolioId,
            ID: assetId
        };

        mobXStoreHelper(APIS.DELETE_ASSET, params, (response) => {
            this.fetchCustomerPortfolioDetails(customerId, portfolioId);
            snackbar.handleOpen(`asset ${assetId} removed`, "success");
        });
    }

    async addAsset(customerId, portfolioId, asset, handleClose) {
        let assetParams = {
            AssetName: asset.AssetName,
            AssetCurrency: asset.AssetCurrency,
            AssetID: asset.AssetID,
            Holding: Number(asset.Holding)
        };
        if (asset.GroupName) {
            assetParams.GroupName = asset.GroupName;
        }

        const params = {
            CustomerID: Number(customerId),
            PortfolioName: portfolioId,
            ASSET: assetParams
        };

        mobXStoreHelper(APIS.UPDATE_PORTFOLIO, params, (response) => {
            snackbar.handleOpen(
                `Asset ${asset.AssetName} was successfully added to portfolio ${portfolioId}`,
                "success"
            );
            this.fetchCustomerPortfolioDetails(customerId, portfolioId);
            handleClose();
        });
    }

    async addGroup(
        customerId,
        portfolioId,
        groupName,
        setModalIsOpen,
        setGroupName
    ) {
        const params = {
            CustomerID: Number(customerId),
            PortfolioName: portfolioId,
            GroupName: groupName,
            ToFollowID: portfolioId,
            AddToGroupFlag: 0
        };

        mobXStoreHelper(APIS.ADD_GROUP, params, (response) => {
            const dataCopy = this.selectedPortfolio;
            dataCopy.splice(1, 0, {
                id: groupName,
                variant: "group",
                name: groupName,
                group: [groupName]
            });
            this.selectedPortfolio = dataCopy;
            this.fetchCustomerPortfolioDetails(customerId, portfolioId);

            setModalIsOpen();
            setGroupName("");
            snackbar.handleOpen(`Group ${groupName} added`, "success");
        });
    }

    async renameGroup(args, cb) {
        const params = {
            CustomerID: Number(args.customerId),
            PortfolioName: args.portfolioId,
            OldGroupName: args.id,
            NewGroupName: args.groupName
        };
        mobXStoreHelper(APIS.RENAME_GROUP, params, (response) => {
            this.fetchCustomerPortfolioDetails(args.customerId, args.portfolioId);
            if (cb) {
                cb();
            }
        });
    }

    async deleteGroup(customerId, portfolioId, groupName) {
        mobXStoreHelper(
            APIS.DELETE_GROUP,
            {
                CustomerID: Number(customerId),
                PortfolioName: portfolioId,
                ID: groupName
            },
            (response) => {
                this.fetchCustomerPortfolioDetails(customerId, portfolioId);
                snackbar.handleOpen(
                    `Group ${groupName} successfully deleted`,
                    "success"
                );
            }
        );
    }

    async fetchCustomerPortfolios(customerId, cb, errorCb) {
        this.list = [];
        mobXStoreHelper(
            APIS.GET_ALL_PORTFOLIOS,
            { CustomerID: Number(customerId) },
            (response) => {
                this.list = response.meta.data.Portfolios.map((el) => {
                    return {
                        ...el,
                        id: el.PortfolioName
                    };
                });

                if (cb) {
                    cb(this.list);
                }
            },
            (text, code) => {
                if (errorCb) {
                    errorCb(text, code);
                }
            }
        );
    }

    async calcPortfolioReturn(CustomerID, PortfolioName) {
        const params = {
            CustomerID: Number(CustomerID),
            PortfolioName,
            CalculationDate: this.portfolioReturn.ToDate,
            StartDate: this.portfolioReturn.FromDate
        };
        await mobXStoreHelper(APIS.CALC_PORTFOLIO_RETURN, params, (response) => {
            console.log("RawReturn", response.message);
            const portfolioReturnData =
                response.message.Return.shift().GroupDetails[0];
            const groupReturnData =
                response.message.Return.shift().GroupDetails.map((el) => ({
                    ...el,
                    Group_ID: el.GroupName
                }));

            const merged = mergeArrays(
                response.message.Return,
                groupReturnData,
                "Group_ID"
            );

            const rows = [
                {
                    ...portfolioReturnData,
                    id: uuidv4(),
                    ID: portfolioReturnData.PortfolioName,
                    name: portfolioReturnData.PortfolioName,
                    Group_ID: "Portfolio",
                    variant: "portfolio"
                }
            ];
            merged.forEach((el) => {
                rows.push({
                    ...el,
                    ID: el.GroupName,
                    variant: "group",
                    id: uuidv4(),
                    name: el.Group_ID
                });

                if (el.GroupDetails && el.GroupDetails.length > 0) {
                    el.GroupDetails.forEach((el2) => {
                        rows.push({
                            ...el2,
                            ID: el2.AssetID,
                            variant: "asset",
                            id: uuidv4(),
                            name: el2.AssetName
                        });
                    });
                }
            });

            const IsHolding = response.message.IsHolding === 0 ? false : true;

            this.portfolioReturn = {
                ...this.portfolioReturn,
                IsHolding,
                AllRows: rows,
                DisplayRows: rows
                // IsHolding || !this.portfolioReturn.IsExpanded
                //     ? rows
                //     : rows.filter((el) => {
                //           return (
                //               (el.UltimoHolding && el.UltimoHolding > 0) ||
                //               el.variant === "group" ||
                //               el.variant === "portfolio"
                //           );
                //       })
            };

            if (response.message.RemovedAssets) {
                this.portfolioReturn.NonIncludedAssets =
                    response.message.RemovedAssets.map((el) => {
                        return {
                            ...el,
                            id: el.AssetID,
                            Currency: el.AssetCurrencyCode,
                            description: translateErrorMessage(el.ErrorMessage),
                            AssetCurrencyCode: el.AssetCurrencyCode
                        };
                    });
            }

            this.portfolioReturn.ReturnTable = response.message.Return;
        });
    }

    async calcReturnAndAddToPortfolio(CustomerID, PortfolioName) {
        await this.calcPortfolioReturn(CustomerID, PortfolioName)
        this.setSelectedPortfolio();
    }

    async setCustomerPortfolioKeyfigures(keyTableData) {
        // console.log("KeyTable", keyTableData);
        let orderedKeyTableElements = [];

        // We take the first element out since that is the groups data
        // keyTableData[0].variant = "portfolio"
        const groupsData = keyTableData[0];
        const groupDetails = keyTableData.shift();
        groupDetails.GroupDetails.forEach((el, i) => {
            const item = {
                variant: i === 0 ? "portfolio" : "group",
                id: el.ID,
                group: [el.ID],
                ...el
            };
            orderedKeyTableElements.push(item);
            const foundItem = keyTableData.find(
                (item) => item.Group_ID === el.ID
            );
            foundItem.GroupDetails.forEach((el2) => {
                orderedKeyTableElements.push({
                    id: el2.ID,
                    AssetID: el2.ID,
                    group: [el.ID, `${el2.ID}-${el2.CurrencyCode}`],
                    variant: "asset",
                    ...el2
                });
            });
        });

        this.portfolioKeyfigures.KeyFigures = orderedKeyTableElements;
        this.portfolioKeyfigures.KeyTable = [groupsData, ...keyTableData];
        // this.portfolioKeyfigures.KeyTable[0].GroupDetails = this.portfolioKeyfigures.KeyTable[0].GroupDetails.filter((item)=> item.ID !== "DK0060548386")
    }

    async saveNumberOfCertificates(customerId, rows, cb) {
        const NumberOfCertifates = [];
        const Date = [];

        rows.forEach((el) => {
            NumberOfCertifates.push(Number(el.NumberOfCertifates));
            Date.push(dateToInt(el.Date));
        });
        const params = {
            PortfolioName: this.portfolioInformation.PortfolioName,
            NumberOfCertificates: NumberOfCertifates,
            Date
        };
        mobXStoreHelper(
            APIS.SAVE_NUMBER_OF_CERTIFICATES,
            params,
            (response) => {
                if (response.message.Certificates.length) {
                    this.portfolioInformation.NumberOfCertificates =
                        response.message.Certificates[0].NumberOfCertifates;
                    this.fetchCustomerPortfolioDetails(customerId, this.portfolioInformation.PortfolioName);
                    snackbar.handleOpen(
                        "Your number of certificates has been updated",
                        "success"
                    );
                }
                if (cb) {
                    cb();
                }
            }
        );
    }

    async getNumberOfCertificates(cb) {
        const params = {
            PortfolioName: this.portfolioInformation.PortfolioName
        };
        mobXStoreHelper(APIS.GET_NUMBER_OF_CERTIFICATES, params, (response) => {
            this.portfolioNAV.Certificates = response.message.Certificates.map(
                (el) => ({ id: uuidv4(), ...el })
            );
            if (cb) {
                cb();
            }
        });
    }

    async getPortfoliosData(CustomerID = null) {
        this.loading = true;
        const params = {};
        if (CustomerID !== null) {
            params.CustomerID = Number(CustomerID);
        }
        mobXStoreHelper(APIS.GET_PORTFOLIO_DATA, params, (response) => {


            const portfoliosByObject = {}
            this.allPortfolios = response.message.Portfolio.map((el) => {
                portfoliosByObject[el.PortfolioName] = el
                return {
                    id: el.PortfolioName,
                    ...el
                }
            });


            this.allPortfoliosByObject = portfoliosByObject
            this.loading = false;
        }, (text, code) => {
            this.loading = false;
        });
    }

    async getPretradeCompliance(CustomerID, PortfolioName, CalcID) {
        const params = {
            CustomerID: Number(CustomerID),
            PortfolioName,
            CalcID: Number(CalcID),
            CalcType: "Portfolio_Weight"
        };

        mobXStoreHelper(APIS.GET_CALCULATIONS_RESULT, params, (response) => {
            this.pretradeComplianceRows =
                response.message.Result.Compliance.map((el, i) => {
                    const id = `${el.ID}-${el.Test_Type}-${el.RuleType}-${i}`;
                    return {
                        ...el,
                        id,
                        CurrentValue: numberWithCommas(el.CurrentValue),
                        group: [el.RuleType, el.Test_Type, id]
                    };
                });
        });
    }

    async moveAssetToGroup(CustomerID, PortfolioName, AssetID, newGroup) {
        const params = {
            CustomerID: Number(CustomerID),
            PortfolioName,
            ID: AssetID,
            ToFollowID: newGroup
        };

        mobXStoreHelper(APIS.MOVE_ASSET_TO_GROUP, params, async (response) => {
            this.fetchCustomerPortfolioDetails(CustomerID, PortfolioName);
        });
    }

    async setSelectedPortfolio() {
        const newData = {
            ...this.portfoloOverview,
            KeyTable: this.portfolioKeyfigures.KeyTable,
            Return: this.portfolioReturn.AllRows
        }

        const formattedPortfolio = formatPortfolio(newData);

        if (formattedPortfolio.data.length > 0) {
            this.selectedPortfolioType = formattedPortfolio.portfolioInformation.PortfolioType
            this.selectedPortfolioCurrency = formattedPortfolio.portfolioInformation.CurrencyCode
            this.selectedPortfolioBenchmark = formattedPortfolio.portfolioInformation.BenchMark
        }

        this.selectedPortfolioGroups = formattedPortfolio.portfolioGroups
        this.selectedPortfolio = formattedPortfolio.data;
        

        this.selectedPortfolioErrors = formattedPortfolio.errorData
        this.portfolioInformation = formattedPortfolio.portfolioInformation

        // -------------------- This part should be in a widget store at some point --------------------
        const mergedBaseAndReturn = mergeArrays(this.selectedPortfolio, newData.Return, "ID")

        const holdingData = {
            portfolio: {
                type: this.selectedPortfolioType,
                currency: this.selectedPortfolioCurrency,
                benchmark: this.selectedPortfolioBenchmark,
                groups: this.selectedPortfolioGroups,
                errors: this.selectedPortfolioErrors,
                information: this.portfolioInformation
            },
            rows: mergedBaseAndReturn,
            columnVisibilityModel: {
                name: true,
                Holding: true,
                AssetCleanPrice: true,
                Value: true,
                Return_In_Kroner: true,

            }
        }

        const numOfRowsWithinPage = 19
        const numOfPagesToCreate =  Math.ceil(mergedBaseAndReturn.length / numOfRowsWithinPage)
        

        if(numOfPagesToCreate > 1 && !this.isConstructPortfolioPagesTriggered) {
            store.processPages(mergedBaseAndReturn, numOfPagesToCreate, numOfRowsWithinPage, holdingData);    
            this.isConstructPortfolioPagesTriggered = true;
        } else {
            portfolioWidgetsStore.injectWidgetData("holding", holdingData)
        }

        // portfolioWidgetsStore.injectWidgetData("holding", holdingData)
        this.portfolioStatistics.holding = holdingData

        // -------------------- This part should be in a widget store at some point --------------------
    }

    // CUSTOMER PORTFOLIO DETAILS
    async fetchCustomerPortfolioDetails(CustomerID, PortfolioName, startDate, endDate) {
        return new Promise((resolve, reject) => {
            this.loading = true;
            this.selectedPortfolioType = null;
            this.selectedPortfolioCurrency = null;
            this.selectedPortfolioBenchmark = null;
            this.selectedPortfolioGroups = [];
            this.selectedPortfolioErrors = [];
            this.portfolioInformation = {
                PortfolioType: 2  // assume transaction based to include all columns in overview table 
            };
            this.portfolioReturn = { // refresh
                AllRows: [],
                DisplayRows: [],
                columns: [],
                IsHolding: false,
                // IsExpanded: false,
                FromDate: null,
                ToDate: null,
                earliestFromDate: null,
                latestFromDate: null,
                earliestToDate: null,
                latestToDate: null,
                NonIncludedAssets: []
            }
            this.portfolioESGDetails = {
                ESG_Details: [],
                esgColumns: []
            };
            this.portfolioKeyfigures = {
                KeyFigures: [],
                keyfiguresColumns: []
            }
            this.portfolioCashflow = [];
            this.portfolioCashflowChart = [];
            this.selectedPortfolio = [];

            this.riskLimitChart = {
                ruleTypeKeys: [],
                data: {},
                loading: true,
            }
            this.portfolioStatistics = initialPortfolioStatistics
            const params = {
                CustomerID: Number(CustomerID),
                PortfolioName: PortfolioName,
                CalculationDate: endDate || dataStore.calcDate,
                PortfolioCurrency: dataStore.currencyCode,
                UseBankPrices: this.UseBankPrices
            };

            if (this.pretradeComplianceRows.length) {
                params.PreTradeCompliance = this.pretradeComplianceRows
            }


            mobXStoreHelper(APIS.RUN_GLOBAL_CALCULATION, params, async (response) => {
                const portfolioWeight = response.message.GlobalCalculation[0]
                const portfolioCashflowProjection = response.message.GlobalCalculation[2]
                this.portfoloOverview = {
                    PortfolioCurrency: response.message.PortfolioCurrency,
                    PortfolioName: response.message.PortfolioName,
                    PortfolioType: response.message.PortfolioType,
                    Compliance: response.message.Compliance,
                    ComplianceBreachCounter: response.message.ComplianceBreachCounter,
                    ...portfolioWeight
                };
                
                await this.setCustomerPortfolioKeyfigures(response.message.KeyTable)

                if (portfolioIsTransactionsBased(response.message.PortfolioType)) {
                    await mobXStoreHelper(APIS.GET_LATEST_TRANSACTION_DATE, { "CustomerID": Number(CustomerID), "PortfolioName": PortfolioName }, (response) => {
                        let FromDate = response.message.FirstTransactionDate
                        let ToDate = dataStore.calcDate
                        // if response.message.FirstTransactionDate is older than startDate, use startDate
                        if(response.message.FirstTransactionDate < startDate) {
                            FromDate = startDate
                        }
                        // endDate is before calcDate and after firstTransactionDate use endDate
                        if(endDate < dataStore.calcDate && endDate > response.message.FirstTransactionDate) {
                            ToDate = endDate
                        }

                        this.portfolioReturn = {
                            ...this.portfolioReturn,
                            ToDate,
                            FromDate,
                            earliestFromDate: response.message.FirstTransactionDate, // the earliest possible date so set in from
                            latestFromDate: dataStore.calcDate, // the latest possible date to set in from date
                            earliestToDate: response.message.FirstTransactionDate, // the earliest possible date to set in todate
                            latestToDate: dataStore.calcDate // the latest possible date to set in to date
            
                        }
                        this.portfolioInformation.MaxTradingDate = response.meta.data.MaxTradingDate
                    })
                    
                } else {
                    // 1st day and month of the year before.
                    const earliest = parseInt(((parseInt(dataStore.calcDate.toString().slice(0, 4)) - 1).toString() + "0101"));

                    this.portfolioReturn = {
                        ...this.portfolioReturn,
                        ToDate: endDate || dataStore.calcDate,
                        FromDate: startDate || earliest,
                        earliestFromDate: earliest,
                        latestFromDate: dataStore.calcDate, // the latest possible date to set in from date
                        earliestToDate: earliest,
                        latestToDate: dataStore.calcDate // the latest possible date to set in to date
                    };
                }

                if(portfolioCashflowProjection && portfolioCashflowProjection.ID === "CFProjection" && portfolioCashflowProjection.CFProjection && portfolioCashflowProjection.CFProjectionChart) {
                    this.portfolioCashflow = portfolioCashflowProjection.CFProjection;
                    this.portfolioCashflowChart = portfolioCashflowProjection.CFProjectionChart;

                }
                else {
                    console.warn("No CFProjection found on index 2 in GlobalCalculation");
                }

                const storedValueConst = "portfolioColumnVisibilityModel";
                const storedValue = localStorage.getItem(storedValueConst)
                const hasStoredValue = !!storedValue && storedValue[0] === "{";

                if (hasStoredValue &&
                    this.portFolioOverviewTableApiRef &&
                    this.portFolioOverviewTableApiRef.current &&
                    typeof this.portFolioOverviewTableApiRef.current === "object" &&
                    Object.keys(this.portFolioOverviewTableApiRef.current).length !== 0) {

                    const columnVisibilityModelInitialState = JSON.parse(storedValue)

                    let columns = this.portFolioOverviewTableApiRef.current.getAllColumns();

                    columns = columns.filter((col) => {
                        return col.groupType === "return";
                    });

                    const colVisibilityModelKeys = Object.keys(columnVisibilityModelInitialState);

                    const anyReturnColumnsVisible = columns.map((col) => {
                        return !colVisibilityModelKeys.includes(col.field) || (colVisibilityModelKeys.includes(col.field) && columnVisibilityModelInitialState[col.field]);
                    }).some((visibility) => {
                        return visibility;
                    });

                    if (anyReturnColumnsVisible) {
                        await this.calcPortfolioReturn(CustomerID, PortfolioName)
                    }
                }
                else {
                    await this.calcPortfolioReturn(CustomerID, PortfolioName)
                }

                const liquidityDistData = {
                    data: [{ name: 'Assets', value: portfolioWeight.AssetWeight, key: "Assets" }, { key: "Cash", name: 'Cash', value: portfolioWeight.CashWeight }]
                }

                portfolioWidgetsStore.injectWidgetData("liquidity-distribution", liquidityDistData)          
                this.portfolioStatistics.liquidityDistribution = {
                    data: liquidityDistData
                }
                
                const groupDistData = handleFormatWeights(portfolioWeight.GroupResult)
                const sectorDistData = handleFormatWeights(portfolioWeight.SectorResult)
                const riskClassDistData = handleFormatWeights(portfolioWeight.RCResult)
                const currencyDistData = handleFormatWeights(portfolioWeight.CurrResult)

                portfolioWidgetsStore.injectWidgetData("groups-distribution", groupDistData)
                portfolioWidgetsStore.injectWidgetData("sector-distribution", sectorDistData)
                portfolioWidgetsStore.injectWidgetData("risk-classification", riskClassDistData)
                portfolioWidgetsStore.injectWidgetData("currency-exposure", currencyDistData)

                this.portfolioStatistics.weights = {
                    groupDistribution: handleFormatWeights(portfolioWeight.GroupResult),
                    sectorDistribution: handleFormatWeights(portfolioWeight.SectorResult),
                    currencyExposure: handleFormatWeights(portfolioWeight.CurrResult),
                    riskClassification: handleFormatWeights(portfolioWeight.RCResult)
                }
                this.portfolioStatistics.allocations = handleFormatAllocations(portfolioWeight.Allocation);

                const totalInvestedData = numberWithCommas(portfolioWeight.AssetAmount + (portfolioWeight.CashAmount || 0))
                portfolioWidgetsStore.injectWidgetData("total-invested", totalInvestedData)
                this.portfolioStatistics.totalInvested = {
                    data: numberWithCommas(portfolioWeight.AssetAmount + (portfolioWeight.CashAmount || 0))
                }
            

            if (portfolioWeight.BondIssuer) {
                const bondIssuerData = handleFormatWeights(portfolioWeight.BondIssuer);
                portfolioWidgetsStore.injectWidgetData("bond-issuers", bondIssuerData)
                this.portfolioStatistics.weights.bondIssuers = bondIssuerData
            }

            if (portfolioWeight.BondType) {
                const bondClassData = handleFormatWeights(portfolioWeight.BondType);
                portfolioWidgetsStore.injectWidgetData("bond-class", bondClassData)
                this.portfolioStatistics.weights.bondType = handleFormatWeights(portfolioWeight.BondType);
            }

            debugger
            if(response.message.Target) {
                portfolioWidgetsStore.injectWidgetData("target-weight", response.message.Target)
                this.portfolioStatistics.target.data = response.message.Target
            }

            this.setSelectedPortfolio();


            if(!!this.portfoloOverview.Compliance && !!this.portfoloOverview.ComplianceBreachCounter) {
                this.constructRiskLimitBreachChart(this.portfoloOverview.Compliance, this.portfoloOverview.ComplianceBreachCounter);
            }

                this.loading = false;
                this.fetchedId = PortfolioName
                resolve();
            }, (errorText, errorCode) => {
                this.loading = false;
                this.riskLimitChart.loading = false;
                this.fetchedId = null;

            })
        })
    }

    async updateCustomerPortfolioWithPretrades(CustomerID, PortfolioName, pretradeRows) {
        const formattedRows = pretradeRows.map((pretradeRow) => {
            return {
                AssetID: pretradeRow.AssetID,
                AssetCurrencyCode: pretradeRow.AssetCurrencyCode,
                TradedAmount: parseFloat(pretradeRow.TradedAmount),
                TradedPrice: parseFloat(pretradeRow.TradedPrice),
                FXRate: parseFloat(pretradeRow.FXRate)
            }
        });

        this.pretradeComplianceRows = formattedRows;

        await this.fetchCustomerPortfolioDetails(CustomerID, PortfolioName);
    }


    async savePortfolioUpload(payload, customerId, cb, errorCb) {
        const formattedPayload = formatUploadDataToFitApi(payload, customerId);
        mobXStoreHelper(
            APIS.ADD_PORTFOLIO,
            formattedPayload,
            (response) => {
                this.fetchCustomerPortfolios(customerId);

                if (cb) {
                    cb();
                }
            },
            (errorText) => {
                if (errorCb) {
                    errorCb(errorText);
                }
            }
        );
    }

    async deletePortfolio(portfolioId, customerId, cb) {
        mobXStoreHelper(APIS.DELETE_PORTFOLIO, { PortfolioName: portfolioId }, (response) => {
                snackbar.handleOpen(
                    "Successfully deleted portfolio",
                    "success"
                );
                const newList = this.list.filter((el) => {
                    return (
                        el.PortfolioName.toLowerCase() !==
                        portfolioId.toLowerCase()
                    );
                });

                const newAllPortfolios = this.allPortfolios.filter((el) => {
                    return (
                        el.PortfolioName.toLowerCase() !==
                        portfolioId.toLowerCase()
                    );
                });

                this.allPortfolios = newAllPortfolios;
                this.list = newList;

                this.selectedPortfolio = [];
                this.weights = {
                    groups: null,
                    sectors: null
                };

                if (cb) {
                    cb();
                }
            }, (errorText, errorCode) => {

                snackbar.handleOpen(errorText,"error")
            });
    }

    async mergePortfolios({
        customerID,
        newPortfolioName,
        currencyCode,
        portfolioA,
        portfolioB,
        tempPortfolio,
        cb,
        errorCb
    }) {
        const payload = {
            CustomerID: Number(customerID),
            NewPortfolioName: newPortfolioName,
            CurrencyCode: currencyCode,
            PortfolioName: [portfolioA, portfolioB],
            TemporaryPortfolio: tempPortfolio
        };

        mobXStoreHelper(APIS.MERGE_PORTFOLIOS, payload, (response) => {
            this.fetchCustomerPortfolios(payload.CustomerID);

            if (cb) {
                cb();
            }
        });
    }

    async updatePortfolioSettings(customerId, portfolioId, args, successCb) {
        const params = {
            CustomerID: Number(customerId),
            PortfolioName: portfolioId,
            ...args
        };

        mobXStoreHelper(APIS.UPDATE_PORTFOLIO, params, (response) => {
            if (response.success && response.message.ErrorCode === 0) {
                snackbar.handleOpen(
                    `Portfolio settings updated`,
                    "success"
                );

                this.allPortfolios.forEach((portfolio, index) => {
                    if (portfolio.id === portfolioId) {
                        this.allPortfolios[index].PortfolioSubName = args.PortfolioSubName;
                        this.allPortfolios[index].RealPortfolio = args.IncludeAUMFlag;
                    }
                });

                this.allPortfolios = toJS(this.allPortfolios);

                if (this.searchedPortfolios.length) {
                    this.searchedPortfolios.forEach((portfolio, index) => {
                        if (portfolio.id === portfolioId) {
                            this.allPortfolios[index].PortfolioSubName = args.PortfolioSubName;
                            this.allPortfolios[index].RealPortfolio = args.IncludeAUMFlag;
                        }
                    })

                    this.searchedPortfolios = toJS(this.searchedPortfolios);
                }

                successCb();
            }
            else {
                snackbar.handleOpen(
                    `Something went wrong`,
                    "error"
                );
            }
        });
    }
}

// this makes it possible to access it via console
const store = (window.portfoliosStore = new PortfoliosStore());

export default store;

export const Number_Of_Years = [2, 3, 4, 5, 6, 7, 8, 9, 10];
export const NumberOfSteps = [
    {
        value: 4,
        label: '4'
    },
    {
        value: 20,
        label: '20'
    },
    {
        value: 36,
        label: '36'
    },
    {
        value: 52,
        label: '52'
    }
]

export const LengthOfReturnPeriodSteps = [
    {
        value: 0,
        label: '0'
    },
    {
        value: 1,
        label: '1'
    }
]


export const CALC_DATE = {
    TODAY: 'TODAY',
    PREVIOUS_WORKDAY: 'PREVIOUS_WORKDAY',
}

export const CALC_DATE_SETTING = [
    {
        key: CALC_DATE.TODAY,
        label: 'Today'
    },
    {
        key: CALC_DATE.PREVIOUS_WORKDAY,
        label: 'Previous Workday'
    }
]


export const STDDEV_PERIOD_INDICATOR_KEYS = {
    WEEKLY: 'WEEKLY',
    DAILY: 'DAILY',
}

export const STDDEV_PERIOD_INDICATOR = [
    {
        key: STDDEV_PERIOD_INDICATOR_KEYS.WEEKLY,
        label: 'Weekly'
    },
    {
        key: STDDEV_PERIOD_INDICATOR_KEYS.DAILY,
        label: 'Daily'
    }
]
export const INCLUDE_MEAN_IN_RISK_CALC_KEYS = {
    YES: 'YES',
    NO: 'NO',
}



export const IncludeMeanInRiskCalc = [
    {
        key: INCLUDE_MEAN_IN_RISK_CALC_KEYS.YES,
        label: 'Yes'
    },
    {
        key: INCLUDE_MEAN_IN_RISK_CALC_KEYS.NO,
        label: 'No'
    },
]

export const KIID_SOURCE_KEYS = {
    OFFICIAL: 1,
    OUR_CALCULATED: 2,
}
export const USE_BM_CORRELATION_KEYS = {
    YES: "YES",
    NO: "NO",
}

export const REAL_TIME_DATA_KEYS = {
    NO: 1,
    YES: 0,
}

export const CORR_TYPE_KEYS = {
    RANK: 'RANK',
    NORMAL: 'NORMAL',
}

export const CorrTypeOptions = [
    {
        key: CORR_TYPE_KEYS.RANK,
        label: 'Rank'
    },
    {
        key: CORR_TYPE_KEYS.NORMAL,
        label: 'Normal'
    }
]

export const KiiDSourceOptions = [
    {
        key: KIID_SOURCE_KEYS.OFFICIAL,
        label: 'Official KiiD'
    },
    {
        key: KIID_SOURCE_KEYS.OUR_CALCULATED,
        label: 'Rebus Finance calculated KiiD'
    }
]

export const UseRealTimeDataOptions = [
    {
        key: REAL_TIME_DATA_KEYS.YES,
        label: 'Yes'
    },
    {
        key: REAL_TIME_DATA_KEYS.NO,
        label: 'No'
    },
]
export const UseBMCorrelationOptions = [
    {
        key: USE_BM_CORRELATION_KEYS.YES,
        label: 'Yes'
    },
    {
        key: USE_BM_CORRELATION_KEYS.NO,
        label: 'No'
    },
]

export const StdDevNumberOfPeriodsSteps = [
    {
        value: 1,
        label: '1'
    },
    {
        value: 60,
        label: '60'
    }
]

export const RiskUnwindPeriodSteps = [
    {
        value: 1,
        label: '1'
    },
    {
        value: 90,
        label: '90'
    }
]

export const HistRiskNumberOfDaysSteps = [
    {
        value: 180,
        label: '180'
    },
    {
        value: 720,
        label: '720'
    }
]

export const QuantileAdjustmentSteps = [
    {
        value: 80,
        label: '80'
    },
    {
        value: 100,
        label: '100'
    }
]

export const ConfidenceIntervalSteps = [
    {
        value: 75,
        label: '75'
    },
    {
        value: 100,
        label: '100'
    }
]

export const defaultData = (dataStore) => {
    return {
        Confidence_Interval: 99,
        CorrInitialize: 'SPECTRAL_DECOMPOSITION',
        CorrModel: 'HYPERSPHERE',
        CorrType: 'RANK',
        KiiDSource: 2,
        ForceCorrEstimate: 'NO',
        HistRisk_Number_Of_Days: 360,
        IncludeMeanInRiskCalc: 'NO',
        Length_Of_Return_Period: 0.5,
        Number_Of_Steps: 4,
        Number_Of_Years: 5,
        QuantileAdjustment: 100,
        Risk_Unwind_Period: 10,
        StdDev_Number_Of_Periods: 52,
        StdDev_Period_Indicator: 'WEEKLY',
        UseRealTimeData: 0,
        UseBMCorrelation: 'NO',
        Investment_Amount: 0,
        RiskClassification: {
          Name: ['Stable', 'Stable', 'Stable', 'Stable', 'Risky', 'Risky', 'Risky'],
          Number: [1, 2, 3, 4, 5, 6, 7]
        },
        YCLink: {
            CurrencyCode: dataStore ? dataStore.currencyCodes : [],
            Links: dataStore ? [
                {
                    label: "Tenor",
                    value: dataStore.currencyCodes.map(() => "3M")
                },
                {
                    label: "Discounting",
                    value: dataStore.currencyCodes.map(() => "3M")
                }
            ] : [],
            Options: ["3M", "6M", "OIS", "CITA"]
        }
    }
};